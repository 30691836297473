import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { hcpRolesURL, serverURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HcpRolesService {

  constructor(private http: HttpClient) { }

  getAllHcpRoles(): Observable<any>{
    return this.http.get<any>(hcpRolesURL)
  }

  getHcpRoles(id): Observable<any>{
    return this.http.get<any>(hcpRolesURL+id+"/")
  }

  createHcpRole(hcpRoleDetails): Observable<any>{
    return this.http.post<any>(hcpRolesURL, hcpRoleDetails)
  }

  updateHcpRole(id, hcpRole): Observable<any> {
    return this.http.patch<any>(hcpRolesURL+id+"/", hcpRole);
  }

}
