<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
		<span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-department-details">Department Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					HCP Training Form ( Neurovascular Phase II )
				</span>
	  </li>
		</ol>   
	</div>
</section>

<div class="form-container hcp-training-form">
  <div>
	  <div class="section">
			<div class="row">
				<div class="col-md-8">
					<div class="hero-block__headings">
						<h1 class="hero-block__title">CORPATH GRX NEUROVASCULAR ROBOTIC SYSTEM</h1>
					</div>
				</div>
				<div class="col-md-4 flex-reverse">
				<button class="button display-block btn-sm" routerLink="/account-department-details">Back</button>
				</div>
			</div>

			<div class="heading">
				<h2>Phase II Training – Flow Model Practice Protocol & Acknowledgement Form</h2>
			</div>
	  
		  
			<div class="s-heading">
				<h3>OBJECTIVE</h3>
			</div>

			<div class="intro">
				<p>This purpose of this training curriculum is to develop hands on robotic operator and bedside team skills and workflow acumen for participating robotic operators. The program will focus on robotic scenarios and activities that are expected to occur during procedures. This training program will follow the curriculum matrix below and be administered by Corindus personnel. Each module will consist of device selection, navigation practice, aneurysm cannulation practice, coil deliver practice, and stent delivery practice with the CorPath GRX System.</p>
			</div>

		  
			<div class="row">

	        </div>

        <div class="section">
            <div class="s-heading">
                <h2>MODULE 1 – DISTAL BASILAR SIDE WALL ANEURYSM</h2>
            </div>
            <div class="entry-content">
                <img src="/assets/imgs/o_1.jpg">
            </div>
        </div>
	  	<div class="section">
			<div class="subheading">
				<h3>PRACTICE PROTOCOL</h3>
			</div>
			<div class="module-container">
				<div class="col-md-12">
					<h4>Module 1 – Distal Basilar Side Wall Aneurysm</h4>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 1 - Device Selection & Treatment Approach</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_1.section_1.confirm" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<div>
								<label>1. Select device stack. List selected devices</label>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_1][section_1][guide_catheter]">Guide Catheter</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_1.section_1.guide_catheter}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_1][section_1][guide_catheter]" class="textfield__input" [(ngModel)]="form?.tasks.module_1.section_1.guide_catheter">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_1][section_1][intermediate_catheter]">Intermediate Catheter  (if applicable)</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_1.section_1.intermediate_catheter}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_1][section_1][intermediate_catheter]" class="textfield__input" [(ngModel)]="form?.tasks.module_1.section_1.intermediate_catheter">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_1][section_1][microcatheter]">Microcatheter</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_1.section_1.microcatheter}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_1][section_1][microcatheter]" class="textfield__input" [(ngModel)]="form?.tasks.module_1.section_1.microcatheter">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_1][section_1][microwire]">Microwire</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_1.section_1.microwire}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_1][section_1][microwire]" class="textfield__input" [(ngModel)]="form?.tasks.module_1.section_1.microwire">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<label>2. Determine approximately where devices should be positioned. Please draw where devices would be located prior to robotic start. Microcatheter starting position, prior to robotic loading, should consider necessary and available travel distance, and catheter support.</label>
								<img src="/assets/imgs/m1_s1.jpg">
							</div>
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 2 – Navigation</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_1.section_2" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<p>Pre-robotic device introduction to be completed manually by bedside physician role</p>
							<p>Physician Operator</p>
							<ul>
								<li>
									<p>Request loading of devices in to GRX cassette</p>
									<p><i>Ensure all automated moves are disabled.</i></p>
								</li>
								<li>
									<p>Starting at intermediate or guide catheter, navigate the microcatheter to the right and left PCA, traversing from P1 to P2<br>Return to microcatheter robotic starting position.</p>
									<p><i>Enable Active Device Fixation (ADF).</i></p>
								</li>
								<li>
									<p>Starting at intermediate or guide catheter, navigate the microcatheter to the right and left PCA, traversing from P1 to P2<br>Return to microcatheter robotic starting position.
									</p>
								</li>
							</ul>
							<img src="/assets/imgs/m1_s2.jpg">
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 3 – Aneurysm Cannulation</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_1.section_3" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<ul>
								<li>1. Ensure all automated moves are disabled
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
										</ul>
									</ul>
								</li>
								<li>2. Enable Active Device Fixation (ADF)
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
											<li>SIMULATE EMERGENCY CONVERT TO MANUAL</li>
										</ul>
									</ul>
								</li>
								<li>3. Disable ADF. Enable Limited Speed (LS).
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
										</ul>
									</ul>
								</li>
								<li>4. Request a microcatheter exchange.
									<ul>
										<li>Move microwire and microcatheter to a safe position</li>
										<li>Have bedside team remove the microcatheter, flush and re-introduce</li>
									</ul>
								</li>
								<li>5. Repeat cannulation of the aneurysm
									<ul>
										<li>Select ADF, LS, or no-automated move (operator choice</li>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<li>Return to robotic starting point</li>
									</ul>
								</li>
								<li>6. Request a modified curve on the microwire
									<ul>
										<li>Move microwire and microcatheter to a safe position</li>
										<li>Have bedside team remove the microwire, adjust tip curve, and re-introduce</li>
									</ul>
								</li>
								<li>7. Repeat cannulation of the aneurysm
								</li>
								<li>8. Ensure all automated moves disabled
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
										</ul>
									</ul>
								</li>
								<li>9. Enable Active Device Fixation (ADF) only
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
											<li>Disable ADF</li>
										</ul>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 4 – Coil Delivery</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_1.section_4" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<ul>
								<li>1. Cannulate the aneurysm with the microwire and microcatheter
								</li>
								<li>2. Retract microwire to safe position inside microcatheter
								</li>
								<li>3. Request loading of desired coil
									<ul>
										<li>Bedside team exchanges wire for coil</li>
									</ul>
								</li>
								<li>4. Robotically deliver coil
									<ul>
										<li>Ensure automated moves are OFF
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
												<li>Recapture coil</li>
											</ul>
										</li>
										<li>Enable Limited Speed (LS)
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
												<li>Recapture coil</li>
												<li>Disable LS</li>
											</ul>
										</li>
										<li>Enable Active Device Fixation (ADF)
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
											</ul>
										</li>
										<li>Request detachment of coil
											<ul>
												<li>Bedside team detaches coil</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>5. Retract pusher wire
								</li>
								<li>6. Request loading of second desired coil
									<ul>
										<li>Bedside team exchanges wire for coil</li>
									</ul>
								</li>
								<li>7. Robotically deliver Coil
									<ul>
										<li>Select operator desired automated move (none, ADF, LS)
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
												<li>Recapture coil</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>8. Robotically deliver coil almost to the point of detachment
									<ul>
										<li>Repeat coil delivery with desired automated move – ONLY DELIVER HALF WAY</li>
										<li>Retract the microcatheter robotically to into mid basilar</li>
										<li>Re-catheterize the aneurysm over the coil</li>
										<li>Recapture coil after re-catheterization over coil</li>
										<li>Exchange for wire, and retract microwire and microcatheter to robotic starting point</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 5 – Stent Delivery & Coil Through</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_1.section_5" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<ul>
								<li>1. Skill 5 – Stent Delivery & Coil Through
								</li>
								<li>2. Request loading of stent
								</li>
								<li>3. Robotically deliver stent, using automated moves of choice
								</li>
								<li>4. Request exchange for microwire
								</li>
								<li>5. Robotically cannulate the aneurysm through the stent
								</li>
								<li>6. Robotically deliver coil, using automated move(s) of choice
								</li>
								<li>7. Robotically deliver coil, using automated move(s) of choice
								</li>
								<li>8. Recapture coil partially
								</li>
								<li>9. Retract the microcatheter robotically to into mid basilar
								</li>
								<li>10. Re-catheterize the aneurysm over the coil
								</li>
								<li>11. Option:
									<ul>
										<li>Request detachment of coil, or</li>
										<li>Recapture coil for cost-savings</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="section">
            <div class="s-heading">
                <h2>MODULE 3 – ACOM ANEURYSM</h2>
            </div>
            <div class="entry-content">
                <img src="/assets/imgs/m3.jpg">
            </div>
        </div>


		<div class="section">
			<div class="module-container">
				<div class="col-md-12">
					<h4> Acom Aneurysm</h4>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 1 - Device Selection & Treatment Approach</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_3.section_1.confirm" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<div>
								<label>1. Select device stack. List selected devices</label>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_3][section_1][guide_catheter]">Guide Catheter</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_3.section_1.guide_catheter}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_3][section_1][guide_catheter]" class="textfield__input" [(ngModel)]="form?.tasks.module_3.section_1.guide_catheter">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_3][section_1][intermediate_catheter]">Intermediate Catheter  (if applicable)</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_3.section_1.intermediate_catheter}">
										<div class="textfield__box">
											<input type="text" name="tasks[section_1.][intermediate_catheter]" class="textfield__input" [(ngModel)]="form?.tasks.module_3.section_1.intermediate_catheter">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_3][section_1][microcatheter]">Microcatheter</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_3.section_1.microcatheter}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_3][section_1][microcatheter]" class="textfield__input" [(ngModel)]="form?.tasks.module_3.section_1.microcatheter">
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<label class="textfield__label" for="tasks[module_3][section_1][microwire]">Microwire</label>
								</div>
								<div class="col-md-9">
									<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.tasks.module_3.section_1.microwire}">
										<div class="textfield__box">
											<input type="text" name="tasks[module_3][section_1][microwire]" class="textfield__input" [(ngModel)]="form?.tasks.module_3.section_1.microwire">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<label>2.  Determine approximately where devices should be positioned. Please draw where devices would be located prior to robotic start. Microcatheter starting position, prior to robotic loading, should consider necessary and available travel distance, and catheter support.</label>
								<img src="/assets/imgs/m3_s1.jpg">
							</div>
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 2 – Navigation</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_3.section_2" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<p>Pre-robotic device introduction to be completed manually by bedside physician role</p>
							<p>Physician Operator</p>
							<ul>
								<li>
									<p>Request loading of devices in to GRX cassette</p>
									<p><i>Ensure all automated moves are disabled.</i></p>
								</li>
								<li>
									<ul>Starting at intermediate or guide catheter, navigate the microcatheter to the 
										<li>MCA distal segment</li>
										<li>L-ACA A2 /pericallosal segmen<br>Return to microcatheter robotic starting position.</li>
									</ul>
									<p><i>Enable Active Device Fixation (ADF).</i></p>
								</li>
								<li>
									<ul>Starting at intermediate or guide catheter, navigate the microcatheter to the 
										<li>MCA distal segment</li>
										<li>L-ACA A2 /pericallosal segmen<br>Return to microcatheter robotic starting position.</li>
									</ul>
								</li>
							</ul>
							<img src="/assets/imgs/m3_s2.jpg">
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 3 – Aneurysm Cannulation</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_3.section_3" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<ul>
								<li>1. Ensure all automated moves are disabled
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
											<li>SIMULATE EMERGENCY CONVERT TO MANUAL</li>
										</ul>
									</ul>
								</li>
								<li>2. Enable Active Device Fixation (ADF)
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
										</ul>
									</ul>
								</li>
								<li>3. Disable ADF. Enable Limited Speed (LS).
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
										</ul>
									</ul>
								</li>
								<li>4. Request a microcatheter exchange.
									<ul>
										<li>Move microwire and microcatheter to a safe position</li>
										<li>Have bedside team remove the microcatheter, flush and re-introduce</li>
									</ul>
								</li>
								<li>5. Repeat cannulation of the aneurysm
									<ul>
										<li>Select ADF, LS, or no-automated move (operator choice</li>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<li>Return to robotic starting point</li>
									</ul>
								</li>
								<li>6. Request a modified curve on the microwire
									<ul>
										<li>Move microwire and microcatheter to a safe position</li>
										<li>Have bedside team remove the microwire, adjust tip curve, and re-introduce</li>
									</ul>
								</li>
								<li>7. Repeat cannulation of the aneurysm
								</li>
								<li>8. Ensure all automated moves disabled
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
										</ul>
									</ul>
								</li>
								<li>9. Enable Active Device Fixation (ADF) only
									<ul>
										<li>Cannulate the aneurysm with the microwire and microcatheter</li>
										<ul>
											<li>Return to robotic starting point</li>
											<li>Complete 3 times, returning to robotic starting point at the end</li>
											<li>Disable ADF</li>
										</ul>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 4 – Coil Delivery</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_3.section_4" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<ul>
								<li>1. Cannulate the aneurysm with the microwire and microcatheter
								</li>
								<li>2. Retract microwire to safe position inside microcatheter
								</li>
								<li>3. Request loading of desired coil
									<ul>
										<li>Bedside team exchanges wire for coil</li>
									</ul>
								</li>
								<li>4. Robotically deliver coil
									<ul>
										<li>Ensure automated moves are OFF
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
												<li>Recapture coil</li>
											</ul>
										</li>
										<li>Enable Limited Speed (LS)
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
												<li>Recapture coil</li>
												<li>Disable LS</li>
											</ul>
										</li>
										<li>Enable Active Device Fixation (ADF)
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
											</ul>
										</li>
										<li>Request detachment of coil
											<ul>
												<li>Bedside team detaches coil</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>5. Retract pusher wire
								</li>
								<li>6. Request loading of second desired coil
									<ul>
										<li>Bedside team exchanges wire for coil</li>
									</ul>
								</li>
								<li>7. Robotically deliver Coil
									<ul>
										<li>Select operator desired automated move (none, ADF, LS)
											<ul>
												<li>Robotically deliver coil almost to the point of detachment</li>
												<li>Recapture coil</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>8. Robotically deliver coil almost to the point of detachment
									<ul>
										<li>Repeat coil delivery with desired automated move – ONLY DELIVER HALF WAY</li>
										<li>Retract the microcatheter robotically to into ACA mid-A1 segment</li>
										<li>Re-catheterize the aneurysm over the coil</li>
										<li>Recapture coil after re-catheterization over coil</li>
										<li>Exchange for wire, and retract microwire and microcatheter to robotic starting point</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="row module-row">
					<div class="section-header col-md-12">
						<label>Skill 5 – Stent Delivery & Coil Through</label>
						<p>Section Completed</p>
						<input type="checkbox" [(ngModel)]="form?.tasks.module_3.section_5" class="form-control">
					</div>
					<div class="col-md-12">
						<div class="section-content">
							<ul>
								<li>1. Skill 5 – Stent Delivery & Coil Through
								</li>
								<li>2. Request loading of stent
								</li>
								<li>3. Robotically deliver stent, using automated moves of choice
								</li>
								<li>4. Request exchange for microwire
								</li>
								<li>5. Robotically cannulate the aneurysm through the stent
								</li>
								<li>6. Robotically deliver coil, using automated move(s) of choice
								</li>
								<li>7. Robotically deliver coil, using automated move(s) of choice
								</li>
								<li>8. Recapture coil partially
								</li>
								<li>9. Retract the microcatheter robotically to into ACA mid-A1 segment
								</li>
								<li>10. Re-catheterize the aneurysm over the coil
								</li>
								<li>11. Option:
									<ul>
										<li>Request detachment of coil, or</li>
										<li>Recapture coil for cost-savings</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>



		  <div class="row">
			<div class="col-md-12">
				<h3>CORPATH GRX NEUROVASCULAR ROBOTIC SYSTEM</h3>
			</div>
			<div class="col-md-12">
				<h5>Phase II Training Completion Certification</h5>
			</div>
			<div class="col-md-12">
				<div>
					<div>
					  <span>
						  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.physician_name}">
							  <div class="textfield__box">
								  <input type="text" id="confirm[physician_name]" name="confirm[physician_name]" class="textfield__input" [(ngModel)]="form?.confirm.physician_name">
								  <label class="textfield__label" for="confirm[physician_name]">Physician name</label>
							  </div>
							  <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
						  </div>
					  </span>
					</div>      
				</div>
			</div>
			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div>
								<label class="" for="confirm[physician_signature]">Physician Signature:</label>
							</div>
							<div class="canvas-holder">
								<canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>

								<button class="sgn-button" (click)="clearPad()">Clear</button>
							</div>
						</span>
					</div> 
				</div>
			</div>

			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.date_of_phase_completion}">
								<div class="textfield__box">
									<input type="text" id="confirm[date_of_phase1_completion]" name="confirm[date_of_phase_completion]" class="textfield__input" [(ngModel)]="form?.confirm.date_of_phase_completion">
									<label class="textfield__label" for="confirm[date_of_phase_completion]">Date of Phase II Completion:*</label>
								</div>
								<small class="textfield__hint display-flex"><span class="col-10 padding-0">Format: M/d/yyyy</span></small>
							</div>
						</span>
					</div>      
				</div>
			</div>

			<br>
			<br>

			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.hospital_name}">
								<div class="textfield__box">
									<input type="text" id="confirm[hospital_name]" name="confirm[hospital_name]" class="textfield__input" [(ngModel)]="form?.confirm.hospital_name">
									<label class="textfield__label" for="confirm[hospital_name]">Hospital Name:</label>
								</div>
								<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						</span>
					</div>      
				</div>
			</div>
			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.corindus_trainer}">
								<div class="textfield__box">
									<input type="text" id="confirm[corindus_trainer]" name="confirm[corindus_trainer]" class="textfield__input" [(ngModel)]="form?.confirm.corindus_trainer">
									<label class="textfield__label" for="confirm[corindus_trainer]">Corindus Trainer Name:*</label>
								</div>
								<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						</span>
					</div>      
				</div>
			</div>
			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.confirm_date}">
								<div class="textfield__box">
									<input type="date" id="confirm[confirm_date]" name="confirm[confirm_date]" class="textfield__input" [(ngModel)]="form?.confirm.confirm_date">
									<label class="textfield__label" for="confirm[confirm_date]">Confirm Date</label>
								</div>
								<small class="textfield__hint display-flex"><span class="col-10 padding-0">Format: M/d/yyyy</span></small>
							</div>
						</span>
					</div>      
				</div>
			</div>

		  </div>

		  <div class="row form-note">
				Upon completion of this training protocol & acknowledgement, please return this page to gst.team@siemens-healthineers.com for 
				documentation of completion.
		  </div>

		  <div class="row form-quote">
			The training described herein is strictly on the use of the CorPath GRX Neurovascular Robotic System and not the procedure being performed. This program does not replace any specific hospital or physician requirements necessary to perform procedures. This document is strictly private, confidential and personal to its recipients and should not be copied, distributed or reproduced in whole or in part, nor passed to any third party.
		  </div>

		  <div class="heading">
			  HEADQUARTERS APPROVAL: Name, Signature, Date: 
		  </div>

		  <div class="row">
			  <div class="col-md-12">
				  <div>
					  <div>
						  <span>
							  <div class="textarea w-100" [ngClass]="{'is-active': form?.approval.signature}">
								<div class="textarea__container">
									<textarea name="approval[signature]" class="textarea__input" row="2" [(ngModel)]="form?.approval.signature"></textarea>
									<label class="textarea__label" for="approval[signature]"></label>
								</div>
								<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						  </span>
					  </div>      
				  </div>
			  </div>
		  </div>
	  </div>

	  <div class="row btn-row submit-form">
		  <button class="button button--primary display-block" (click)="saveDraft()">Save draft</button>
	  </div>
  </div>
  <div class="row btn-row complete-form">
	<button class="button button--primary display-block" (click)="complete()">Complete</button>
  </div>
</div>