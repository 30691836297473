import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DialogService } from 'src/app/services/dialog.service';
import { TrainingsService } from 'src/app/services/trainings.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-case-training',
  templateUrl: './case-training.component.html',
  styleUrls: ['./case-training.component.scss']
})
export class CaseTrainingComponent implements OnInit {

  form: any

  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;

  constructor(
    private dsService: DataSharingService,
    private dialogService: DialogService, 
    private router: Router, 
    private trainingsService: TrainingsService
  ) { }

  ngOnInit(): void {
    let storedData = this.dsService.currentDataValue
    this.defaultData()

    if(storedData.account || storedData.hcp){
      this.trainingsService.getCaseTrainingbyAccount(storedData.account.id, storedData.hcp.id).subscribe(data => {
        if(data.results.length){
          let res = data.results[0];
          // console.log(res);
          this.form = {};
          this.form.id = res.id;
          this.form.overview = res.overview;
          this.form.summary = res.summary;
          this.form.confirm = res.confirm;
          this.form.approval = res.approval;
          this.signatureImg = this.form.confirm.physician_signature

          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement,{
            onEnd: (event) => {
              const base64Data = this.signaturePad.toDataURL();
              this.signatureImg = base64Data;
              this.form.confirm.physician_signature = base64Data;
            }
          });

          if( this.signatureImg !='' && this.signatureImg ) {
            this.signaturePad.fromDataURL( this.signatureImg )
          }
          // this.patchKnownData(storedData)
        }
        else {
          this.defaultData()
          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement,{
            onEnd: (event) => {
              const base64Data = this.signaturePad.toDataURL();
              this.signatureImg = base64Data;
              this.form.confirm.physician_signature = base64Data;
            }
          });
          // this.router.navigate(['/account-department-details'])
        }
      }, error =>{
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.defaultData()
     
      // this.router.navigate(['/account-department-details'])
    }
  }

  patchKnownData(data){
    if(!this.form.tp2_overview.physician_name)
      this.form.tp2_overview.physician_name = data.hcp.title + " " + data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.tp2_overview.hospital)
      this.form.tp2_overview.hospital = data.account.name
    if(!this.form.tp2_overview.cellphone)
      this.form.tp2_overview.cellphone = data.hcp.mobile
    if(!this.form.tp2_overview.email)
      this.form.tp2_overview.email = data.hcp.email

    if(!this.form.tp2_corpath.physician_name)
      this.form.tp2_corpath.physician_name = data.hcp.title + " " + data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.tp2_corpath.hospital)
      this.form.tp2_corpath.hospital = data.account.name
  }


  defaultData() {
    this.form = {}
    this.form.overview = {};
    this.form.overview.your_name = '';
    this.form.overview.physician_name = '';
    this.form.overview.hospital = '';
    this.form.overview.cellphone = '';
    this.form.overview.office = '';
    this.form.overview.email = '';

    this.form.summary = {};
    this.form.summary.case_1 = {};
    this.form.summary.case_2 = {};
    this.form.summary.case_3 = {};
    this.form.summary.case_4 = {};
    this.form.summary.case_5 = {};
    this.form.summary.case_1.date = '';
    this.form.summary.case_1.procedure = '';
    this.form.summary.case_1.comments = '';
    this.form.summary.case_2.date = '';
    this.form.summary.case_2.procedure = '';
    this.form.summary.case_2.comments = '';
    this.form.summary.case_3.date = '';
    this.form.summary.case_3.procedure = '';
    this.form.summary.case_3.comments = '';
    this.form.summary.case_4.date = '';
    this.form.summary.case_4.procedure = '';
    this.form.summary.case_4.comments = '';
    this.form.summary.case_5.date = '';
    this.form.summary.case_5.procedure = '';
    this.form.summary.case_5.comments = '';


    this.form.confirm = {};
    this.form.confirm.physician_name = '';
    this.form.confirm.corindus_trainer = '';
    this.form.confirm.date_of_phase1_completion = '';
    this.form.confirm.confirm_trained = '';
    this.form.confirm.physician_signature = '';

    this.form.approval = {};
    this.form.signature = '';

  }

  saveDraft(){
    if( this.form.id ) {
      this.trainingsService.updateCaseTraining(this.form.id, this.form).subscribe(data =>{
        console.log(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Case Training Form has been saved successfully."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } else {
      let storedData = this.dsService.currentDataValue
      this.form.account = storedData.account.id;
      this.form.hcp = storedData.hcp.id

      this.trainingsService.createCaseTraining(this.form).subscribe(data =>{
        this.form.id = data.id;
        this.dialogService.alertDialog({
          title: "Success",
          message: "Case Training Form has been added successfully."
        })
      }, error => {
        console.log( error )
        this.dialogService.errorDialogNew(error)
      })
    }
  }

  complete() {
    this.form.completed = true

    if( this.form.id ) {
      this.trainingsService.updateCaseTraining(this.form.id, this.form).subscribe(data =>{
        console.log(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Case Training Form has been Completed."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } else {
      let storedData = this.dsService.currentDataValue
      this.form.account = storedData.account.id;
      this.form.hcp = storedData.hcp.id

      this.trainingsService.createCaseTraining(this.form).subscribe(data =>{
        this.form.id = data.id;
        this.dialogService.alertDialog({
          title: "Success",
          message: "Case Training Form has been Completed"
        })
      }, error => {
        console.log( error )
        this.dialogService.errorDialogNew(error)
      })
    }

  }

  startDrawing(event: Event) {
    console.log(event);
    // works in device not in browser

  }

  moved(event: Event) {
  }

  clearPad() {
    this.signaturePad.clear();
    this.form.confirm.physician_signature = null;
  }

}
