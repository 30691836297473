import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountsService } from '../services/accounts.service';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  accounts: any
  constructor(private accountsService: AccountsService, private modalService: NgbModal, private dsService: DataSharingService, private router: Router, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.accountsService.getAllAccounts().subscribe(data => {
      // console.log(data)
      this.accounts = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  changeStatus(value, index){
    console.log(value, index)
    this.accounts[index].is_active = value
  }

  getAccountDetails(item){
    let data = this.dsService.currentDataValue
    data.account = {id: item.id, name: item.name}
    this.dsService.storeData(data)
    this.router.navigate(['/account-details'])
  }


  // Add New Account

  modalRef: NgbModalRef
  modalTitle: string
  action: "view" | "edit" | "add"
  selectedAccount: any
  accountRoleIndex: any

  openAccountModal(modal){
    this.modalRef = this.modalService.open(modal, {size: 'xl'})
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  addAccount(modal){
    this.action = "add";
    this.modalTitle = "Add Account"
    this.selectedAccount = undefined;
    this.openAccountModal(modal)
  }

  // editAccount(modal, account, index){
  //   this.action = "edit";
  //   this.modalTitle = "Edit Account"
  //   this.accountRoleIndex = index;
  //   this.selectedAccount = account;
  //   this.openAccountModal(modal)
  // }

  saveAccount(data){   
    if(data && this.action=="add"){
      this.accountsService.createAccount(data).subscribe(data => {
        this.accounts.push(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Successfully created new Account"
        })
        this.modalRef.dismiss()
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } 
  }

}
