import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serverURL, usersURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  loginUser(userCredentials): Observable<any>{
    return this.http.post<any>(serverURL + "users-api/login/", userCredentials)
  }

  logoutUser(): Observable<any>{
    return this.http.post<any>(serverURL + "users-api/logout/", {})
  }

  forgotPasswordRequest(email){
    return this.http.post(serverURL + "password-reset/", {email: email}, { observe: 'response' })
  }

  resetPassword(password: string, token: string){
    return this.http.post(serverURL + "password-reset/confirm/", {password: password, token: token}, { observe: 'response' })
  }

  validateToken(token: string){
    return this.http.post(serverURL + "password-reset/validate_token/", {token: token}, { observe: 'response' })
  }

  changePassword(old_password: string, new_password: string){
    return this.http.patch(serverURL + "change-password", {old_password: old_password, new_password: new_password}, { observe: 'response' })
  }

  getAllUsers(): Observable<any>{
    return this.http.get<any>(usersURL)
  }

  createUser(userDetails): Observable<any>{
    return this.http.post<any>(usersURL, userDetails)
  }

  updateUser(id, user): Observable<any> {
    return this.http.patch<any>(usersURL+id+"/", user);
  }

  getUser(id): Observable<any>{
    return this.http.get<any>(usersURL+id+"/")
  }

  getRegions(): Observable<any>{
    return this.http.get<any>(serverURL+"users-api/get-regions/")
  }

}
