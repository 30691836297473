<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
</div>

<div class="modal-body">
  <form [formGroup]="departmentForm" (ngSubmit)="submit()">

    <div class="form-group row">
      <label for="name" class="col-md-4">Name</label>
      <div class="col-md-8" [ngClass]="{'was-validated': (name.dirty || name.touched)}">
        <input class="form-control" type="text" id="name" formControlName="name" required>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
          <div *ngIf="name.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <input class="form-control" name="status" type="hidden" id="status" value="Active" >

    <div class="form-group">
        <div class="offset-md-4 col-md-4">
            <button type="submit" class="button button--primary display-block" [disabled]="!departmentForm.valid" >Save</button>
        </div>
    </div>
  </form>
</div>