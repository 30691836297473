import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/services/accounts.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DepartmentsService } from 'src/app/services/departments.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HcpRolesService } from 'src/app/services/hcp-roles.service';

@Component({
  selector: 'app-hcp',
  templateUrl: './hcp.component.html',
  styleUrls: ['./hcp.component.scss']
})
export class HcpComponent implements OnInit {
  
  @Input() modalTitle: string;
  @Input() action: string;
  @Input() data;
  @Output() sendHcpForm = new EventEmitter<string>();
  roles: any[]
  departments: any[] = []
  accounts: any[] = []
  availablePath: any
 

  constructor(private fb: FormBuilder, private accountsService: AccountsService, private departmentsService: DepartmentsService, private dsService: DataSharingService, private hcpRolesService: HcpRolesService, private dialogService: DialogService) { }

  ngOnInit(): void {
    if(this.data){
      this.hcpForm.patchValue(this.data)
      this.hcpForm.get("role").setValue(this.data.role.id)
    }
    if(this.action=="add" || this.action=="edit"){
      this.getHcpRoles()
      this.getDepartmentsAndAccount()
    }
    else if(this.action=="view"){
      this.hcpForm.disable()
      this.roles = [this.data.role]
    }


    this.hcpForm.get('role').valueChanges.subscribe(data => {
      if( data ) {
        this.getRoleType( data )
      } else {
        this.availablePath = null
      }
    })


  }  

  getHcpRoles(){
    this.hcpRolesService.getAllHcpRoles().subscribe(data => {
      this.roles = data.results
      if( this.action=="edit" ) {
        this.getRoleType( this.data.role.id )
      }
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  getDepartmentsAndAccount(){
    let data = this.dsService.currentDataValue
    
    if(data.departments)
      this.departments = data.departments
    else
      this.fetchDepartments()

    if(data.department)
      this.hcpForm.get("department").setValue(data.department.id)

    if(data.account){
      this.accounts = [data.account]
      this.hcpForm.get("account").setValue(data.account.id)
    }
    else
      this.fetchAccounts()
  }

  fetchDepartments(){
    this.departmentsService.getAllDepartments().subscribe(data => {
      this.departments = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  fetchAccounts(){
    this.accountsService.getAllAccounts().subscribe(data => {
      this.accounts = data.results
      this.hcpForm.get("account").enable()
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  hcpForm = this.fb.group({
    title: ['', Validators.required],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    mobile: [''],
    role: ['', Validators.required],
    department: ['', Validators.required],
    account: [{value: '', disabled: true}, Validators.required],
    path: '',
    is_hcp:[true],
    is_hmt: [false]
  })

  get title() {return this.hcpForm.get('title')}
  get first_name() {return this.hcpForm.get('first_name')}
  get last_name() {return this.hcpForm.get('last_name')}
  get email() {return this.hcpForm.get('email')}
  get mobile() {return this.hcpForm.get('mobile')}
  get role() {return this.hcpForm.get('role')}
  get path() {return this.hcpForm.get('path')}
  get department() {return this.hcpForm.get('department')}
  get account() {return this.hcpForm.get('account')}

  submit(){
    let hcp = this.hcpForm.getRawValue()
    hcp.roleObj = this.roles.find(role => role.id==hcp.role)
    this.sendHcpForm.emit(hcp);
  }

  close(){
    this.sendHcpForm.emit()
    this.hcpForm.reset();
  }

  getRoleType( role ) {
    let roleType = "staff"
    if( this.roles.length > 0 ) {
      this.roles.forEach( e =>{
        if( e.id == role ) {
          roleType = e.type
        }
      })
      this.getAllPaths( roleType )
    }
  }

  getAllPaths( roleType ) {
    switch (roleType) {
      case 'doctor':
        this.availablePath = [
          { slug: "none", name: "None" },
          { slug: "withtiq", name: "Training With TechIQ" },
          { slug: "withoutetiq", name: "Training Without TechIQ" },
          { slug: "neuro", name: "Neurovascular Training" }
        ]
        break;

      case 'staff':
        this.availablePath = [
          { slug: "none", name: "None" },
          { slug: "staff", name: "Staff Training" }
        ]
        break;
      
      case 'management':
        this.availablePath = [
          { slug: "none", name: "None" }
        ]
        break;
    
      default:
        this.availablePath = [
          { slug: "none", name: "None" }
        ]
        break;
    }
  }

}
