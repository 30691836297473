<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
</div>

<div class="modal-body">
  <form [formGroup]="hcpRoleForm" (ngSubmit)="submit()" novalidate>

    <div class="row form-group">
      <label for="name" class="col-md-4">Name</label>
      <div class="col-md-8" [ngClass]="{'was-validated': (name.dirty || name.touched)}">
        <input class="form-control" type="text" id="name" formControlName="name" required>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
          <div *ngIf="name.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label for="type" class="col-md-4">Type</label>
      <div class="col-md-8" [ngClass]="{'was-validated': (type.dirty || type.touched)}">
        <select class="form-control" formControlName="type" id="type" required>
          <option value="">Select an option</option>
          <option *ngFor="let type of types" [value]="type.id">{{type.name}}</option>
        </select>
        <div *ngIf="type.invalid && (type.dirty || type.touched)" class="invalid-feedback">
          <div *ngIf="type.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <input class="form-control" name="status" type="hidden" id="status" value="Active" >

    <div class="form-group">
        <div class="offset-md-4 col-md-4">
            <button type="submit" class="button button--primary display-block" [disabled]="!hcpRoleForm.valid" >Save</button>
        </div>
    </div>
  </form>
</div>