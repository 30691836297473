import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { UserRolesService } from 'src/app/services/user-roles.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() action: string;
  @Input() data;
  @Output() sendUserForm = new EventEmitter<string>();
  checked: any
  roles: any[]
  regions: any[] = []

  constructor(private fb: FormBuilder, private userService: UserService, private userRolesService: UserRolesService, private dialogService: DialogService) { }

  ngOnInit(): void {
    if(this.data){
      this.userForm.patchValue(this.data)

      // console.log( this.data );

      if(this.data.is_global) {
        this.userForm.controls['region'].disable()
        this.userForm.controls['region'].setValue( '' )
      } else {
        let obj = {};
        if(this.data.region)
          obj['region'] = this.data.region.id
        if(this.data.zone)
          obj['zone'] = this.data.zone.id
        if(this.data.country)
          obj['country'] = this.data.country.id
        this.userForm.get('region').setValue(JSON.stringify(obj))
      }

      if(this.data.role) {}
        this.userForm.get('role').setValue(this.data.role.id)

      
    }
    if(this.action=="add" || this.action=="edit"){
      this.getUserRoles()
      this.getRegions()
    }
  }  

  getUserRoles(){
    this.userRolesService.getAllUserRoles().subscribe(data => {
      this.roles = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  getRegions(){
    this.userService.getRegions().subscribe(data => {
      let regions = data;
      for(let i=0;i<regions.length;i++){
        this.regions.push({id: JSON.stringify({"region": regions[i].id}), name: regions[i].name})
        let zones = regions[i].zones
        for(let j=0;j<zones.length;j++){
          this.regions.push({id: JSON.stringify({"region": regions[i].id, "zone": zones[j].id}), name: "--"+zones[j].name})
          let countries = zones[j].countries
          for(let k=0;k<countries.length;k++){
            this.regions.push({id: JSON.stringify({"region": regions[i].id, "zone": zones[j].id, "country": countries[k].id}), name: "----"+countries[k].name})
          }
        }
      }
    })
  }

  userForm = this.fb.group({
    title: ['', Validators.required],
    name: ['', Validators.required],
    email: ['', Validators.required],
    mobile: ['', Validators.required],
    role: ['', Validators.required],
    is_global:  [''],
    region: [''],
    zone: [''],
    country: ['']
  })

  get title() {return this.userForm.get('title')}
  get name() {return this.userForm.get('name')}
  get email() {return this.userForm.get('email')}
  get mobile() {return this.userForm.get('mobile')}
  get role() {return this.userForm.get('role')}
  get region() {return this.userForm.get('region')}
  get is_global() {return this.userForm.get('is_global')}

  submit(){
    let user = this.userForm.value
    if( user.region != undefined && user.region != '' ) {
      user.region = JSON.parse(user.region)
      user.country = user.region.country || null
      user.zone = user.region.zone || null
      user.region = user.region.region
    } else {
      user.region = ''
    }
    this.sendUserForm.emit(user);
  }

  close(){
    this.sendUserForm.emit()
    this.userForm.reset();
  }

  regionSelector() {
    if( this.userForm.value.is_global ) {
      this.userForm.controls['region'].disable()
      this.userForm.controls['region'].setValue( '' )
    } else {
      this.userForm.controls['region'].enable()
    }
  }
}
