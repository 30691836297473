<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
          <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					Account Checklist
				</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row align-center">
	<div class="col-lg-4 col-md-7">
		<div class="hero-block__headings">
			<h1 class="hero-block__title">Account Checklist</h1>
		</div>
	</div>
  <div class="col-lg-8 col-md-5 flex-reverse">
		<div>
			<button class="button display-block btn-sm" routerLink="/account-details">Back</button>
		</div>
    <div>
			<button class="button button--primary display-block btn-sm" routerLink="/account-site-visit">Site Visit</button>
		</div>
  </div>
</div>  

<div class="row">
	<div class="col-md-12">
		<div class="checklist-container">
			<div *ngFor="let item of account_checklist; index as index" class="checklist-block">
				<div class="block-header">
					<div class="block-title">
						{{item.checklist.name}}
					</div>
					<div class="block-edit">
						<i class="material-icons" (click)="editChecklist(modal, item, index)">settings</i>
					</div>
				</div>
				<div class="block-content">
					<div class="block-sub-list">
						<div *ngFor="let sub of item.acc_subchecklists; let j = index" class="block-single">
							<div class="subchecklist-title">
								<i *ngIf="sub.subchecklist.critical && (sub.value=='Yes' || sub.value=='N/A')" class="sublist-icon critical-icon table-icon icon-exclamationmark complete-icon" title="Critical Item Complete"></i>
								<i *ngIf="sub.subchecklist.critical && (sub.value=='No' || sub.value=='no')" class="sublist-icon critical-icon table-icon icon-exclamationmark" title="Critical Item Incomplete"></i>
								<strong>{{sub.subchecklist.name}}</strong>
							</div>

							<div class="subchecklist-data">
								<label>Details: </label>
								<div class="subchecklist-completed">
									<select class="form-control" style="padding:4px 6px;height:30px;line-height:30px;" name="value" [(ngModel)]="sub.value" (ngModelChange)="detectValueChange( index, j, $event)">
										<option value="Yes">Yes</option>
										<option value="N/A">N/A</option>
										<option value="No">No</option>
									</select>
								</div>
								<div class="subchecklist-details">
									<input [type]="sub.subchecklist.type" name="details" placeholder="Details" style="padding: 4px 6px;height: 30px;line-height: 30px;outline: none;border: 1px solid;border-radius: 3px!important;" [(ngModel)]="sub.details" (ngModelChange)="detectDetailsChange( index, j, $event)">
								</div>
								<div *ngIf="sub.updated_time&&sub.updated_by" class="subschecklist-info">
									<i class="material-icons">info</i>
									<div class="modal-info">
										Updated at {{sub.updated_time | date:'dd/MM/yyyy' }} by {{ getUserName(sub.updated_by) }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="block-status">
						<div class="block-progress">
							<strong style="font-size:22px;">Progress:</strong>
							<strong *ngIf="item.completed" class="complete-color">
								Completed!
							</strong>
							<strong *ngIf="!item.completed && item.critical>0" class="incomplete-color">
								Critical Item Incomplete!
							</strong>
							<p *ngIf="!item.completed" style="font-style:italic;">
								{{ item.incomplete }} items(s) left!
							</p>
						</div>
						<div class="block-date">
							<p><strong>Due Date: </strong>{{item.due_date || 'No date'}}</p>
							<p><strong>Comments: </strong>{{item.comments}}</p>
							<p><strong>Updated At: </strong>{{item.updated_at | date:'dd/MM/yyyy'}}</p>
						</div>
					</div>
				</div>
			</div>

		</div>


		<!-- <div class="table-responsive">				
		<table class="table table-features">
			<thead>
				<tr>
					<th colspan="4">Checklist</th><th>Due Date</th><th>Progress</th><th>Updated Date</th>
					<th>Actions</th>
				</tr>
			</thead>
			<tbody>
			
				<ng-container *ngFor="let item of account_checklist; index as index"> 
				<tr>
					<td colspan="4" class="checklist-title underline pointer" (click)="editChecklist(modal, item, index)">
						<span ngbTooltip="Click to Edit" class="d-flex">
							{{item.checklist.name}}&nbsp;-&nbsp;<i class="material-icons">edit_calendar</i>
						</span>
					</td>
					<td style="width: 10%;">
						<strong>{{item.due_date || 'No date'}}</strong>
					</td>
					<td>
							<strong *ngIf="item.completed" class="complete-color">
								Completed!
							</strong>
							<strong *ngIf="!item.completed && item.critical>0" class="incomplete-color">
								Critical Item Incomplete!
							</strong>
							<p *ngIf="!item.completed" style="font-style:italic;">
								{{ item.incomplete }} items(s) left!
							</p>
					</td>							
					<td>
						{{item.updated_at | date:'dd/MM/yyyy'}}
					</td>
					<td style="text-align:center">
						<button class="button button--primary display-block btn-sm pointer" (click)="editChecklist(modal, item, index)">Edit</button>
					</td>
				</tr>

				<tr style="min-height:59px;" class="sub-checklist" *ngFor="let sub of item.acc_subchecklists; let j = index">
					<td style="position:relative;border:none;padding-left:30px;border-top:1px dashed #ddd" class="sub-name" colspan="4">
						<i *ngIf="sub.subchecklist.critical && (sub.value=='Yes' || sub.value=='N/A')" class="sublist-icon critical-icon table-icon icon-exclamationmark complete-icon" title="Critical Item Complete"></i>
						<i *ngIf="sub.subchecklist.critical && (sub.value=='No' || sub.value=='no')" class="sublist-icon critical-icon table-icon icon-exclamationmark" title="Critical Item Incomplete"></i>
						<span>-</span>
						{{sub.subchecklist.name}}
					</td>
					<td style="border:none;border-top:1px dashed #ddd">
						<select class="form-control" style="padding:4px 6px;height:30px;line-height:30px;" name="value" [(ngModel)]="sub.value" (ngModelChange)="detectValueChange( index, j, $event)">
							<option value="Yes">Yes</option>
							<option value="N/A">N/A</option>
							<option value="No">No</option>
						</select>
					</td>
					<td style="border:none;border-top:1px dashed #ddd">
						<input [type]="sub.subchecklist.type" name="details"  style="padding: 4px 6px;height: 30px;line-height: 30px;outline: none;border: 1px solid;border-radius: 3px!important;" [(ngModel)]="sub.details" (ngModelChange)="detectDetailsChange( index, j, $event)">
					</td>
					<td>
						<input type="date" name="updated_date" disabled style="padding: 4px 6px;height: 30px;line-height: 30px;outline: none;border: 1px solid;border-radius: 3px!important;" [ngModel]="sub.updated_date">
					</td>
					<td style="border:none;text-align:center;border-top:1px dashed #ddd">
						<input class="button display-block btn-sm" type="submit" (click)="updateAll()" value="Update">
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>	
	</div> -->
</div>
<div class="d-flex col mb-4 action" style="justify-content: space-between;">
	<button class="button button--primary" style="font-size: 1.5rem;" type="button" (click)="installationComplete()">Installation Complete</button>
	<button class="button button--primary save-btn-fixed" style="font-size: 1.5rem;" type="button" (click)="updateAll()">Save</button>
</div>

<ng-template #modal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Edit Checklist</h4>
		<button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
	</div>
	
	<div class="modal-body">
		<div class="form-group row">
			<label for="name" class="col-md-4">Due Date</label>
			<div class="col-md-8">
				<input type="date" class="form-control" [(ngModel)]="selectedChecklist.due_date">
			</div>
		</div>

		<!-- <div class="form-group row">
			<label for="name" class="col-md-4">Completed?</label>
			<div class="col-md-8">
				<select class="form-control" [(ngModel)]="selectedChecklist.completed">
					<option value="Yes">Yes</option>
					<option value="N/A">N/A</option>
					<option value="No">No</option>
				</select>
			</div>
		</div> -->

		<div class="form-group row">
			<label for="name" class="col-md-4">Comments</label>
			<div class="col-md-8">
				<input type="text" class="form-control" [(ngModel)]="selectedChecklist.comments">
			</div>
		</div>

		<div class="form-group">
			<div class="offset-md-4 col-md-4">
				<button type="submit" (click)="saveChecklist()" class="button button--primary display-block">Save</button>
			</div>
		</div>
	</div>
</ng-template>