import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { accountChecklistURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AccountChecklistService {

  constructor(private http: HttpClient) { }

  getAccountChecklist(id): Observable<any>{
    return this.http.get<any>(accountChecklistURL+id+"/")
  }

  updateAccountChecklist(id, accountCheklist): Observable<any> {
    return this.http.patch<any>(accountChecklistURL+id+"/", accountCheklist);
  }

}
