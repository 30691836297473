import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AccountsService } from 'src/app/services/accounts.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HcpService } from 'src/app/services/hcp.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.scss']
})
export class ProcedureComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() action: string;
  @Input() data;
  @Output() ownerName;
  regions: any[] = []
  users: any[] = []
  keyPersons;
  tableType;
  accounts: any
  allHCP: any
  options: any
  selectedProcedure:any

  constructor(private fb: FormBuilder, private userService: UserService, private dialogService: DialogService, private procedureService: ProcedureService, private accountsService: AccountsService, private hcpService: HcpService, private dsService: DataSharingService) { }

  ngOnInit(): void {

    this.options = this.procedureService.getOptions();

    this.userService.getAllUsers().subscribe(data => {
      this.users = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })

    this.accountsService.getAllAccounts().subscribe(data => {
      this.accounts = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })

    this.ProcedureForm.get('account').valueChanges.subscribe(data => {
      let selectedAccount = data
      if( data ) {
        this.hcpService.getAllHcpsbyAccount( selectedAccount ).subscribe(data => {
            this.allHCP = data.results
            this.allHCP.forEach(element => {
              element.valueName = element.title +"."+ element.first_name +" "+ element.last_name
            });
        }, error => {
          this.dialogService.errorDialogNew(error)
        })
      } else {
        this.allHCP = []
      }
    })

    setTimeout(() => {
      this.selectedProcedure = this.dsService.currentDataValue.procedure.id
      this.procedureService.getProcedure(this.selectedProcedure).subscribe(data => {
        this.ProcedureForm.patchValue(data)
        this.getUserName( this.ProcedureForm.get('owner').value )
      }, error => {
        this.dialogService.errorDialogNew(error)
      })

      setTimeout(() => {

        
      }) 


    }) 

  }

  ProcedureForm = this.fb.group({
    owner: ['', Validators.required],
    procedure_number: ['', Validators.required],
    procedure_date: ['', Validators.required],
    account: ['', Validators.required],
    corpath_case: ['', Validators.required],
    corpath_case_outcome: [''],
    corpath_system: ['', Validators.required],
    procedure_origin: [''],
    procedure_type: [''],
    number_corpath_case_lost: [''],
    contact_name: [[]],
    scrub1: [[]],
    cinical_presentation: [''],
    access: [''],
    fellow: [[]],
    case_description: [''],
    corpath_room: [''],
    lot_number: [''],
    number_of_cassette_on_shelf: [''],
    number_of_cassette_used: [''],
    independent: [false],
    hybrid: [''],
    mnm: [''],
    corpath_procedure_time: [''],
    xray_time: [''],
    total_gy_at_table: [''],
    total_gy_at_cocpit: [''],
    sheath: [''],
    catheter: [''],
    guide_length: [''],
    guide_wire: [''],
    predil_ballon: [''],
    stent: [''],
    postdil_ballon: [''],
    other_devices: [''],
    other_neuro_devices: [''],
    lesion_location: [''],
    lesion_length:[''],
    plaque_burden:[''],
    techiq_movements:[[]],
    techiq_user_description_and_feedback:[''],
    techiq_type:[''],
    hcp:'0106220d-1d89-410d-9ec1-469c5e5d6aca'
  })

  get name() {return this.ProcedureForm.get('name')}
  get email() {return this.ProcedureForm.get('email')}

  submit(){
    let procedure = this.ProcedureForm.value

    this.procedureService.updateProcedure( this.selectedProcedure ,procedure ).subscribe(data =>{
      this.ProcedureForm.patchValue(data)
      this.dialogService.alertDialog({
        title: "Success",
        message: "Procedure Updated"
      })
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
    console.log( procedure )
  }

  getUserName(id) {
     let users = this.users
    let name = '';
    users.forEach(e => {
      if( e.id == id ) {
        name = e.name
      }
    })
    this.ownerName = name
  }


}
