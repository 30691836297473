<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span property="name">Departments</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row">
  <div class="col-md-12">
    <div class="hero-block__headings">
      <a (click)="add(modal)" class="pointer hd-icon icon icon--primary" title="Add New Department Type" >
        <i class="icon-plus"></i>
      </a>
      <h1 class="aw-h1 hero-block__title">Department Type</h1>
    </div>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
        <table class="table table-features">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of departments; index as index">            
              <td>{{ item.name }}</td>
              <td>
                <i *ngIf="item.is_active" ngbTooltip="Update Status" (click)="changeStatus(false, index)" class="pointer material-icons text-success">thumb_up</i>
                <i *ngIf="!item.is_active" ngbTooltip="Update Status" (click)="changeStatus(true, index)" class="pointer material-icons text-danger">thumb_down</i>
              <td>
                <button class="button button--primary display-block btn-sm" (click)="edit(modal, item, index)"> Edit</button>
              </td>
            </tr>
          </tbody>
        </table>

    </div>
  </div>
</div>

<ng-template #modal let-modal>
  <app-department [title]="title" [action]="action" [data]="selectedDepartment" (sendDepartmentForm)="save($event)"></app-department>
</ng-template>