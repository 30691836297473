<div class="traning-view">
    <div *ngIf="hcpPath">
        <div *ngFor="let training of hcpPath" class="lvl-{{ training.phase }}">
            <a *ngIf="training.status" href="javascript:void(0)" (click)="navigate( $event, training.slug )">
                {{ training.name}}
                <i *ngIf="training.is_completed" class="complete-icon table-icon icon-checkmark" title="Completed"></i>
            </a>
            <span *ngIf="!training.status">{{ training.name}}</span>
        </div>
    </div>

    <div *ngIf="!hcpPath">
        Please choose a training path.
    </div>

</div>

