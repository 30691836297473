import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { hcpURL, proceduresURL, serverURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureService {

  options: any;
  
  procedureOrigin: any[] = [
    { value: "case-observed", text: "Case Observed" },
    { value: "independent-case", text: "Independent Case" },
    { value: "customer-provide-feedback", text: "Customer provided feedback" },
  ]

  procedureType: any[] = [
    { value: "pci", text: "PCI" },
    { value: "nvi", text: "NVI" },
  ]

  corpathCase: any[] = [
    { value: "yes", text: "Yes" },
    { value: "no", text: "No" }
  ]

  corpathCaseOutCome: any[] = [
    { value: "completed-ni", text: "Case Completed - No issues" },
    { value: "completed-error", text: "Case Completed - Error occured, case continued" },
    { value: "failed-convert", text: "Case Failed - converted to manual during case" },
    { value: "failed-startup", text: "Case Failed - product failed at start up" },
  ]

  corpathSystem: any[] = [
    { value: "grx", text: "Corpath GRX" },
    { value: "nvi", text: "Corpath NVI" },
  ]

  cinicalPresentation: any[] = [
    { value: "elective-procedure", text: "Elective procedure" },
    { value: "scheduled-procedure", text: "Scheduled procedure" },
    { value: "scheduled-nvi", text: "Scheduled NVI" },
  ]

  access: any[] = [
    { value: "right-radial", text: "Right Radial" },
    { value: "left-radial", text: "Left Radial" },
    { value: "right-femoral", text: "Right Femoral" },
    { value: "left-femoral", text: "Left Femoral" },
    { value: "brachial", text: "Brachial" },
    { value: "femoral-contralateral", text: "Femoral Contralateral" },
    { value: "femoral-ipsilateral-antegrade", text: "Femoral Ipsilateral Antegrade" },
    { value: "femoral-ipsilateral-retrograde", text: "Femoral Ipsilateral Retrograde" },
    { value: "pedal", text: "Pedal" },
    { value: "other", text: "Other" },
    { value: "unknown", text: "Unknown" },
  ]

  hybrid: any[] = [
    { value: "yes", text: "Yes" },
    { value: "no", text: "No" },
  ]

  mnm: any[] = [
    { value: "yes", text: "Yes - Used both" },
    { value: "used-milimeter", text: "Used milimeter moverment feature only" },
    { value: "used-measurement", text: "Used measurement feature only" },
    { value: "no", text: "No - didn't use either feature" },
  ]

  plaqueBurden: any[] = [
    { value: "mold", text: "Mild" },
    { value: "moderate", text: "Moderate" },
    { value: "severe-calcium", text: "Severe Calcium" },
    { value: "thrombus", text: "Thrombus" },
  ]

  techIQMovements: any[] = [
    { value: "ror", text: "Rotate on Retract" },
    { value: "wiggle", text: "Wiggle" },
    { value: "spin", text: "Spin" },
    { value: "dotter", text: "Dotter" },
    { value: "cs-2", text: "Constant Speed 2mm" },
    { value: "cs-5", text: "Constant Speed 5mm" },
  ]

  techIQTypes: any[] = [
    { value: "active-fixation", text: "Active Fixation" },
    { value: "limited-speed", text: "Limited Speed" },
  ]


  constructor(private http: HttpClient) { }

  getOptions() {
    this.options = {}
    this.options.procedureOrigin = this.procedureOrigin
    this.options.procedureType = this.procedureType
    this.options.corpath_case = this.corpathCase
    this.options.corpathCaseOutCome = this.corpathCaseOutCome
    this.options.corpathSystem = this.corpathSystem
    this.options.cinicalPresentation = this.cinicalPresentation
    this.options.access = this.access
    this.options.hybrid = this.hybrid
    this.options.mnm = this.access
    this.options.plaqueBurden = this.plaqueBurden
    this.options.techIQMovements = this.techIQMovements
    this.options.techIQTypes = this.techIQTypes
    return this.options;
  }

  createProcedure(procedureDetail): Observable<any>{
    return this.http.post<any>(proceduresURL, procedureDetail)
  }

  getAllProcedure(): Observable<any>{
    return this.http.get<any>(proceduresURL)
  }

  getProcedure(id): Observable<any>{
    return this.http.get<any>(proceduresURL+id+"/")
  }

  updateProcedure(id, procedure): Observable<any> {
    return this.http.patch<any>(proceduresURL+id+"/", procedure);
  }

}
