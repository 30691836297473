import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../services/dialog.service';
import { HcpRolesService } from '../services/hcp-roles.service';

@Component({
  selector: 'app-hcp-roles',
  templateUrl: './hcp-roles.component.html',
  styleUrls: ['./hcp-roles.component.scss']
})
export class HcpRolesComponent implements OnInit {

  hcpRoles;
  modalRef: NgbModalRef
  action: "view" | "edit" | "add"
  title: string
  selectedHcpRole: any
  hcpRoleIndex: number;

  constructor(private modalService: NgbModal, private hcpRolesService: HcpRolesService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.hcpRolesService.getAllHcpRoles().subscribe(data => {
      console.log(data)
      this.hcpRoles = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  changeStatus(value, index){
    let dep = this.hcpRoles[index]
    this.updateHcpRole(dep.id, {is_active: value}, index)
  }

  open(modal) {
    this.modalRef = this.modalService.open(modal)
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  add(modal){
    this.action = "add";
    this.title = "Add Hcp Role"
    this.selectedHcpRole = undefined;
    this.open(modal)
  }

  edit(modal, hcpRole, index){
    this.action = "edit"; 
    this.title = "Edit Hcp Role"
    this.hcpRoleIndex = index;
    this.selectedHcpRole = hcpRole;
    this.open(modal)
  }

  save(hcpRole){
    if(hcpRole){
      if(this.action=="edit"){
        this.updateHcpRole(this.selectedHcpRole.id, hcpRole, this.hcpRoleIndex)
      }
      else if(this.action=="add"){
        this.hcpRolesService.createHcpRole(hcpRole).subscribe(data => {
          this.hcpRoles.push(data)
          this.dialogService.alertDialog({title: "Success", message: "Successfully created Hcp Role."})
        }, error => {
          this.dialogService.errorDialogNew(error)
        })
      }
      this.modalRef.dismiss()
    }
    else{
      this.modalRef.dismiss()
    }
  }

  updateHcpRole(id, changes, index){
    this.hcpRolesService.updateHcpRole(id, changes).subscribe(data => {
      this.hcpRoles.splice(index, 1, data)
      this.dialogService.alertDialog({title: "Success", message: "Successfully updated Hcp Role."})
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

}
