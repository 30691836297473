<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span property="name">Accounts List</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row">
  <div class="col-md-12">
    <div class="hero-block__headings">
      <a (click)="addAccount(accountModal)" class="hd-icon icon icon--primary add-new pointer" title="Add New Account">
        <i class="icon-plus"></i>
      </a>
      <h1 class="aw-h1 hero-block__title">Account</h1>
    </div>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-features">
        <thead>
          <tr>
            <th>Name</th><th>Email</th><th>Status</th><th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of accounts; index as index">
            <td><a (click)="getAccountDetails(item)" class="pointer underline">{{ item.name }}</a></td>

            <td>{{ item.email }}</td>
            <td>
              <i *ngIf="item.is_active" ngbTooltip="Update Status" (click)="changeStatus(false, index)" class="pointer material-icons text-success">thumb_up</i>
              <i *ngIf="!item.is_active" ngbTooltip="Update Status" (click)="changeStatus(true, index)" class="pointer material-icons text-danger">thumb_down</i>
            </td>
            <td>
              <button class="button button--primary btn-sm" (click)="getAccountDetails(item)">View</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #accountModal let-modal>
	<app-account [modalTitle]="modalTitle" [action]="action" [data]="selectedAccount" (sendAccountForm)="saveAccount($event)"></app-account>
</ng-template>