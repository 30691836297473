<div class="form-inner procedure-form">
    <form [formGroup]="ProcedureForm" (ngSubmit)="submit()">
        <section class="infomation-section">
        <div class="form-group section-title">
            <h3>Information</h3>
        </div>
        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="owner" class="col-md-3">Owner</label>
            <div class="col-md-9">
                <input class="form-control" type="hidden" id="owner" formControlName="owner" disabled>
                {{ ownerName }}
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="corpath_case" class="col-md-3">*CorPath Case?</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="corpath_case" id="corpath_case" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let case of options.corpath_case" [value]="case.value">{{case.text}}</option>
                </select>
            </div>
            </div>

        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="procedure_number" class="col-md-3">Procedures Number</label>
            <div class="col-md-9">
                <input class="form-control" type="text" id="procedure_number" formControlName="procedure_number">
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="corpath_case_outcome" class="col-md-3 required">CorPath Case Outcome</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="corpath_case_outcome" id="corpath_case_outcome" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let outCome of options.corpathCaseOutCome" [value]="outCome.value">{{outCome.text}}</option>
                </select>
            </div>
            </div>

        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="procedure_date" class="col-md-3">*Procedures Date</label>
            <div class="col-md-9">
                <input class="form-control" type="date" id="procedure_date" formControlName="procedure_date" required>
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="number_corpath_case_lost" class="col-md-3 required">Number of CorPath Cases lost</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="number_corpath_case_lost" id="number_corpath_case_lost" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let caseLost of options.numberCorpathCaseLost" [value]="caseLost.value">{{caseLost.text}}</option>
                </select>
            </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="account" class="col-md-3">*Account</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="account" id="account" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
                </select>
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="corpath_system" class="col-md-3 required">*CorPath System</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="corpath_system" id="corpath_system" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let system of options.corpathSystem" [value]="system.value">{{system.text}}</option>
                </select>
            </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="procedure_origin" class="col-md-3">Procedure Origin</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="procedure_origin" id="procedure_origin" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let origin of options.procedureOrigin" [value]="origin.value">{{origin.text}}</option>
                </select>
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="contact_name" class="col-md-3">Contact Name</label>
            <div class="col-md-9">
                <ng-select [items]="allHCP" 
                            bindLabel="valueName" 
                            bindValue="id" 
                            formControlName="contact_name" 
                            id="contact_name"
                            class="form-control custom-select"
                            multiple="true"
                        >
                </ng-select> 
            </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="procedure_type" class="col-md-3">Procedure Type</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="procedure_type" id="procedure_type" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let type of options.procedureType" [value]="type.value">{{type.text}}</option>
                </select>
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="scrub1" class="col-md-3">Scrub 1</label>
            <div class="col-md-9">
                <ng-select [items]="allHCP" 
                            bindLabel="valueName" 
                            bindValue="id" 
                            formControlName="scrub1" 
                            id="scrub1"
                            class="form-control custom-select"
                            multiple="true"
                        >
                </ng-select>  
            </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="cinical_presentation" class="col-md-3">Clinical Presentation</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="cinical_presentation" id="cinical_presentation" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let presentation of options.cinicalPresentation" [value]="presentation.value">{{presentation.text}}</option>
                </select>
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="fellow" class="col-md-3">Fellow</label>
            <div class="col-md-9">
                <ng-select [items]="allHCP" 
                        bindLabel="valueName" 
                        bindValue="id" 
                        formControlName="fellow" 
                        id="fellow"
                        class="form-control custom-select"
                        multiple="true"
                    >
                    </ng-select> 
            </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="access" class="col-md-3">Access</label>
            <div class="col-md-9">
                <select class="form-control" formControlName="access" id="access" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let acc of options.access" [value]="acc.value">{{acc.text}}</option>
                </select>
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="case_description" class="col-md-3">Case Description</label>
            <div class="col-md-9">
                <textarea class="form-control" type="text" id="case_description" formControlName="case_description"></textarea>
            </div>
            </div>
        </div>
        </section>

        <section class="infomation-section">
        <div class="form-group section-title">
            <h3>Corpath Information</h3>
        </div>
        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="corpath_room" class="col-md-3">CorPath Room</label>
            <div class="col-md-9">
                <input class="form-control" type="checkbox" id="corpath_room" formControlName="corpath_room">
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="independent" class="col-md-3">Independent?</label>
            <div class="col-md-9">
                <input class="form-control" type="checkbox" id="independent" formControlName="independent">
            </div>
            </div>

        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
                <label for="lot_number" class="col-md-3">Lot Number(for consumables)</label>
                <div class="col-md-9">
                <input class="form-control" type="text" id="lot_number" formControlName="lot_number">
                </div>
            </div>

            <div class="col-md-6 row">
                <label for="hybrid" class="col-md-3">Hybrid?</label>
                <div class="col-md-9">
                <select class="form-control" formControlName="hybrid" id="hybrid" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let h of options.hybrid" [value]="h.value">{{h.text}}</option>
                </select>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
                <label for="number_of_cassette_on_shelf" class="col-md-3"># of Cassett on Shelf</label>
                <div class="col-md-9">
                <input class="form-control" type="text" id="number_of_cassette_on_shelf" formControlName="number_of_cassette_on_shelf">
                </div>
            </div>

            <div class="col-md-6 row">
                <label for="mnm" class="col-md-3">M&M?</label>
                <div class="col-md-9">
                <select class="form-control" formControlName="mnm" id="mnm" required>
                    <option value="">Select an option</option>
                    <option *ngFor="let m of options.mnm" [value]="m.value">{{m.text}}</option>
                </select>
                </div>
            </div>
        </div>

            <div class="form-group row">
            <div class="col-md-6 row">
                <label for="number_of_cassette_used" class="col-md-3"># of Cassett Used</label>
                <div class="col-md-9">
                <input class="form-control" type="text" id="number_of_cassette_used" formControlName="number_of_cassette_used">
                </div>
            </div>
        </div>

        </section>

        <section class="infomation-section">
        <div class="form-group section-title">
            <h3>Radiation Information</h3>
        </div>
        <div class="form-group row">
            <div class="col-md-6 row">
            <label for="corpath_procedure_time" class="col-md-3">CorPath Procedure Time(min)</label>
            <div class="col-md-9">
                <input class="form-control" type="text" id="corpath_procedure_time" formControlName="corpath_procedure_time">
            </div>
            </div>

            <div class="col-md-6 row">
            <label for="total_gy_at_table" class="col-md-3">Total Gy at the Table</label>
            <div class="col-md-9">
                <input class="form-control" type="text" id="total_gy_at_table" formControlName="total_gy_at_table">
            </div>
            </div>

        </div>

        <div class="form-group row">
            <div class="col-md-6 row">
                <label for="xray_time" class="col-md-3">X-Ram Time(min)</label>
                <div class="col-md-9">
                <input class="form-control" type="text" id="xray_time" formControlName="xray_time">
                </div>
            </div>

            <div class="col-md-6 row">
                <label for="total_gy_at_cocpit" class="col-md-3">Total Gy at the Cockpit</label>
                <div class="col-md-9">
                <input class="form-control" type="text" id="total_gy_at_cocpit" formControlName="total_gy_at_cocpit">
                </div>
            </div>
        </div>
        </section>

        <section class="infomation-section">
            <div class="form-group section-title">
                <h3>Devices</h3>
            </div>
            <div class="form-group row">
                <div class="col-md-6 row">
                <label for="sheath" class="col-md-3">Sheath</label>
                <div class="col-md-9">
                    <input class="form-control" type="text" id="sheath" formControlName="sheath">
                </div>
                </div>

                <div class="col-md-6 row">
                <label for="predil_ballon" class="col-md-3">Pre-Dil Ballon(s)</label>
                <div class="col-md-9">
                    <input class="form-control" type="text" id="predil_ballon" formControlName="predil_ballon">
                </div>
                </div>

            </div>

            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="catheter" class="col-md-3">Catheter</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="catheter" formControlName="catheter">
                    </div>
                </div>

                <div class="col-md-6 row">
                    <label for="stent" class="col-md-3">Stent(s)</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="stent" formControlName="stent">
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="guide_length" class="col-md-3">Guide Length</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="guide_length" formControlName="guide_length">
                    </div>
                </div>

                <div class="col-md-6 row">
                    <label for="postdil_ballon" class="col-md-3">Post-Dil Ballon(s)</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="postdil_ballon" formControlName="postdil_ballon">
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="guide_wire" class="col-md-3">Guide Wire</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="guide_wire" formControlName="guide_wire">
                    </div>
                </div>

                <div class="col-md-6 row">
                    <label for="other_devices" class="col-md-3">Other Devices</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="other_devices" formControlName="other_devices">
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6 row">
                </div>

                <div class="col-md-6 row">
                    <label for="other_neuro_devices" class="col-md-3">Other Neuro Devices</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="other_neuro_devices" formControlName="other_neuro_devices">
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="lesion_location" class="col-md-3">Lesion Location(s)</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" id="lesion_location" formControlName="lesion_location">
                    </div>
                </div>

                <div class="col-md-6 row">
                    <label for="lesion_length" class="col-md-3">Lesion Length(s)</label>
                    <div class="col-md-9">
                    <input class="form-control" type="text" id="lesion_length" formControlName="lesion_length">
                    </div>
                </div>
            </div>


            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="plaque_burden" class="col-md-3">Plaque Burden</label>
                    <div class="col-md-9">
                        <select class="form-control" formControlName="plaque_burden" id="plaque_burden" required>
                            <option value="">Select an option</option>
                            <option *ngFor="let burden of options.plaqueBurden" [value]="burden.value">{{burden.text}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </section>

        <section class="infomation-section">
            <div class="form-group section-title">
                <h3>techIQ</h3>
            </div>
            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="techiq_movements" class="col-md-3">Movements</label>
                    <div class="col-md-9">
                        <ng-select [items]="options.techIQMovements" 
                            bindLabel="text" 
                            bindValue="value" 
                            formControlName="techiq_movements" 
                            id="techiq_movements"
                            class="form-control custom-select"
                            multiple="true"
                        >
                        </ng-select> 
                    </div>
                </div>

                <div class="col-md-6 row">
                    <label for="techiq_user_description_and_feedback" class="col-md-3">User Description and FeedBack</label>
                    <div class="col-md-9">
                        <textarea class="form-control" type="text" id="techiq_user_description_and_feedback" formControlName="techiq_user_description_and_feedback"></textarea>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-6 row">
                    <label for="techiq_type" class="col-md-3">TechIQ type</label>
                    <div class="col-md-9">
                        <div *ngFor="let techIQType of options.techIQTypes" class="radio-btn">
                            <input class="form-control" type="radio" id="{{techIQType.value}}" name="techiq_type" formControlName="techiq_type" value="{{techIQType.value}}">
                            <label for="{{techIQType.value}}">{{techIQType.text}}</label>
                        </div>
                    </div>
                </div>

            </div>

        </section>



        <input class="form-control" name="status" type="hidden" id="status" value="Active" >

        <div class="form-group">
            <div class="offset-md-8 col-md-4 text-right">
                <button type="submit" class="button button--primary display-block" >Update</button>
            </div>
        </div>
    </form>
</div>