<div [formGroup]="form" class="form-group row">
    <div class="col-md-12">
        <div class="form-row-header">
            <label>Role</label>
            <label>Name</label>
            <label>Contact Detail</label>
        </div>
        <div formArrayName="persons" *ngFor="let person of rows.controls; let i = index;">
            
            <div class="form-row" [formGroupName]="i">
                <input type="text" formControlName="title" placeholder="Role">
                <input type="text" formControlName="name" placeholder="Name">
                <input type="text" formControlName="contact_detail" placeholder="Contact Detail">
            </div>
        </div>
    </div>
</div>

<div class="form-group btn-action">
    <div>
        <button type="submit" (click)="addRow()" class="button button--primary display-block" >Add Row</button>
    </div>
    <div>
        <button type="submit" (click)="removeRow()" class="button button--primary display-block" >Delete Row</button>
    </div>
    <div *ngIf="type==='edit'">
        <button type="submit" (click)="saveKeyPerson()" class="button button--primary display-block" >Save</button>
    </div>
</div>