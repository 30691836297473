import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { checklistURL, serverURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private http: HttpClient) { }

  getChecklistbyAccount(account): Observable<any>{
    return this.http.get<any>(serverURL+"accounts-api/get-account-checklist"+"?account="+account)
  }

  updateAccountChecklist(accountChecklist): Observable<any>{
    return this.http.patch<any>(serverURL+"accounts-api/update-account-checklist", accountChecklist)
  }

}
