import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from './services/authentication.service';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'corindus-webapp';
  isLoggedIn: boolean = false
  isWelcomePage: boolean = true
  url: string;
  currentUser: any
  menuItems: any = [
    {name: "Users", link: "/users"},
    // {name: "User Roles", link: "/users-roles"},
    // {name: "Offices", link: "/offices"},
    {name: "Accounts", link: "/accounts"},
    {name: "Department Types", link: "/departments"},
    {name: "Procedures", link: "/procedures"},
    {name: "HCP Roles", link: "/hcp-roles"},
    // {name: "Checklist Settings", link: "/checklist-settings"}
  ]

  constructor(private router: Router, private authenticationService: AuthenticationService, private loaderService: LoaderService, private spinner: NgxSpinnerService){
    
    // authenticationService.removeUser()

    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.url = val.url
        this.isWelcomePage = this.url=="/" ?  true: false
        if(this.url.slice(0,15) == '/reset-password')
          this.url = this.url.slice(0,15)
      }
    });

    this.authenticationService.currentUser.subscribe(data => {
      // console.log(data)
      this.isLoggedIn = data? true: false
      this.currentUser = data
    });

    this.loaderService.isLoading.subscribe((v) => {
      if(v)
        this.spinner.show()
      else
        this.spinner.hide()
    });
  }

  ngOnInit(){
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.url = val.url
        this.isWelcomePage = this.url=="/" ?  true: false
      }
    });
  }

  logout(){
    this.authenticationService.logout()
  }
}
