import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-hcp-role',
  templateUrl: './hcp-role.component.html',
  styleUrls: ['./hcp-role.component.scss']
})
export class HcpRoleComponent implements OnInit {

  @Input() title: string;
  @Input() action: string;
  @Input() data;
  @Output() sendHcpRoleForm = new EventEmitter<string>();
  types = [
    {id: 'doctor', name: "Doctor"},
    {id: 'staff', name: "Staff"},
    {id: 'management', name: "Management"}
  ]

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if(this.data)
      this.hcpRoleForm.patchValue(this.data)
  }  

  hcpRoleForm = this.fb.group({
    name: ['', Validators.required],
    type: ['', Validators.required]
  })

  get name() {return this.hcpRoleForm.get('name')}
  get type() {return this.hcpRoleForm.get('type')}

  submit(){
    this.sendHcpRoleForm.emit(this.hcpRoleForm.value);
  }

  close(){
    this.sendHcpRoleForm.emit()
    this.hcpRoleForm.reset();
  }

}
