import { Injectable } from '@angular/core';

export const serverURL = "http://34.255.128.232:8000/"

export const accountsURL = serverURL + "accounts-api/accounts/"
export const departmentsURL = serverURL + "accounts-api/departments/"
export const siteVisitURL = serverURL + "accounts-api/site-visits/"
export const checklistURL = serverURL + "accounts-api/checklists/"
export const accountChecklistURL = serverURL + "accounts-api/account-checklists/"

export const hcpRolesURL = serverURL + "healthcare-api/hcp-roles/"
export const hcpURL = serverURL + "healthcare-api/hcps/"
export const hcpnontechTrainingURL = serverURL + "healthcare-api/physician-phase1-non-tech-trainings/"
export const hcptechTrainingURL = serverURL + "healthcare-api/physician-phase1-tech-trainings/"
export const caseTrainingURL = serverURL + "healthcare-api/case-trainings/"
export const staffTrainingURL = serverURL + "healthcare-api/staff-trainings/"

export const hcpneurop1TrainingURL = serverURL + "healthcare-api/neurop1-trainings/"
export const hcpneurop2TrainingURL = serverURL + "healthcare-api/neurop2-trainings/"
export const hcpneuroCaseTrainingURL = serverURL + "healthcare-api/neurocase-trainings/"

export const proceduresURL = serverURL + "accounts-api/procedure/"



export const usersURL = serverURL + "users-api/users/"
export const userRolesURL = serverURL + "users-api/user-roles/"


export const privateKey = "Corindus2021Kothari"

@Injectable()
export class ConfigService {

  constructor() { }

}