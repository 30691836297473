import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { hcpnontechTrainingURL, hcptechTrainingURL, caseTrainingURL, staffTrainingURL, hcpneurop1TrainingURL, hcpneurop2TrainingURL, hcpneuroCaseTrainingURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingsService {

  constructor(private http: HttpClient) { }

  getHcpNonTechTrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(hcpnontechTrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createHcpNonTechTraining(form): Observable<any> {
    return this.http.post<any>(hcpnontechTrainingURL, form);
  }

  updateHcpNonTechTraining(id, form): Observable<any> {
    return this.http.patch<any>(hcpnontechTrainingURL+id+"/", form);
  }

  getHcpTechTrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(hcptechTrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createTechHcpTraining(form): Observable<any> {
    return this.http.post<any>(hcptechTrainingURL, form);
  }

  updateTechHcpTraining(id, form): Observable<any> {
    return this.http.patch<any>(hcptechTrainingURL+id+"/", form);
  }

  getCaseTrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(caseTrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createCaseTraining(form): Observable<any> {
    return this.http.post<any>(caseTrainingURL, form);
  }

  updateCaseTraining(id, form): Observable<any> {
    return this.http.patch<any>(caseTrainingURL+id+"/", form);
  }

  getStaffTrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(staffTrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createStaffTraining(form): Observable<any> {
    return this.http.post<any>(staffTrainingURL, form);
  }

  updateStaffTraining(id, form): Observable<any> {
    return this.http.patch<any>(staffTrainingURL+id+"/", form);
  }

  getHcpNeuroP1TrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(hcpneurop1TrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createHcpNeuroP1Training(form): Observable<any> {
    return this.http.post<any>(hcpneurop1TrainingURL, form);
  }

  updateHcpNeuroP1Training(id, form): Observable<any> {
    return this.http.patch<any>(hcpneurop1TrainingURL+id+"/", form);
  }

  getHcpNeuroP2TrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(hcpneurop2TrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createHcpNeuroP2Training(form): Observable<any> {
    return this.http.post<any>(hcpneurop2TrainingURL, form);
  }

  updateHcpNeuroP2Training(id, form): Observable<any> {
    return this.http.patch<any>(hcpneurop2TrainingURL+id+"/", form);
  }

  getHcpNeuroCaseTrainingbyAccount(account, hcp): Observable<any>{
    return this.http.get<any>(hcpneuroCaseTrainingURL+"?account="+account+"&hcp="+hcp)
  }

  createHcpNeuroCaseTraining(form): Observable<any> {
    return this.http.post<any>(hcpneuroCaseTrainingURL, form);
  }

  updateHcpNeuroCaseTraining(id, form): Observable<any> {
    return this.http.patch<any>(hcpneuroCaseTrainingURL+id+"/", form);
  }

}
