import { Component, OnInit } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../services/dialog.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users;
  modalRef: NgbModalRef
  action: "view" | "edit" | "add"
  modalTitle: string
  selectedUser: any
  userIndex: number;

  constructor(private modalService: NgbModal, private usersService: UserService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.usersService.getAllUsers().subscribe(data => {
      this.users = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  changeStatus(value, index){
    let dep = this.users[index]
    this.updateUser(dep.id, {is_active: value}, index)
  }

  open(modal) {
    this.modalRef = this.modalService.open(modal, {size: 'lg'})
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  add(modal){
    this.action = "add";
    this.modalTitle = "Add User"
    this.selectedUser = undefined;
    this.open(modal)
  }

  edit(modal, user, index){
    this.action = "edit"; 
    this.modalTitle = "Edit User"
    this.userIndex = index;
    this.selectedUser = user;
    this.open(modal)
  }

  save(user){
    if(user){
      if(this.action=="edit"){
        this.updateUser(this.selectedUser.id, user, this.userIndex)
      }
      else if(this.action=="add"){
        this.usersService.createUser(user).subscribe(data => {
          this.users.push(data)
          this.dialogService.alertDialog({title: "Success", message: "Successfully created user."})
        }, error => {
          this.dialogService.errorDialogNew(error)
        })
      }
      this.modalRef.dismiss()
    }
    else{
      this.modalRef.dismiss()
    }
  }

  updateUser(id, changes, index){
    this.usersService.updateUser(id, changes).subscribe(data => {
      this.users.splice(index, 1, data)
      this.dialogService.alertDialog({title: "Success", message: "Successfully updated user."})
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  


}
