<section data-ste-chapter="breadcrumbs">
	<div class="container">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;"></li>
		</ol>   
	</div>
</section>

<div class="row">
  <div class="col-xl-6 col-lg-12">
      <div class="row">
          <div class="col-xl-12">
              <div class="hero-block__headings">
                  <h1 class="hero-block__title">Accounts Status</h1>
              </div>
          </div>
      </div>
          <div *ngIf="accounts && accounts[0].status" class="alert alert-success">
              {{ accounts[0]?.status }}
          </div>
      <div class="table-responsive ">
          <table class="table-features">
              <thead>
                  <tr>
                      <th>Name</th><th>Country</th><th>Installation Date</th><th></th><th></th><th>Progress</th>
                  </tr>
              </thead>
              <tbody>
          
                  <tr *ngFor="let item of accounts">
                      <td>
                          <a (click)="getAccount(item)" class="pointer underline">{{ item.name }}</a>
                      </td>
                      <td>
                          <i>{{ item.country?.name || 'No Country' }}</i>
                      </td>
                      <td>
                          {{item.installation_date || 'No Date'}}
                      </td>
                      <td>
                          <!-- <a href="#" ><i class="table-icon icon-settings" title="Account Settings"></i></a>
                          &nbsp; -->
                          <a (click)="getAccountChecklist(item)" class="pointer"><i class="table-icon icon-text-check" title="Check List"></i></a>
                      </td>
                      <td>
                          <i *ngIf="item.critical" class="critical-icon table-icon icon-exclamationmark" title="Critical Item Incomplete"></i>
                          <!-- <i *ngIf="item.status == 'critical'" class="critical-icon table-icon icon-exclamationmark" title="Critical Item Incomplete"></i> -->
                      </td>
                      <td>
                          <div class="progress" style="height: 30px;">
                              <div [ngClass]="{'critical': item.critical, 'completed': item.progress==100}" class="progress-bar" role="progressbar" [style.width.%]="item.progress" aria-valuemin="0" aria-valuemax="100"></div>
                              <span [ngClass]="{'white': item.progress>73}" class="percentage" style="line-height: 30px;">{{item.progress | number:'1.0-0'}}%</span>
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
  <div class="col-xl-6 col-lg-12">
      <div id="corindus-calendar">
        <full-calendar [options]="calendarOptions"></full-calendar>
      </div>
  </div>
</div>