import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './helpers/token.interceptor';
import { DialogComponent } from './dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService } from './services/dialog.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './helpers/auth.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountsComponent } from './accounts/accounts.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { DepartmentsComponent } from './departments/departments.component';
import { DepartmentComponent } from './modals/department/department.component';
import { HcpRolesComponent } from './hcp-role/hcp-roles.component';
import { HcpRoleComponent } from './modals/hcp-role/hcp-role.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './modals/user/user.component';
import { AccountDepartmentDetailsComponent } from './account-department-details/account-department-details.component';
import { HcpComponent } from './modals/hcp/hcp.component';
import { AccountSiteVisitComponent } from './account-site-visit/account-site-visit.component';
import { AccountChecklistComponent } from './account-checklist/account-checklist.component';
import { AccountComponent } from './modals/account/account.component';
import { HcpTrainingComponent } from './trainings/hcp-training/hcp-training.component';
import { CaseTrainingComponent } from './trainings/case-training/case-training.component';
import { StaffTrainingComponent } from './trainings/staff-training/staff-training.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ReportsComponent } from './reports/reports.component'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { TitleCasePipe } from '@angular/common';
import { TrainingFormSelectorComponent } from './training-form-selector/training-form-selector.component';
import { Hcp2TrainingComponent } from './trainings/hcp2-training/hcp2-training.component';
import { KeyPersonTableComponent } from './key-person-table/key-person-table.component';
import { NeuroP1TrainingComponent } from './trainings/neuro-p1-training/neuro-p1-training.component';
import { NeuroP2TrainingComponent } from './trainings/neuro-p2-training/neuro-p2-training.component';
import { NeuroCaseTrainingComponent } from './trainings/neuro-case-training/neuro-case-training.component';
import { ProceduresComponent } from './procedure/procedures/procedures.component';
import { ProcedureComponent } from './procedure/procedure/procedure.component';
import { AddProcedureComponent } from './procedure/add-procedure/add-procedure.component';
import {DatePipe} from '@angular/common';

FullCalendarModule.registerPlugins([
  dayGridPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DialogComponent,
    DashboardComponent,
    AccountsComponent,
    AccountDetailsComponent,
    DepartmentsComponent,
    DepartmentComponent,
    HcpRolesComponent,
    HcpRoleComponent,
    UsersComponent,
    UserComponent,
    AccountDepartmentDetailsComponent,
    HcpComponent,
    AccountSiteVisitComponent,
    AccountChecklistComponent,
    AccountComponent,
    HcpTrainingComponent,
    CaseTrainingComponent,
    StaffTrainingComponent,
    ResetPasswordComponent,
    ReportsComponent,
    TrainingFormSelectorComponent,
    Hcp2TrainingComponent,
    KeyPersonTableComponent,
    NeuroP1TrainingComponent,
    NeuroP2TrainingComponent,
    NeuroCaseTrainingComponent,
    ProcedureComponent,
    ProceduresComponent,
    AddProcedureComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgxSpinnerModule,
    NgbModule,
    MatIconModule,
    FullCalendarModule,
    NgSelectModule,
  ],
  providers: [
    DialogService,
    AuthGuard,
    TitleCasePipe,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
