<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
</div>

<div class="modal-body">
  <form [formGroup]="hcpForm" (ngSubmit)="submit()" novalidate>

    <div class="row form-group">
      <label for="title" class="col-md-2">Title</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (title.dirty || title.touched)}">
        <input class="form-control" type="text" id="title" formControlName="title" required>
        <div *ngIf="title.invalid && (title.dirty || title.touched)" class="invalid-feedback">
          <div *ngIf="title.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label for="first_name" class="col-md-2">First Name</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (first_name.dirty || first_name.touched)}">
        <input class="form-control" type="text" id="first_name" formControlName="first_name" required>
        <div *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)" class="invalid-feedback">
          <div *ngIf="first_name.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
      <label for="last_name" class="col-md-2">Last Name</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (last_name.dirty || last_name.touched)}">
        <input class="form-control" type="text" id="last_name" formControlName="last_name" required>
        <div *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)" class="invalid-feedback">
          <div *ngIf="last_name.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label for="email" class="col-md-2">Email</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (email.dirty || email.touched)}">
        <input class="form-control" type="email" id="email" formControlName="email" required>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
          <div *ngIf="email.errors.required">
            <strong>Fix this</strong>
          </div>
          <div *ngIf="email.errors.email">
            <strong>Invalid email</strong>
          </div>
        </div>
      </div>
      <label for="mobile" class="col-md-2">Mobile</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (mobile.dirty || mobile.touched)}">
        <input class="form-control" type="text" id="mobile" formControlName="mobile">
      </div>
    </div>

    <div class="row form-group">
      <label for="role" class="col-md-2">HCP Role</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (role.dirty || role.touched)}">
        <select class="form-control" formControlName="role" id="role" required>
          <option value="">Select an option</option>
          <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
        </select>
        <div *ngIf="role.invalid && (role.dirty || role.touched)" class="invalid-feedback">
          <div *ngIf="role.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
      <label for="department" class="col-md-2">Department</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (department.dirty || department.touched)}">
        <select class="form-control" formControlName="department" id="department" required >
          <option value="">Select an option</option>
          <option *ngFor="let d of departments" [ngValue]="d.id">{{d.name}}</option>
        </select>
        <div *ngIf="department.invalid && (department.dirty || department.touched)" class="invalid-feedback">
          <div *ngIf="department.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label for="account" class="col-md-2">Account</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (account.dirty || account.touched)}">
        <select class="form-control" formControlName="account" id="account" required>
          <option value="">Select an option</option>
          <option *ngFor="let account of accounts" [value]="account.id">{{account.name}}</option>
        </select>
        <div *ngIf="account.invalid && (account.dirty || account.touched)" class="invalid-feedback">
          <div *ngIf="account.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
      <label for="path" class="col-md-2">Traning Path</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (path.dirty || path.touched)}">
        <select class="form-control" formControlName="path" id="path">
          <option *ngFor="let path of availablePath" [value]="path.slug">{{path.name}}</option>
        </select>
        <div class="feedback">
            <strong style="font-size:11px;font-style: italic;">You shouldnt change the training path after choosing one.</strong>            
        </div>
      </div>
    </div>

    <div class="row form-group no-gutters">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="is_hmt" id="is_hmt" checked>
        <label class="form-check-label" for="is_hmt">
          Is this person also a part of Hospital Management Team (HMT)?
        </label>
      </div>
    </div>

    <input class="form-control" name="status" type="hidden" id="status" value="Active" >

    <div class="form-group">
        <div class="offset-md-4 col-md-4">
            <button type="submit" class="button button--primary display-block" [disabled]="!hcpForm.valid" >Save</button>
        </div>
    </div>
  </form>
</div>