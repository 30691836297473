import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '../services/dialog.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  queryParams;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private router: Router, private dialogService: DialogService, private usersService: UserService) { }

  ngOnInit(): void {
    this.queryParams = this.route.snapshot.queryParams
    if(this.queryParams.reset_token){
      this.usersService.validateToken(this.queryParams.reset_token).subscribe(data => {
        console.log(data)
      }, error => {
        this.dialogService.errorDialog({
          title: "Page 404",
          message: "The link is expired or invalid. Taking you back to Login page now..."
        })
        setTimeout(() => {
          this.router.navigate(['login'])
        }, 5000);
      })
    }
    else{
      setTimeout(() => {
        this.dialogService.errorDialog({
          title: "Page 404",
          message: "The link is expired or invalid. Taking you back to Login page now..."
        })
        setTimeout(() => {
          this.router.navigate(['login'])
        }, 5000);
      })    
    }

    this.resetForm.get('password').valueChanges.subscribe(data => {
      this.matchPasswords()
    })
    this.resetForm.get('password2').valueChanges.subscribe(data => {
      this.matchPasswords()
    })
  }

  resetForm = this.fb.group({
    password: [''],
    password2: ['']
  })

  get password() { return this.resetForm.get('password'); }
  get password2() { return this.resetForm.get('password2'); }


  passwordsMatch: boolean = false
  matchPasswords(){
    let p = this.password.value
    let p2 = this.password2.value
    if(p==p2){
      this.passwordsMatch = true      
    }
    else{
      if(p2.length>7){
        this.passwordsMatch = false
        this.resetForm.get('password2').setErrors({notMatch: true})
        this.resetForm.updateValueAndValidity()
      }
    }
  }

  resetPassword(){
    if(!this.resetForm.errors){
      this.usersService.resetPassword(this.password.value, this.queryParams.reset_token).subscribe(data => {
        console.log(data)
        this.dialogService.alertDialogWithReturn({
          title: "Success",
          message: "Password has been changed successfully."
        }).then(d => {
          this.router.navigate(['login'])
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
  }
}
