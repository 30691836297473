<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span property="name">Procedures</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row">
  <div class="col-md-12">
    <div class="hero-block__headings">
      <a routerLink="/add-procedure" class="hd-icon icon icon--primary add-new pointer" title="Add New Procedure">
        <i class="icon-plus"></i>
      </a>
      <h1 class="aw-h1 hero-block__title">Procedure</h1>
    </div>
  </div>
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-features">
        <thead>
          <tr>
            <th>Procedures Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let procedure of procedures">
            <td><a (click)="getProcedureDetails(procedure)" class="pointer underline">{{ procedure.procedure_number }}</a></td>
          </tr>
        </tbody>

      </table>
    </div>
  </div>
</div>
