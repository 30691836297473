import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { LoaderService } from '../services/loader.service'

@Injectable({providedIn: 'root'})
export class TokenInterceptor implements HttpInterceptor {
  
  private requests: HttpRequest<any>[] = [];
  constructor(private authenticationService: AuthenticationService, private loaderService: LoaderService) {}


  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
        this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available and loader on every request
  
    
    this.loaderService.isLoading.next(true);

    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: { 
          'Authorization': 'Token ' + currentUser.token
        }
      });
    }

    this.requests.push(request);
    console.log("No of requests--->" + this.requests.length);
    
    return Observable.create(observer => {
      const subscription = next.handle(request)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(request);
              observer.next(event);
            }
          },
          err => {
            // alert('error returned');
            this.removeRequest(request);
            observer.error(err);
          },
          () => {
            this.removeRequest(request);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(request);
        subscription.unsubscribe();
      };
    });
  }
}