import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    queryParams;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.queryParams = route.queryParams
        let routerUrl = this.router.url // url of current component
        let stateUrl = state.url // url of next/redirected component.
        const currentUser = this.authenticationService.currentUserValue;
        
        // if(stateUrl.slice(0,12) == '/pay-invoice')
        //     return true

        if(currentUser){
          if(stateUrl == "/login"){
            this.router.navigate(['/dashboard'])
            return false; 
          }
          else
            return true
        }
        // if (currentUser && currentUser.user.is_owner) {
        //     if(stateUrl == "/login" || stateUrl == "/registration"){
        //         this.router.navigate(['/businesses'])
        //         return false; 
        //     }
        //     if(currentUser.selectedBusiness){
        //         if(stateUrl == "/businesses"){
        //             return true;
        //         }
        //         else if(!currentUser.selectedBusiness.is_paid)
        //             return false;
        //         else{
        //             if(currentUser.selectedBusiness.is_setup)
        //                 return true;
        //             else if(!currentUser.selectedBusiness.is_setup && routerUrl=="/setup-business")
        //                 return true;
        //             else
        //                 return false;
        //         }
        //     }
        //     else{
        //         if(stateUrl == "/businesses")
        //             return true;
        //         else{
        //             this.router.navigate(['/businesses'])
        //             return false; 
        //         }
        //     }
        // }

        // else if(currentUser && currentUser.user.is_customer){
        //     if(
        //         stateUrl == '/customer-dashboard' ||
        //         stateUrl == '/customer-properties' ||
        //         stateUrl == '/customer-jobs' ||
        //         stateUrl == '/customer-refer-friend' ||
        //         stateUrl == '/customer-job-visits' || 
        //         stateUrl == '/customer-wallet'
        //     )
        //         return true
        //     else{
        //         this.router.navigate(['/customer-dashboard'])
        //         return false
        //     }
        // }

        else if(
            stateUrl == '/login' || 
            stateUrl.slice(0,15) == '/reset-password'
          )
            return true
        
            // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: this.queryParams? this.queryParams: { returnUrl: state.url }});
        return false;
        // this.router.navigate(['/businesses'])
    }
}