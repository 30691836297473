<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span property="name">Reports</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row">
  <div class="col-md-12">
    <div class="hero-block__headings">
      <h1 class="aw-h1 hero-block__title">Search</h1>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 account-search">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="search-btn">
          <button (click)="search()" id="main-search" class="button-primary my-1"><i class="icon-search"></i></button>
        </div>
      </div>  
      <ng-select [items]="types" 
        bindLabel="name" 
        bindValue="id"
        placeholder="Report Type"
        [(ngModel)]="selectedType"
        class="form-control"
        style="padding-top: 0.125rem;">
      </ng-select>        
      <ng-select [items]="accounts" 
        bindLabel="name" 
        bindValue="id"
        multiple="true" 
        placeholder="Accounts"
        [(ngModel)]="selectedAccounts"
        class="form-control"
        style="padding-top: 0.125rem;">
      </ng-select>
      <input class="form-control" type="date" [(ngModel)]="fromDate" required>
      <input class="form-control" type="date" [(ngModel)]="toDate" required>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <h3>Results: <span id="res">{{trainingResults?.length || angioResults?.length || 0}}</span></h3>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-features table-search table-long">
        <thead>
          <tr *ngIf="trainingResults?.length">
            <th>Account Name</th>
            <th>HCP name</th>
            <th>HCP type</th>
            <th>Training</th>
            <th class="sort desc" (click)="_reorder()">
              Date Completed
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="401.998px" height="401.998px" viewBox="0 0 401.998 401.998" style="enable-background:new 0 0 401.998 401.998;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M73.092,164.452h255.813c4.949,0,9.233-1.807,12.848-5.424c3.613-3.616,5.427-7.898,5.427-12.847
                            c0-4.949-1.813-9.229-5.427-12.85L213.846,5.424C210.232,1.812,205.951,0,200.999,0s-9.233,1.812-12.85,5.424L60.242,133.331
                            c-3.617,3.617-5.424,7.901-5.424,12.85c0,4.948,1.807,9.231,5.424,12.847C63.863,162.645,68.144,164.452,73.092,164.452z"></path>
                        <path d="M328.905,237.549H73.092c-4.952,0-9.233,1.808-12.85,5.421c-3.617,3.617-5.424,7.898-5.424,12.847
                            c0,4.949,1.807,9.233,5.424,12.848L188.149,396.57c3.621,3.617,7.902,5.428,12.85,5.428s9.233-1.811,12.847-5.428l127.907-127.906
                            c3.613-3.614,5.427-7.898,5.427-12.848c0-4.948-1.813-9.229-5.427-12.847C338.139,239.353,333.854,237.549,328.905,237.549z"></path>
                    </g>
                </g></svg>
            </th>
          </tr>
          <tr *ngIf="angioResults?.length">
            <th>Angio Type</th>
            <th>Number of Installations</th>
          </tr>          
          <!-- <tr>
            <td><input id="res-acc" placeholder="Account Search" ></td>
            <td><input id="res-hcpname" placeholder="HCP Name Search" ></td>
            <td><input id="res-hcptype" placeholder="HCP Type Search" ></td>
            <td></td>
          </tr> -->
        </thead>
        <tbody id="res-container">
          <tr class="data-row" *ngFor="let item of trainingResults" style="cursor: pointer;">
            <td>{{item.account__name}}</td>
            <td>{{item.hcp__first_name}} {{item.hcp__last_name}}</td>
            <td>{{item.hcp__role__name}}</td>
            <td>{{item.pathway}}</td>
            <td>{{item.updated_at | date:'dd-MM-yyyy'}}</td>
          </tr>
          <ng-container class="data-row" *ngFor="let item of angioResults">
            <tr (click)="item.show=!item.show">
              <td>{{item.name}}</td>
              <td>{{item.value.length}}</td>            
            </tr>
            <tr *ngIf="item.show">
              <td colspan="12">              
                <table class="table" style="background-color: #cacaca;">
                  <thead>
                    <tr>
                      <th>Account Name</th>
                      <th>Region</th>
                      <th>Country</th>
                      <th>Installation Date</th>
                      <th>Delivery Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let i of item.value">
                      <td>{{i.name}}</td>
                      <td>{{i.region__name}}</td>
                      <td>{{i.country__name}}</td>
                      <td>{{i.installation_date}}</td>
                      <td>{{i.delivery_date}}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <!-- <tr>
              <td colspan="12" class="hiddenRow">
                <div class="accordian-body collapse" id="demo1"> 
                  <table class="table table-striped">
                    <thead>
                      <tr class="info">
                        <th>Job</th>
                        <th>Company</th>
                      </tr>
                    </thead>	
                    
                    <tbody>												
                      <tr>
                        <td>Google</td>
                        <td>U$8.00000 </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr> -->
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
