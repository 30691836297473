<nav *ngIf="!isWelcomePage" class="navbar navbar-default fixed-top justify-content-center navbar-expand-md" style="height: 120px; background-color: white; border-bottom: 5px solid #ec6602;">
  <div class="header-wrapper">
    <div  class="header">
      <a href="#" class="home-logo navbar-brand">CORINDUS</a>
      <div class="collapse navbar-collapse navHeaderCollapse" style="height: 120px;">
        <ul class="nav navbar-nav navbar-right top-bar justify-content-end ml-auto" *ngIf="!isLoggedIn">
          <li *ngIf="!isLoggedIn"><a href='#' routerLink="/login">Login</a></li>
        </ul>
        <ul class="nav navbar-nav navbar-right top-bar justify-content-end ml-auto" *ngIf="isLoggedIn">          
          <li><a class="nav-link">Logged in as: <span style="color: #cf4b00;"> {{ currentUser.id }} </span></a></li>
          <li><a class="nav-link" routerLink="/dashboard">Dashboard</a></li>
          <li><a class="nav-link" routerLink="/reports">Reports</a></li>

          <li #menuDropdown ngbDropdown class="nav-item">
            <a ngbDropdownToggle class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Menu
            </a>
            <ul ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="navbarDropdown">
              <li *ngFor="let item of menuItems" class="pointer" ngbDropdownItem routerLink="{{item.link}}">{{item.name}}</li>
            </ul>
          </li>          
          <li><a class="nav-link" style="cursor: pointer;" (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>


<div *ngIf="isWelcomePage" class="main-wrapper">
  <div class="flex-center position-ref full-height">
    <div class="top-right links">
      <a *ngIf="isLoggedIn" href="#" routerLink="/dashboard">Home</a>
      <a *ngIf="!isLoggedIn" href="#" routerLink="/login">Login</a>
    </div>
    <div class="content">
      <div class="title m-b-md">Corindus Tool</div>
      <div class="links">
        <a href="https://laravel.com/docs">Documentation</a>
        <a href="https://laracasts.com">Laracasts</a>
        <a href="https://laravel-news.com">News</a>
        <a href="https://forge.laravel.com">Forge</a>
        <a href="https://github.com/laravel/laravel">GitHub</a>
      </div>
    </div>
  </div>     
</div>

<ngx-spinner><p style="color: white" >Loading...</p></ngx-spinner>

<main role="main" style="margin-top: 120px;" class="m-2rem">
  <!-- <section class="mt-1 mb-1" data-ste-chapter="breadcrumbs">
    <div class="px-2rem">
      <ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
        <li class="breadcrumbs__item" property="itemListElement" typeof="ListItem"><span property="name">WELCOME TO THE CAM</span></li>
      </ol>   
    </div>
  </section> -->
  <router-outlet></router-outlet>
</main>