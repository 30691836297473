import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { siteVisitURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SiteVisitService {

  constructor(private http: HttpClient) { }

  getSiteVisitbyAccount(account): Observable<any>{
    return this.http.get<any>(siteVisitURL+"?account="+account)
  }

  updateSiteVisit(id, siteVisit): Observable<any> {
    return this.http.patch<any>(siteVisitURL+id+"/", siteVisit);
  }

}
