import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TrainingFormSelectorComponent } from '../training-form-selector/training-form-selector.component';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';
import { HcpService } from '../services/hcp.service';


@Component({
  selector: 'app-account-department-details',
  templateUrl: './account-department-details.component.html',
  styleUrls: ['./account-department-details.component.scss']
})
export class AccountDepartmentDetailsComponent implements OnInit {

  hcps: any[]
  department: any
  account: any

  constructor(private hcpService: HcpService, private modalService: NgbModal, private dialogService: DialogService, private router: Router, private dsService: DataSharingService) { }

  ngOnInit(): void {
    let savedData = this.dsService.currentDataValue
    if(!savedData.department || !savedData.account){
      this.router.navigate(['/accounts'])
    }
    else{
      this.department = savedData.department
      this.account = savedData.account
      this.getHcps()
    }
  }

  getHcps(){
    this.hcpService.getAllHcpsbyDepartmentAndAccount(this.department.id, this.account.id).subscribe(data => {
      this.hcps = data.results
      // console.log( this.hcps );
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  changeStatus(value, index){
    console.log(value, index)
    this.hcps[index].is_active = value
  }

  sendEmail(hcp){
    if(hcp){
      this.hcpService.sendConsentEmail(hcp.id).subscribe(() => {
        hcp.email_log.push({email_timestamp: new Date().toISOString()})
        // console.log(this.hcps)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Email has been sent again successfully."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
  }

  hcpTraining(hcp){
    let data = this.dsService.currentDataValue
    data.hcp = hcp;
    this.dsService.storeData(data)
    this.router.navigate(['/hcp-training'])
  }

  caseTraining(hcp){
    let data = this.dsService.currentDataValue
    data.hcp = hcp;
    this.dsService.storeData(data)
    this.router.navigate(['/case-training'])
  }

  staffTraining(hcp){
    let data = this.dsService.currentDataValue
    data.hcp = hcp;
    this.dsService.storeData(data)
    this.router.navigate(['/staff-training'])
  }


  // Add New HCP

  modalRef: NgbModalRef
  modalTitle: string
  action: "view" | "edit" | "add"
  selectedHcp: any
  hcpIndex: any

  openHcpModal(modal){
    this.modalRef = this.modalService.open(modal, {size: "lg"})
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  addHcp(modal){
    this.action = "add";
    this.modalTitle = "Add Hcp"
    this.selectedHcp = undefined;
    this.openHcpModal(modal)
  }

  editHcp(modal, hcp, index){
    this.action = "edit";
    this.modalTitle = "Edit Hcp"
    this.hcpIndex = index;
    this.selectedHcp = hcp;
    this.openHcpModal(modal)
  }

  viewHcp(modal, hcp){
    this.action = "view";
    this.modalTitle = "View Hcp"
    this.selectedHcp = hcp;
    this.openHcpModal(modal)
  }

  saveHcp(hcp){    
    if(hcp && this.action=="add"){
      this.hcpService.createHcp(hcp).subscribe(data => {
        data.role = hcp.roleObj
        this.hcps.push(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Successfully created new HCP"
        })
        this.modalRef.dismiss()
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } 
    if(hcp && this.action=="edit"){
      this.hcpService.updateHcp(this.selectedHcp.id, hcp).subscribe(data => {        
        data.role = hcp.roleObj
        this.hcps[this.hcpIndex] = data
        this.dialogService.alertDialog({
          title: "Success",
          message: "Successfully updated HCP details"
        })
        this.modalRef.dismiss()
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.modalRef.dismiss()
    }
  }


  openEmailLogsModal(hcp, modal){
    this.selectedHcp = hcp
    console.log(this.selectedHcp.email_log)
    this.modalRef = this.modalService.open(modal)
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  closeEmailLogsModal(){
    this.selectedHcp = undefined
    this.modalRef.close
  }

}
