<section class="mt-1 mb-3" data-ste-chapter="breadcrumbs">
	<div class="container">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;"></li>
		</ol>   
	</div>
</section>

<div id="login-container">
    <div class="login-form">
        <div class="row">
            <div class="col-md-12">
                <div class="hero-block__headings">
                    <h1 class="hero-block__title">WELCOME TO CAM</h1>
                </div>
            </div>
        </div>
        <!-- <div class="row"> -->
            <form class="form-horizontal row" [formGroup]="loginForm">
                <!-- {{ csrf_field() }} -->
                <div class="col-md-12">
                    <div [ngClass]="{'was-validated': (email.dirty || email.touched)}">
                        <!-- <div> -->
                            <!-- <div> -->
                                <!-- <span> -->
                                    <div class="textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': email.value}">
                                        <div class="textfield__box">
                                            <input formControlName="email" type="email" name="email" class="textfield__input" required>
                                            <label class="textfield__label" for="email">E-MAIL ADDRESS</label>
                                            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                                                <div *ngIf="email.errors.required">
                                                    <span class="help-block">
                                                        <strong>Fix this</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>                                        
                                    </div>
                                <!-- </span> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>

                <div class="col-md-12">
                    <div [ngClass]="{'was-validated': (password.dirty || password.touched)}">
                        <!-- <div> -->
                            <!-- <div> -->
                                <!-- <span> -->
                                    <div class="textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': password.value}">
                                        <div class="textfield__box">
                                            <input formControlName="password" type="password" name="password" class="textfield__input" required>
                                            <label class="textfield__label" for="password">PASSWORD</label>
                                            <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                                                <div *ngIf="password.errors.required">
                                                    <span class="help-block">
                                                        <strong>Fix This</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>                                        
                                    </div>
                                <!-- </span> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>

                <div class="col-md-12">
                    <div >
                        <div>
                            <div>
                                <span>
                                    <div class="textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': code.value}">
                                        <div class="textfield__box">
                                            <!-- <button style="float:right;" (click)="sendAuthCode()" class="btn btn-primary pointer">Get Code</button>   -->
                                            <input type="text" id="code" style="width: 80%" name="code" formControlName="code" class="textfield__input">
                                            <label class="textfield__label" for="password">CODE</label>
                                            <button style="float:right;" (click)="sendAuthCode()" class="btn btn-outline-secondary pointer">Get Code</button>
                                        </div>
                                        <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                                                <!-- <span id="code-mess">
                                                </span> -->
                                        <!-- <button style="float:right;" (click)="sendAuthCode()" class="btn btn+-primary pointer">Get Code</button> -->
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <a class="forgot-password pointer" (click)="openModal(forgotModal)">
                        Forgot Your Password?
                    </a>
                </div>


                <div class="col-md-12">
                    <p>
                    <input type="checkbox" id="remember-me" name="remember" class="checkbox">
                    <label for="remember-me">
                        <span class="wysiwyg">Remember me</span>
                    </label>
                    </p>
                </div>


                <div class="col-md-12">
                    <button type="button" (click)="login()" class="button button--primary display-block">
                        Login
                    </button>
                </div>
            </form>
        <!-- </div> -->
    </div>
</div>

<ng-template #forgotModal let-modal>
  <div class="modal-header">
		<h4 class="modal-title">Forgot Password?</h4>
		<button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
	</div>

	<div class="modal-body">
		<div class="form-group row">
			<label for="name" class="col-md-4">E-MAIL ADDRESS</label>
			<div class="col-md-8">
        <input type="email" [(ngModel)]="forgotEmail" class="form-control">
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md flex-reverse">
				<button type="submit" (click)="sendLink()" class="button button--primary display-block" [disabled]="!forgotEmail" >Send Password Reset Link</button>
			</div>
			</div>
	</div>
</ng-template>