import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AccountsService } from '../services/accounts.service';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';

export interface TableData {
  title: string;
  name: string;
  contact_detail: string;
}

@Component({
  selector: 'app-key-person-table',
  templateUrl: './key-person-table.component.html',
  styleUrls: ['./key-person-table.component.scss']
})
export class KeyPersonTableComponent implements OnInit {

  @Input() persons;
  @Input() type;

  accountId: any;
  storedData: any;
  account: any;
  
  // data: TableData[];
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  displayColumns = ['Title', 'Name', 'Contact Detail'];
  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'persons': this.rows });

  constructor(private fb: FormBuilder,private accountService: AccountsService, private dialogService: DialogService,private dsService: DataSharingService) { }

  ngOnInit(): void {
    this.storedData = this.dsService.currentDataValue
    this.accountId = this.storedData.account.id

    if( this.persons.length > 0 ) {
      this.persons.forEach((d: TableData) => this.addRow(d, false));
    }
    this.updateView();
  }

  saveKeyPerson(){
      this.accountService.updateAccount(this.accountId, {key_person: this.rows.value}).subscribe(data => {
        this.account = data;
        this.dialogService.alertDialog({
          title: "Success",
          message: "Key person updated successfully."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })

  }

  updateView() {
    this.dataSource.next(this.rows.controls);
  }

  addRow(d?: TableData, noUpdate?: boolean) {
    const row = this.fb.group({
      'title'   : [d && d.title ? d.title : null, []],
      'name'     : [d && d.name   ? d.name   : null, []],
      'contact_detail'   : [d && d.contact_detail ? d.contact_detail : null, []],
    });
    this.rows.push(row);
    if (!noUpdate) { this.updateView(); }
  }

  removeRow() {
    let last = this.rows.length - 1
    this.rows.removeAt(last)
  }


  accountAddRow(d?: TableData, noUpdate?: boolean) {
    const row = this.fb.group({
      'title'   : [d && d.title ? d.title : null, []],
      'name'     : [d && d.name   ? d.name   : null, []],
      'contact_detail'   : [d && d.contact_detail ? d.contact_detail : null, []],
    });
    this.rows.push(row);
    if (!noUpdate) { this.updateView(); }
  }

  accountRemoveRow() {
    let last = this.rows.length - 1
    this.rows.removeAt(last)
  }

}
