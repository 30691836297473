import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private router: Router, private http: HttpClient, private userService: UserService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('corindusCurrentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  updateUserValue(data) {
    localStorage.setItem('corindusCurrentUser', JSON.stringify(data));
    this.currentUserSubject.next(data);
  }

  removeUser(){
    localStorage.removeItem('corindusCurrentUser');
    this.currentUserSubject.next(null);
  }

  loginUser(userCredentials) {
    return this.userService.loginUser(userCredentials).pipe(map(user => {
      localStorage.setItem('corindusCurrentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
      return user;
    }))
  }

  storeUserDetails(user){
    localStorage.setItem('corindusCurrentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  logout() {
    this.userService.logoutUser().subscribe(user => {
      localStorage.removeItem('corindusCurrentUser');
      this.currentUserSubject.next(null);
      this.router.navigate(['/login'])
    }, error => {
      localStorage.removeItem('corindusCurrentUser');
      this.currentUserSubject.next(null);
      console.log(error)
      this.router.navigate(['/login'])
    })
  }
}
