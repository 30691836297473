<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
		<span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-department-details">Department Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					HCP Training Form ( technIQ )
				</span>
	  </li>
		</ol>   
	</div>
</section>

<div class="form-container hcp-training-form">
  <div>
	  <div class="section">
			<div class="row">
				<div class="col-md-8">
					<div class="hero-block__headings">
						<h1 class="hero-block__title">CORPATH GRX VASCULAR ROBOTIC SYSTEM</h1>
					</div>
				</div>
				<div class="col-md-4 flex-reverse">
				<button class="button display-block btn-sm" routerLink="/account-department-details">Back</button>
				</div>
			</div>

			<div class="heading">
				<h2>Physician Training Pathway: Phase I (TechnIQ)</h2>
			</div>

			<div class="row">
				<div class="col-md-6">
					<label>This form will record your name, please fill your name</label>
					<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.your_name}">
						
						<div class="textfield__box">
							<input type="text" name="overview[your_name]" class="textfield__input" [(ngModel)]="form?.overview.your_name">
							<label class="textfield__label" for="overview[your_name]">Your Name:</label>
						</div>
						<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>
				</div>
			</div>
		  
		  
			<div class="heading">
				<h3>Phase I Training Pathway: Overview</h3>
			</div>

			<div class="intro">
				<p>The following document outlines activities required for physicians to complete Phase I training on the CorPath GRX Vascular Robotic System.</p>
			</div>

		  
			<div class="row">
			  <div class="col-md-6">
					<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.physician_name}">
						<div class="textfield__box">
							<input type="text" name="overview[physician_name]" class="textfield__input" [(ngModel)]="form?.overview.physician_name">
							<label class="textfield__label" for="overview[physician_name]">Physician Name:</label>
						</div>
						<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>
			  </div>
			  <div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.hospital}">
					<div class="textfield__box">
						<input type="text" id="overview[hospital]" name="overview[hospital]" class="textfield__input" [(ngModel)]="form?.overview.hospital">
						<label class="textfield__label" for="overview[hospital]">Hospital:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		  </div>

		  <div class="row">
			<div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.cellphone}">
					<div class="textfield__box">
						<input type="text" name="overview[cellphone]" class="textfield__input" [(ngModel)]="form?.overview.cellphone">
						<label class="textfield__label" for="overview[cellphone]">Cell Phone #:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
			<div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.office}">
					<div class="textfield__box">
						<input type="text" id="overview[office]" name="overview[office]" class="textfield__input" [(ngModel)]="form?.overview.office">
						<label class="textfield__label" for="overview[office]">Office #:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		</div>

		  <div class="row">
			<div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.email}">
					<div class="textfield__box">
						<input type="text" name="overview[email]" class="textfield__input" [(ngModel)]="form?.overview.email">
						<label class="textfield__label" for="overview[email]">Email Address:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>

			<div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.current_volume}">
					<div class="textfield__box">
						<input type="text" name="overview[current_volume]" class="textfield__input" [(ngModel)]="form?.overview.current_volume">
						<label class="textfield__label" for="overview[current_volume]">Current Volume/WeeK:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		</div>

		  <div class="">
			<table class="table table-features">
				<tr>
				<td>
					<p><strong>PROGRAM GOALS</strong></p>
					<p>Procedures:</p>
					<p>Curent Volume/Week:</p>
					<p>CorPath Procedure Goals/Week:</p>
				</td>
				<td>
					<div class="textarea w-100" [ngClass]="{'is-active': form?.overview.program_goals}">
						<div class="textarea__container">
							<textarea name="overview[program_goals]" class="textarea__input" row="4" [(ngModel)]="form?.overview['program_goals']"></textarea>
						</div>
						<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>  
				</td>
				</tr>
			</table>
		  </div>

		  <div class="">
			  <table class="table table-features">
				  <tr>
					  <td rowspan="4"><p>Procedure timeline</p></td>
					  <td></td>
					  <td><p>Projected Date</p></td>
				  </tr>

				  <tr>
					<td><p>1st CorPath Procedure</p></td>
					<td>
						<div>
							<div>
								<span>
									<div class="aw-textfield textfield w-100 textfield-with-prefix">
										<div class="textfield__box">
											<input type="text" id="overview[procedure_timeline_1]" name="overview[procedure_timeline_1]" class="textfield__input" [(ngModel)]="form?.overview.procedure_timeline_1">
										</div>
										<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
									</div>
								</span>
							</div>      
						</div>
					</td>
				</tr>

				<tr>
					<td><p>10th CorPath Procedure</p></td>
					<td>
						<div>
							<div>
								<span>
									<div class="aw-textfield textfield w-100 textfield-with-prefix">
										<div class="textfield__box">
											<input type="text" id="overview[procedure_timeline_10]" name="overview[procedure_timeline_10]" class="textfield__input" [(ngModel)]="form?.overview.procedure_timeline_10">
										</div>
										<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
									</div>
								</span>
							</div>
						</div>
					</td>
				</tr>

				<tr>
					<td><p>20th CorPath Procedure</p></td>
					<td>
						<div>
							<div>
								<span>
									<div class="aw-textfield textfield w-100 textfield-with-prefix">
										<div class="textfield__box">
											<input type="text" id="overview[procedure_timeline_20]" name="overview[procedure_timeline_20]" class="textfield__input" [(ngModel)]="form?.overview.procedure_timeline_20">
										</div>
										<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
									</div>
								</span>
							</div>
						</div>
					</td>
				</tr>
				  
			  </table>
		  </div>

	  </div>


	  <div class="section">
		  <div class="s-heading">
			  <h2>Phase I Training Pathway Summary</h2>
		  </div>
		  <div class="entry-content">
			  <p>Corindus will administer a training and education program developed for the CorPath GRX Vascular Robotic System.</p>
			  <p>Prior to using the System, physicians must attend and satisfactorily complete the following Phase 1 training elements</p>
			  <ul>
				  <li>Product overview</li>
				  <li>System training</li>
				  <li>Skills practice</li>
			  </ul>
			  <p>Physicians will be issued a certificate of completion of Basic CorPath Physician Training upon successful completion of Phase I. Corindus will only support procedural use of the CorPath GRX System after a physician completes the Phase I training program.</p>
			  <p>A checklist will be ultilized by Corindus clinical staff to ensure proficiency. The competency assessment will be maintained at Corindus. Repetition of the above tasks may be necessary until the physician user shows competence with all requirements. Non-physician staff will be encouraged to participate in the training sessions with physicians.</p>
			 
			<div class="form-group">
				<label>Credentialing</label>
				<div>
					<div>
						<div class="textarea w-100" [ngClass]="{'is-active': form?.detail.hospital_credentialing_requirements}">
							<div class="textarea__container">
								<textarea name="detail[hospital_credentialing_requirements]" class="textarea__input" row="2" [(ngModel)]="form?.detail['hospital_credentialing_requirements']"></textarea>
								<label class="textarea__label" for="detail[hospital_credentialing_requirements]">Hospital credentialing requirements (if any):</label>
							</div>
							<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
			<label>Procedure Scheduling</label>
				<div>
					<div>
						<div class="textarea w-100 " [ngClass]="{'is-active': form?.detail.days_of_procedures}">
							<div class="textarea__container">
								<textarea id="detail[days_of_procedures]" name="detail[days_of_procedures]" class="textarea__input" row="2" [(ngModel)]="form?.detail['days_of_procedures']">form.detail['days_of_procedures']</textarea>
								<label class="textarea__label" for="detail[days_of_procedures]">Days of procedures (Interventionalist scheduled time in CorPath room)</label>
							</div>
							<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div>
					<div>
						<div class="textarea w-100" [ngClass]="{'is-active': form?.detail.constraints}">
							<div class="textarea__container">
								<textarea id="detail[constraints]" name="detail[constraints]" class="textarea__input" row="2" [(ngModel)]="form?.detail['constraints']"></textarea>
								<label class="textarea__label" for="detail[constraints]">Constraints with block time in the Cath Lab that will need to be addressed (staffing, case mix, etc.)</label>
							</div>
							<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div>
					<div>
						<div class="textarea w-100" [ngClass]="{'is-active': form?.detail.other_notes}">
							<div class="textarea__container">
								<textarea id="detail[other_notes]" name="detail[other_notes]" class="textarea__input" row="2" [(ngModel)]="form?.detail['other_notes']"></textarea>
								<label class="textarea__label" for="detail[other_notes]">Other notes:</label>
							</div>
							<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
						</div>
					</div>
				</div>
			</div>
	  </div>

	  <div class="section">
			<div class="subheading">
				<h3>PHASE I - BASIC CORPATH TRAINING (technIQ)</h3>
			</div>

			<div class="form-group">
				<label>Observation & Performance</label>
			</div>
			  
		  <table class="table table-features">
			  <tr class="heading">
				  <td>Task</td>
				  <td>Activity</td>
				  <td>Method</td>
			  </tr>
			  <tr>
				  <td>CorPath GRX System Overview</td>
				  <td>
					<p>Review:</p>
					<ul>
						<li>System components including Indications for Use</li>
						<li>Extended Reach Arm</li>
						<li>Robotic Drive</li>
						<li>Cockpit,Console</li>
						<li>Single Use Cassette</li>
						<li>icons</li>
					</ul>
				  <td>
					  <label>Observe:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.system_overview" class="form-control">
				  </td>
			  </tr>
			  <tr>
				  <td>Site specific connections</td>
				  <td>
					<p>Review:</p>
					<ul>
						<li>Power connection</li>
						<li>Video connection</li>
						<li>Communication cable connection</li>
					</ul>
					</td>
				  <td>
					  <label>Observe:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.specific_connections" class="form-control">
				  </td>
			  </tr>
			  <tr>
				  <td>System power up</td>
				  <td>
					<p>Review:</p>
					<ul>
						<li>Power Up button location and use</li>
					</ul>
				</td>
				  <td>
					  <label>Observe:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.system_power_up" class="form-control">
				  </td>
			  </tr>
			  <tr>
				  <td>Cockpit overview</td>
				  <td>
					<p>Review:</p>
					<ul>
						<li>Control Console</li>
						<li>Monitor</li>
						<li>Room Camera</li>
					</ul>
					</td>
				  <td>
					  <label>Observe:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.cockpit_overview" class="form-control">
				  </td>
			  </tr>
			  <tr>
				  <td>Control Console Operation</td>
				  <td>
					  <p>Review Control Console and touchscreen functions, including:</p>
					  <ul>
						  <li>Individual Joystick control and disable function</li>
						  <li>Simultaneous device movement</li>
						  <li>Turbo Speed movement</li>
						  <li>E-stop button, location and use in menu</li>
					  </ul>
				  </td>
				  <td>
					  <label>Observe & Perform:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.cco" class="form-control">
				  </td>
			  </tr>
			  <tr>
				  <td>Control Console Operation - Guide Catheter</td>
				  <td>
					  <p>Review:</p>
					  <ul>
						  <li>Guide Catheter Joystick Rotational and Linear Travel indicators</li>
						  <li>Guide Catheter Joystick operation and disable function</li>
					  </ul>
				  </td>
				  <td>
					  <label>Observe & Perform:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.cco_catheter" class="form-control">
					  <!-- <input type="checkbox" name="tasks[cco_catheter]" value="true" <?php if(isset(form->tasks['cco_catheter'])): ?> <?php if(form->tasks['cco_catheter']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
				  </td>
			  </tr>
			  <tr>
				  <td>Control Console Operation - Guide Wire</td>
				  <td>
					  <p>Review:</p>
					  <ul>
						  <li>Guidewire Joystick Rotational, Speed and Direction indicators</li>
						  <li>Guidewire Speed and Direction Indicators</li>
						  <li>Guidewire discrete  rotation</li>
					  </ul>
				  </td>
				  <td>
					  <label>Observe & Perform:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.cco_wire" class="form-control">
					  <!-- <input type="checkbox" name="tasks[cco_wire]" value="true" <?php if(isset(form->tasks['cco_wire'])): ?> <?php if(form->tasks['cco_wire']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
				  </td>
			  </tr>

				<tr>
					<td>techniIQ™ Movement Overview</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>RoR</li>
							<li>Wiggle</li>
							<li>Spin</li>
							<li>Dotter</li>
							<li>Constant Speed (2mm/sec and 5mm/sec)</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.tiq_movement_overview" class="form-control">
						<!-- <input type="checkbox" name="tasks[cco_wire]" value="true" <?php if(isset(form->tasks['cco_wire'])): ?> <?php if(form->tasks['cco_wire']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - technIQ™ Rotate on Retract(RoR) software</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>RoR enable and disable function</li>
							<li>RoR workflow</li>
							<li>RoR joystick operation</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_techniq_ror" class="form-control">
						<!-- <input type="checkbox" name="tasks[cco_techniq]" value="true" <?php if(isset(form->tasks['cco_techniq'])): ?> <?php if(form->tasks['cco_techniq']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - technIQ™ Wiggle software</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Wiggle enable and disable function</li>
							<li>Wiggle workflow</li>
							<li>Wiggle joystick operation</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_techniq_wiggle" class="form-control">
						<!-- <input type="checkbox" name="tasks[cco_techniq]" value="true" <?php if(isset(form->tasks['cco_techniq'])): ?> <?php if(form->tasks['cco_techniq']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - technIQ™ Spin software</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Spin enable and disable function</li>
							<li>Spin workflow</li>
							<li>Spin joystick operation</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_techniq_spin" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - technIQ™ Dotter software</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Dotter enable and disable function</li>
							<li>Dotter workflow</li>
							<li>Dotter joystick operation</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_techniq_dotter" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - technIQ™ Constant Speed software</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Constant Speed enable and disable function</li>
							<li>Constant Speed workflow</li>
							<li>Constant Speed joystick operation</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_techniq_constant_speed" class="form-control">
					</td>
				</tr>


				<tr>
					<td>Control Console Operation - RX Catheter</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>RX Speed and Direction Indicators</li>
							<li>RX and Guidewire Measurement Indicators</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_rx" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - Safety Icons</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Obstacle Detect, Sheath Detect and Motion Limit Icons</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_safety_icons" class="form-control">
						<!-- <input type="checkbox" name="tasks[cco_safety_icons]" value="true" <?php if(isset(form->tasks['cco_safety_icons'])): ?> <?php if(form->tasks['cco_safety_icons']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>
				
				<tr>
					<td>Control Console Operation - Icons</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Traction and Pushability Icons</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_icons" class="form-control">
						<!-- <input type="checkbox" name="tasks[cco_icons]" value="true" <?php if(isset(form->tasks['cco_icons'])): ?> <?php if(form->tasks['cco_icons']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Extended Reach Arm Operation</td>
					<td>
						<p>Review CAUTION - Ensure guide catheter or devices are NOTloaded onto the Single-use Cassette anytime Extended Reach Arm is moved</p>
						<p><strong>Review arm movement capabilities</strong></p>
						<ul>
							<li>Unlock button on front of Robotic Drive handle.</li>
							<li>Positioning for left and right radial and femoral access.</li>
						</ul>
						<p><strong>Review</strong></p>
						<ul>
							<li>E-stop button; location and use.</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.extended_reach_arm_operation" class="form-control">
						<!-- <input type="checkbox" name="tasks[extended_reach_arm_operation]" value="true" <?php if(isset(form->tasks['extended_reach_arm_operation'])): ?> <?php if(form->tasks['extended_reach_arm_operation']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Equipment needed for Robotic procedure</td>
					<td>Review single use robotic drape, console drape, Single-Use Cassette, Drive Gear and Y-connector function and use</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.equipment_needed_for_robotic" class="form-control">
						<!-- <input type="checkbox" name="tasks[equipment_needed_for_robotic]" value="true" <?php if(isset(form->tasks['equipment_needed_for_robotic'])): ?> <?php if(form->tasks['equipment_needed_for_robotic']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Prepare Guide Catheter</td>
					<td>Attach proximal end of Drive Gear to Y-connector, then connect distal end of Drive Gear to Guide Catheter hub</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.prepare_guide" class="form-control">
						<!-- <input type="checkbox" name="tasks[prepare_guide]" value="true" <?php if(isset(form->tasks['prepare_guide'])): ?> <?php if(form->tasks['prepare_guide']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Attach Single-use Cassette</td>
					<td>Open and attach Cassette to Robotic Drive unit. Align Cassette over optical sensor and ensure Cassette is flush with Robotic Drive surface</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.attach_single_use" class="form-control">
						<!-- <input type="checkbox" name="tasks[attach_single_use]" value="true" <?php if(isset(form->tasks['attach_single_use'])): ?> <?php if(form->tasks['attach_single_use']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Prepare Single-use Cassette for use</td>
					<td>
						<p>Follow system prompts shown on bedside touchscreen</p>
						<ul>
							<li>Straighten guide catheter support track from front of Cassette</li>
							<li>Open rotary drive cover, remove orange key</li>
							<li>Align rotary drive with linear drive, close cover; Cassette will perform self-test</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.prepare_single_use" class="form-control">
						<!-- <input type="checkbox" name="tasks[prepare_single_use]" value="true" <?php if(isset(form->tasks['prepare_single_use'])): ?> <?php if(form->tasks['prepare_single_use']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Prepare Robotic Drive for use</td>
					<td>Follow system prompt to tilt robotic drive 30 degrees</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.prepare_robotic_drive" class="form-control">
						<!-- <input type="checkbox" name="tasks[prepare_robotic_drive]" value="true" <?php if(isset(form->tasks['prepare_robotic_drive'])): ?> <?php if(form->tasks['prepare_robotic_drive']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Position arm, Robotic Drive/Cassette</td>
					<td>
						<ul>
							<li>Position Extended Reach Arm for case via unlock button on front of Robotic Drive handle</li>
							<li>Position Robotic Drive/Cassette to permit Y-connector holder to reach Y-connector</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.position_robotic_drive" class="form-control">
						<!-- <input type="checkbox" name="tasks[position_robotic_drive]" value="true" <?php if(isset(form->tasks['position_robotic_drive'])): ?> <?php if(form->tasks['position_robotic_drive']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Load Guide Catheter</td>
					<td>
						<p>Follow system prompts shown on bedside touchscreen</p>
						<ul>
							<li>Open lid of Y-connector holder</li>
							<li>Use Fine Tune Controls on front of Robotic Drive to optimize location of Robotic Drive/Cassette</li>
							<li>Position Drive Gear & Y-connector into Y-connector holder; close lid</li>
							<li>Extend guide catheter support track so that it encapsulates guide catheter</li>
							<li>Attach guide catheter support track to introducer sheath using sheath retainer ring</li>
							<li>Turn lever on Track Clamp clockwise 180o  to lock</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.load_guide" class="form-control">
						<!-- <input type="checkbox" name="tasks[load_guide]" value="true" <?php if(isset(form->tasks['load_guide'])): ?> <?php if(form->tasks['load_guide']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Load Devices/Exchange Devices</td>
					<td>
						<p>Select “Load/Exchange Devices” on bedside touchscreen</p>
						<ul>
							<li>Interact with Cassette on the Robotic Drive using instructions shown on bedside touchscreen</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.load_devices" class="form-control">
						<!-- <input type="checkbox" name="tasks[load_devices]" value="true"<?php if(isset(form->tasks['load_devices'])): ?> <?php if(form->tasks['load_devices']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Exchange Guide Catheter</td>
					<td>
						<p>Select “Exchange Guide” at bedside touchscreen</p>
						<ul>
							<li>Interact with Cassette on Robotic Drive using instructions shown on bedside touchscreen</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.exchange_guide" class="form-control">
						<!-- <input type="checkbox" name="tasks[exchange_guide]" value="true" <?php if(isset(form->tasks['exchange_guide'])): ?> <?php if(form->tasks['exchange_guide']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Movement of Guidewire and RX catheter</td>
					<td>
						<p>Use cockpit controls to position wire and RX catheter into desired position</p>
						<ul>
							<li>Review joystick control and touchscreen control movement, including rotation (wire)</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.movement_of_guidewire" class="form-control">
						<!-- <input type="checkbox" name="tasks[movement_of_guidewire]" value="true" <?php if(isset(form->tasks['movement_of_guidewire'])): ?> <?php if(form->tasks['movement_of_guidewire']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Movement of Guide Catheter</td>
					<td>
						<p>Use cockpit controls to position guide catheter to desired position</p>
						<ul>
							<li>Review joystick control and touchscreen control movement</li>
							<li>Review touchscreen values displayed (mm movement, rotation)</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.movement_of_guide" class="form-control">
						<!-- <input type="checkbox" name="tasks[movement_of_guide]" value="true" <?php if(isset(form->tasks['movement_of_guide'])): ?> <?php if(form->tasks['movement_of_guide']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Measurement Feature</td>
					<td>Correctly use measurement feature at Control Console</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.measurement_feature" class="form-control">
						<!-- <input type="checkbox" name="tasks[measurement_feature]" value="true" <?php if(isset(form->tasks['measurement_feature'])): ?> <?php if(form->tasks['measurement_feature']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>System Error Messages</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Unrecoverable error “Convert to Manual”</li>
							<li>Communication Error</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.system_error_mess" class="form-control">
						<!-- <input type="checkbox" name="tasks[system_error_mess]" value="true" <?php if(isset(form->tasks['system_error_mess'])): ?> <?php if(form->tasks['system_error_mess']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Finish case:</td>
					<td>
						<p>Select “End Case” on bedside touchscreen. Follow instructions shown on bedside touchscreen</p>
						<ul>
							<li>Turn Track Clamp counterclockwise 180o to unlock</li>
							<li>Rotate sheath retainer ring to disconnect track from introducer sheath</li>
							<li>Retract guide catheter support track into Cassette by pulling proximal end of track</li>
							<li>Open lid on Y-Connector Holder and lift the Y-Connector, guide catheter and wire/catheter out of cassette</li>
							<li>Close Cassette covers and gates</li>
						</ul>

						<p>Press “Release Cassette” on bedside touchscreen</p>
						<ul>
							<li>Remove Cassette from Robotic Drive</li>
							<li>Follow system prompts to level Robotic Drive</li>
							<li>Ensure area around Robotic Drive is free from obstruction; select “OK to move” on bedside touchscreen</li>
							<li>• Remove drape, move Extended Reach Arm away from patient and place in Stow position</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.finish_case" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Communication error, troubleshooting</td>
					<td>Review power source connections, E-stop buttons and communication cable connections</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.troubleshooting" class="form-control">
					</td>
				</tr>
				
			  <tr>
				  <td>Additional CorPath information</td>
				  <td>
					  <p>Review</p>
					  <ul>
						  <li>Location of CorPath GRX System Operator’s Manual</li>
						  <li>Location of Single-use Cassette Instructions for Use</li>
					  </ul>
				  </td>
				  <td>
					  <label>Observe:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.additional" class="form-control">
				  </td>
			  </tr>
		  </table>

	  </div>


	  <div class="section">
		  <div class="row">
			<div class="col-md-12">
				<div>
					<div>
					  <label>Corindus, A siemens Healthineers Company is proud to certify that ( enter physicians name below) has successfully completed training on CorPath GRX Vascular Robotic system *</label>
					  <span>
						  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.physician_name}">
							  <div class="textfield__box">
								  <input type="text" id="confirm[physician_name]" name="confirm[physician_name]" class="textfield__input" [(ngModel)]="form?.confirm.physician_name">
								  <label class="textfield__label" for="confirm[physician_name]"></label>
							  </div>
							  <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
						  </div>
					  </span>
					</div>      
				</div>
			</div>
			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.corindus_trainer}">
								<div class="textfield__box">
									<input type="text" id="confirm[corindus_trainer]" name="confirm[corindus_trainer]" class="textfield__input" [(ngModel)]="form?.confirm.corindus_trainer">
									<label class="textfield__label" for="confirm[corindus_trainer]">Corindus Trainer Name:*</label>
								</div>
								<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						</span>
					</div>      
				</div>
			</div>
			  <div class="col-md-12">
				  <div>
					  <div>
						  <span>
							  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.date_of_phase1_completion}">
								  <div class="textfield__box">
									  <input type="text" id="confirm[date_of_phase1_completion]" name="confirm[date_of_phase1_completion]" class="textfield__input" [(ngModel)]="form?.confirm.date_of_phase1_completion">
									  <label class="textfield__label" for="confirm[date_of_phase1_completion]">Date of Phase 1 Completion:*</label>
								  </div>
								  <small class="textfield__hint display-flex"><span class="col-10 padding-0">Format: M/d/yyyy</span></small>
							  </div>
						  </span>
					  </div>      
				  </div>
			  </div>

			  <div class="col-md-12">
				<div>
					<div>
					  <label>Physician hereby confirms that they have been trained on this system, that they are able to access translated CorPath GRX Operator Manuals at<br><a href="https://www.corindus.com/operatorsmanual">https://www.corindus.com/operatorsmanual</a>, and that they are professionally capable of operating the system with the Graphic User Interface( GUI ) touchscreen</label>
					  <input type="radio"  [(ngModel)]="form?.confirm.confirm_trained" name="confirm[confirm_trained]" value="yes" class="form-control"><label>Yes</label>
					  <input type="radio" [(ngModel)]="form?.confirm.confirm_trained" name="confirm[confirm_trained]" value="no" class="form-control"><label>No</label>
					</div>      
				</div>
			</div>

			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div>
								<label class="" for="confirm[physician_signature]">Physician Signature:</label>
							</div>
							<div class="canvas-holder">
								<canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>

								<button class="sgn-button" (click)="clearPad()">Clear</button>
							</div>
						</span>
					</div> 
				</div>
			</div>
		  </div>

		  <div class="row form-note">
			  Upon completion of Phase 1, please submit this form to Corindus for documentation of proficiency
		  </div>

		  <div class="row form-quote">
			  The training described herein is strictly on the use of the CorPath GRX Vascular Robotic System and not the procedure being performed. This program does
			  not replace any specific hospital or physician requirements necessary to perform procedures. This document is strictly private, confidential and personal to
			  its recipients and should not be copied, distributed or reproduced in whole or in part, nor passed to any third party
		  </div>

		  <div class="heading">
			  HEADQUARTERS APPROVAL: Name, Signature, Date: 
		  </div>

		  <div class="row">
			  <div class="col-md-12">
				  <div>
					  <div>
						  <span>
							  <div class="textarea w-100" [ngClass]="{'is-active': form?.approval.signature}">
								<div class="textarea__container">
									<textarea name="approval[signature]" class="textarea__input" row="2" [(ngModel)]="form?.approval.signature"></textarea>
									<label class="textarea__label" for="approval[signature]"></label>
								</div>
								<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						  </span>
					  </div>      
				  </div>
			  </div>
		  </div>
	  </div>

	  <div class="row btn-row submit-form">
		  <button class="button button--primary display-block" (click)="saveDraft()">Save draft</button>
	  </div>
  </div>
  <div class="row btn-row complete-form">
	<button class="button button--primary display-block" (click)="complete()">Complete</button>
  </div>
</div>