<h1 mat-dialog-title [ngClass]="{'text-danger': data.errorDialog, 'text-success': data.alertDialog, 'text-warning': data.confirmDialog}">{{data.title}}</h1>

<!-- Normal Dialog -->
<div mat-dialog-content>
  <p [innerHtml]="data.message">{{data.message}}</p>
</div>

<!-- Selection from multiple options Dialog -->
<!-- <div mat-dialog-content *ngIf="data.selectionDialog">
  <div class="col-md-12">
    <div class="custom-control custom-radio" *ngFor="let option of data.options">
      <input class="custom-control-input" type="radio" id="{{option}}" [(ngModel)]="selectedOption" [value]="option">
      <label class="custom-control-label" for="{{option}}">{{option}}</label>
    </div>
  </div>
</div> -->


<!-- Dialog Actions -->
<div mat-dialog-actions align="end" *ngIf="data.alertDialog">
  <button class="btn btn-outline-success" (click)="okClick()">OK</button>
</div>
<div mat-dialog-actions align="end" *ngIf="data.errorDialog">
  <button class="btn btn-outline-danger" (click)="okClick()">OK</button>
</div>
<div mat-dialog-actions align="end" *ngIf="data.confirmDialog">
  <button class="btn btn-outline-secondary mr-2" mat-dialog-close>No</button>
  <button class="btn btn-outline-danger" [mat-dialog-close]="true">Yes</button>
</div>
<div mat-dialog-actions align="end" *ngIf="data.logoutDialog">
  <button class="btn btn-outline-primary" [mat-dialog-close]="true">Keep me logged in!</button>
</div>
<!-- <div mat-dialog-actions align="end" *ngIf="data.routeDialog || data.selectionDialog">
  <button class="btn btn-outline-secondary mr-2" mat-dialog-close>Cancel</button>
  <button class="btn btn-outline-success" (click)="saveClick()">Save</button>
</div>
<div mat-dialog-actions align="end" *ngIf="data.closeInvoiceDialog">
  <button class="btn btn-outline-secondary mr-2" mat-dialog-close>Cancel</button>
  <button class="btn btn-outline-success" (click)="saveClick()" [disabled]="isFormValid()">Save</button>
</div> -->