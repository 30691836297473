<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
		<span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-department-details">Department Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					HCP Training Form ( Neurovascular Phase I )
				</span>
	  </li>
		</ol>   
	</div>
</section>

<div class="form-container hcp-training-form">
  <div>
	  <div class="section">
			<div class="row">
				<div class="col-md-8">
					<div class="hero-block__headings">
						<h1 class="hero-block__title">CORPATH GRX NEUROVASCULAR ROBOTIC SYSTEM</h1>
					</div>
				</div>
				<div class="col-md-4 flex-reverse">
				<button class="button display-block btn-sm" routerLink="/account-department-details">Back</button>
				</div>
			</div>

			<div class="heading">
				<h2>Phase I Training – CorPath GRX Neurovascular Physician Training Pathway</h2>
			</div>
	  
		  
			<div class="s-heading">
				<h3>Training Pathway: Overview</h3>
			</div>

			<div class="intro">
				<p>The following document outlines activities required for physicians to complete Phase I training on the CorPath GRX Neurovascular Robotic System</p>
			</div>

		  
			<div class="row">
			  <div class="col-md-6">
					<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.physician_name}">
						<div class="textfield__box">
							<input type="text" name="overview[physician_name]" class="textfield__input" [(ngModel)]="form?.overview.physician_name">
							<label class="textfield__label" for="overview[physician_name]">Physician Name:</label>
						</div>
						<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>
			  </div>
			  <div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.hospital}">
					<div class="textfield__box">
						<input type="text" id="overview[hospital]" name="overview[hospital]" class="textfield__input" [(ngModel)]="form?.overview.hospital">
						<label class="textfield__label" for="overview[hospital]">Hospital:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		  </div>

		  <div class="row">
			<div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.cellphone}">
					<div class="textfield__box">
						<input type="text" name="overview[cellphone]" class="textfield__input" [(ngModel)]="form?.overview.cellphone">
						<label class="textfield__label" for="overview[cellphone]">Cell Phone #:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
			<div class="col-md-6">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.office}">
					<div class="textfield__box">
						<input type="text" id="overview[office]" name="overview[office]" class="textfield__input" [(ngModel)]="form?.overview.office">
						<label class="textfield__label" for="overview[office]">Office #:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		</div>

		  <div class="row">
			<div class="col-md-12">
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.email}">
					<div class="textfield__box">
						<input type="text" name="overview[email]" class="textfield__input" [(ngModel)]="form?.overview.email">
						<label class="textfield__label" for="overview[email]">Email Address:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		</div>
	  </div>


	  <div class="section">
		  <div class="s-heading">
			  <h2>CORPATH GRX NEUROVASCULAR TRAINING PATHWAY SUMMARY</h2>
		  </div>
		  <div class="entry-content">
			  <p>Corindus will administer a training and education program developed for the CorPath GRX Neurovascular Robotic System. Prior to using the System, physicians must attend and satisfactorily complete the following <strong>Phase I training</strong> elements:</p>
			  <ul>
				  <li>Attend the didactic portion of the training, presented by a member of the Corindus clinical staff.</li>
				  <li>Perform hands-on training on either a demonstration equipment, with verbal guidance and evaluation from Corindus clinical staff.</li>
			  </ul>
			  <p>Physicians will be issued a certificate of completion of <strong>CorPath Physician Training</strong> upon successful completion of <strong>Phase I</strong>. Corindus will only support procedural use of the CorPath GRX Neurovascular Robotic System after a physician completes the <strong>Phase I</strong> training program</p>
			  <p>Additionally, Corindus will provide hands-on training constituting <strong>Phase II</strong> of the program, followed by observation of an initial series of 5 cases with each trained physician, and conduct a comprehensive review, constituting <strong>Phase III</strong> of the program. Physicians will be issued a certificate of completion of <strong>CorPath Physician Training</strong> upon completion of <strong>Phase III</strong>.</p>
			  <p>A checklist will be utilized by Corindus clinical staff to ensure proficiency. The competency assessment will be maintained at Corindus. Repetition of the above tasks may be necessary until the physician user shows competence with all requirements. Non-physician staff will be encouraged to participate in the training sessions with physicians.</p>

			<ul>
				<h5>PREPARATION</h5>
				<li>Credentialing requirements met, if any (hospital specific)</li>
				<li>Procedure scheduling complete</li>
			</ul>

			<ul>
				<h5>PHASE I - BASIC CORPATH TRAINING</h5>
				<li>Product overview</li>
				<li>System training</li>
				<li>Skills practice</li>
			</ul>

			<ul>
				<h5>PHASE II - Flow Model Skills PRACTICE</h5>
				<li>Hands-on skills development</li>
				<li>Device selection practice</li>
				<li>Simulated procedure practice</li>
			</ul>

			<ul>
				<h5>PHASE III - Initial SERIES OF 5 ROBOTIC CASES</h5>
				<li>Initial case series activities – 5 cases</li>
				<li>Comprehensive review (post-5th case)</li>
			</ul>
			 
	  </div>

	  <div class="section">
			<div class="subheading">
				<h3>PHASE I - BASIC CORPATH TRAINING</h3>
			</div>

			<!-- <div class="row">
				<div class="col-md-4">
					<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.detail.physician_name}">
						<div class="textfield__box">
							<input type="text" name="detail[physician_name]" class="textfield__input" [(ngModel)]="form?.detail.physician_name">
							<label class="textfield__label" for="detail[physician_name]">Physician Name:</label>
						</div>
						<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>
				</div>
				<div class="col-md-4">
					<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.detail.hospital}">
						<div class="textfield__box">
							<input type="text" id="detail[hospital]" name="detail[hospital]" class="textfield__input" [(ngModel)]="form?.detail.hospital">
							<label class="textfield__label" for="detail[hospital]">Hospital:</label>
						</div>
						<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>
				</div>

				<div class="col-md-4">
					<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.detail.date}">
						<div class="textfield__box">
							<input type="date" id="detail[date]" name="detail[date]" class="textfield__input" [(ngModel)]="form?.detail.date">
							<label class="textfield__label" for="detail[date]">Date:</label>
						</div>
						<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					</div>
				</div>
			</div> -->
			  
		  <table class="table table-features">
				<tr class="heading">
					<td>Task</td>
					<td>Activity</td>
					<td>Method</td>
				</tr>
				<tr>
					<td>CorPath GRX System Overview</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>System components including Indications for Use</li>
							<li>Extended Reach Arm</li>
							<li>Robotic Drive</li>
							<li>Cockpit,Console</li>
							<li>Single Use Cassette</li>
							<li>icons</li>
						</ul>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.system_overview" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Site specific connections</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Power connection</li>
							<li>Video connection</li>
							<li>Communication cable connection</li>
						</ul>
						</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.specific_connections" class="form-control">
					</td>
				</tr>
				<tr>
					<td>System power up</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Power Up button location and use</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.system_power_up" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Menu Configuration</td>
					<td>
					<p>Review:</p>
					<ul>
						<li>Locate and press Menu button</li>
						<li>Review options on menu including Disable and Shutdown buttons</li>
						<li>Select System Configuration and set to appropriate loading position</li>
					</ul>
				</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.menu_configuration" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Cockpit overview</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Control Console</li>
							<li>Monitor</li>
							<li>Room Camera</li>
						</ul>
						</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cockpit_overview" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Control Console Operation</td>
					<td>
						<p>Review Control Console and touchscreen functions, including:</p>
						<ul>
							<li>Individual Joystick control and disable function</li>
							<li>Simultaneous device movement</li>
							<li>Turbo Speed movement</li>
							<li>E-stop button, location and use in menu</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Loading Position Options</td>
					<td>
						<ul>
							<li>Review design and intended use of Center Loading Position</li>
							<li>Review design and intended use of Rear Loading Position</li>
							<li>Review ways to change loading position and process for doing so:
								<ul>
									<li>Default system configuration</li>
									<li>Start of new case (before and after tilt)</li>
									<li>During catheter exchange</li>
								</ul>
							</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.loading_position_options" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Control Console Operation - Catheter</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Catheter Joystick Rotational and Linear Travel indicators</li>
							<li>Catheter Joystick operation and disable function</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_catheter" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Control Console Operation - Guide Wire</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Guidewire Joystick Rotational, Speed and Direction indicators</li>
							<li>Guidewire Speed and Direction Indicators</li>
							<li>Guidewire discrete  rotation</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_wire" class="form-control">
					</td>
				</tr>
				<tr>
					<td>Control Console Operation - Device</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Device Speed and Direction Indicators</li>
							<li>Device Speed and Direction Indicators</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_device" class="form-control">
					</td>
				</tr>

				<tr>
					<td>TechnIQ™ Automated Moves</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Review technIQ™ dropdown menu location and operation</li>
							<li>Practice enabling and disabling technIQ™ moves using the drop-down menu</li>
							<li>Review technIQ™ move options, functionality, and operation for Catheter drive
								<ul>
									<li>Active Device Fixation</li>
								</ul>
							</li>
							<li>Review technIQ™ move options, functionality, and operation for Guidewire drive
								<ul>
									<li>RoR</li>
									<li>Limited Speed</li>
								</ul>
							</li>
							<li>Review technIQ™ move options, functionality, & operation for Device drive
								<ul>
									<li>Limited Speed</li>
								</ul>
							</li>

						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.techiq_auto_move" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Control Console Operation - Safety Icons</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Obstacle Detect, Sheath Detect and Motion Limit Icons</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_safety_icons" class="form-control">
					</td>
				</tr>
				
				<tr>
					<td>Control Console Operation - Icons</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Traction and Pushability Icons</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.cco_icons" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Extended Reach Arm Operation</td>
					<td>
						<p>Review CAUTION - Ensure guide catheter or devices are NOTloaded onto the Single-use Cassette anytime Extended Reach Arm is moved</p>
						<p><strong>Review arm movement capabilities</strong></p>
						<ul>
							<li>Unlock button on front of Robotic Drive handle.</li>
							<li>Positioning for left and right radial and femoral access.</li>
						</ul>
						<p><strong>Review</strong></p>
						<ul>
							<li>E-stop button; location and use.</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
					</td>
				</tr>

				<tr>
					<td>Equipment needed for Robotic procedure</td>
					<td>Review single use robotic drape, console drape, Single-Use Cassette, Drive Gear and Y-connector function and use</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.equipment_needed_for_robotic" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Prepare Catheter</td>
					<td>Attach proximal end of Drive Gear to Y-connector, then connect distal end of Drive Gear to Guide Catheter hub</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.prepare_catheter" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Attach Single-use Cassette</td>
					<td>Open and attach Cassette to Robotic Drive unit. Align Cassette over optical sensor and ensure Cassette is flush with Robotic Drive surface</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.attach_single_use" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Prepare Single-use Cassette for use</td>
					<td>
						<p>Follow system prompts shown on bedside touchscreen</p>
						<ul>
							<li>Straighten guide catheter support track from front of Cassette</li>
							<li>Open rotary drive cover, remove orange key</li>
							<li>Align rotary drive with linear drive, close cover; Cassette will perform self-test</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.prepare_single_use" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Prepare Robotic Drive for use</td>
					<td>Follow system prompt to tilt robotic drive 30 degrees</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.prepare_robotic_drive" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Position arm, Robotic Drive/Cassette</td>
					<td>
						<ul>
							<li>Position Extended Reach Arm for case via unlock button on front of Robotic Drive handle</li>
							<li>Position Robotic Drive/Cassette to permit Y-connector holder to reach Y-connector</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.position_robotic_drive" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Catheter Loading and Exchange</td>
					<td>
						<ul>
							<li>Demonstrate how to load a catheter for a new case by following bedside instructions</li>
							<li>Perform exchange of catheter by following bedside instructions</li>
							<li>Demonstrate how to end a case when a catheter is loaded by following bedside instructions</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.load_catheter" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Load Devices/Exchange Devices</td>
					<td>
						<p>Select “Load/Exchange Devices” on bedside touchscreen</p>
						<ul>
							<li>Interact with Cassette on the Robotic Drive using instructions shown on bedside touchscreen</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.load_devices" class="form-control">
						<!-- <input type="checkbox" name="tasks[load_devices]" value="true"<?php if(isset(form->tasks['load_devices'])): ?> <?php if(form->tasks['load_devices']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Movement of Guidewire and Devices</td>
					<td>
						<p>Use cockpit controls to position wire and device into desired position</p>
						<ul>
							<li>Review joystick control and touchscreen control movement, including rotation (wire)</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.movement_of_guidewire" class="form-control">
						<!-- <input type="checkbox" name="tasks[movement_of_guidewire]" value="true" <?php if(isset(form->tasks['movement_of_guidewire'])): ?> <?php if(form->tasks['movement_of_guidewire']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Movement of Catheter</td>
					<td>
						<p>Use cockpit controls to position catheter to desired position</p>
						<ul>
							<li>Review joystick control and touchscreen control movement</li>
							<li>Review touchscreen values displayed (mm movement, rotation)</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.movement_of_guide" class="form-control">
						<!-- <input type="checkbox" name="tasks[movement_of_guide]" value="true" <?php if(isset(form->tasks['movement_of_guide'])): ?> <?php if(form->tasks['movement_of_guide']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Measurement Feature</td>
					<td>Correctly use measurement feature at Control Console</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.measurement_feature" class="form-control">
						<!-- <input type="checkbox" name="tasks[measurement_feature]" value="true" <?php if(isset(form->tasks['measurement_feature'])): ?> <?php if(form->tasks['measurement_feature']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>System Error Messages</td>
					<td>
						<p>Review:</p>
						<ul>
							<li>Unrecoverable error “Convert to Manual”</li>
							<li>Communication Error</li>
						</ul>
					</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.system_error_mess" class="form-control">
						<!-- <input type="checkbox" name="tasks[system_error_mess]" value="true" <?php if(isset(form->tasks['system_error_mess'])): ?> <?php if(form->tasks['system_error_mess']=='true'): ?> checked <?php endif; ?> <?php endif; ?> class="form-control"> -->
					</td>
				</tr>

				<tr>
					<td>Finish case:</td>
					<td>
						<p>Select “End Case” on bedside touchscreen. Follow instructions shown on bedside touchscreen</p>
						<ul>
							<li>Turn Track Clamp counterclockwise 180o to unlock</li>
							<li>Rotate sheath retainer ring to disconnect track from introducer sheath</li>
							<li>Retract guide catheter support track into Cassette by pulling proximal end of track</li>
							<li>Open lid on Y-Connector Holder and lift the Y-Connector, guide catheter and wire/catheter out of cassette</li>
							<li>Close Cassette covers and gates</li>
						</ul>

						<p>Press “Release Cassette” on bedside touchscreen</p>
						<ul>
							<li>Remove Cassette from Robotic Drive</li>
							<li>Follow system prompts to level Robotic Drive</li>
							<li>Ensure area around Robotic Drive is free from obstruction; select “OK to move” on bedside touchscreen</li>
							<li>• Remove drape, move Extended Reach Arm away from patient and place in Stow position</li>
						</ul>
					</td>
					<td>
						<label>Observe & Perform:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.finish_case" class="form-control">
					</td>
				</tr>

				<tr>
					<td>Communication error, troubleshooting</td>
					<td>Review power source connections, E-stop buttons and communication cable connections</td>
					<td>
						<label>Observe:</label>
						<input type="checkbox" [(ngModel)]="form?.tasks.troubleshooting" class="form-control">
					</td>
				</tr>
				
			  <tr>
				  <td>Additional CorPath information</td>
				  <td>
					  <p>Review</p>
					  <ul>
						  <li>Location of CorPath GRX System Operator’s Manual</li>
						  <li>Location of Single-use Cassette Instructions for Use</li>
					  </ul>
				  </td>
				  <td>
					  <label>Observe:</label>
					  <input type="checkbox" [(ngModel)]="form?.tasks.additional" class="form-control">
				  </td>
			  </tr>
		  </table>

	  </div>


	  <div class="section">
		<div class="heading">
			<h3>PHASE I - BASIC CORPATH PHYSICIAN TRAINING</h3>
		</div>
  
	  
		<div class="s-heading">
			<h5>PRODUCT OVERVIEW</h5>
		</div>

		<div class="intro">
			<p>CorPath Didactic Training</p>
		</div>

		<div class="s-heading">
			<h5>SYSTEM TRAINING</h5>
		</div>

		<div class="intro">
			<p>Complete hands-on system training with Corindus Representative, System Component Overview and Setup, Cassette Overview and Loading, Physician Cockpit Overview, Emergency Procedures Complete list of activities in checklist on Pages 3-5</p>
		</div>

		<div class="s-heading">
			<h5>SYSTEM TRAINING</h5>
		</div>

		<div class="intro">
			<p><strong>Sufficiently Perform System Skills on Model</strong></p>
			<div class="row">
				<div class="col-md-6">
					<ul>
						<li>System setup – Cassette load and device exchange</li>
						<li>Advance/retract guidewire and RX catheter</li>
						<li>Rotate guidewire to steer toward target site</li>
						<li>Enable and use technIQ™ automated movements</li>
						<li>Millimeter movement feature</li>
						<li>Adjust loading position at console</li>
					</ul>
				</div>
				<div class="col-md-6">
					<ul>
						<li>Measurement techniques</li>
						<li>Discrete guidewire rotation</li>
						<li>Simultaneous device movement</li>
						<li>Catheter movement</li>
						<li>Turbo-speed movement</li>
						<li>Adjust loading position at bedside</li>
					</ul>
				</div>
			</div>
		</div>

		  <div class="row">
			<div class="col-md-12">
				<div>
					<div>
					  <label>Corindus, A siemens Healthineers Company is proud to certify that ( enter physicians name below) has successfully completed training on CorPath GRX Neurovascular Robotic system *</label>
					  <span>
						  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.physician_name}">
							  <div class="textfield__box">
								  <input type="text" id="confirm[physician_name]" name="confirm[physician_name]" class="textfield__input" [(ngModel)]="form?.confirm.physician_name">
								  <label class="textfield__label" for="confirm[physician_name]"></label>
							  </div>
							  <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
						  </div>
					  </span>
					</div>      
				</div>
			</div>
			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.corindus_trainer}">
								<div class="textfield__box">
									<input type="text" id="confirm[corindus_trainer]" name="confirm[corindus_trainer]" class="textfield__input" [(ngModel)]="form?.confirm.corindus_trainer">
									<label class="textfield__label" for="confirm[corindus_trainer]">Corindus Trainer Name:*</label>
								</div>
								<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						</span>
					</div>      
				</div>
			</div>
			  <div class="col-md-12">
				  <div>
					  <div>
						  <span>
							  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.date_of_phase1_completion}">
								  <div class="textfield__box">
									  <input type="text" id="confirm[date_of_phase1_completion]" name="confirm[date_of_phase1_completion]" class="textfield__input" [(ngModel)]="form?.confirm.date_of_phase1_completion">
									  <label class="textfield__label" for="confirm[date_of_phase1_completion]">Date of Phase 1 Completion:*</label>
								  </div>
								  <small class="textfield__hint display-flex"><span class="col-10 padding-0">Format: M/d/yyyy</span></small>
							  </div>
						  </span>
					  </div>      
				  </div>
			  </div>

			<div class="col-md-12">
				<div>
					<div>
						<span>
							<div>
								<label class="" for="confirm[physician_signature]">Physician Signature:</label>
							</div>
							<div class="canvas-holder">
								<canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>

								<button class="sgn-button" (click)="clearPad()">Clear</button>
							</div>
						</span>
					</div> 
				</div>
			</div>

			<div class="col-md-12">
				<div>
					<div>
					  <label>Physician hereby confirms that they have been trained on this system, that they are able to access translated CorPath GRX Operator Manuals at<br><a href="https://www.corindus.com/operatorsmanual">https://www.corindus.com/operatorsmanual</a>, and that they are professionally capable of operating the system with the Graphic User Interface( GUI ) touchscreen</label>
					  <input type="radio"  [(ngModel)]="form?.confirm.confirm_trained" name="confirm[confirm_trained]" value="yes" class="form-control"><label>Yes</label>
					  <input type="radio" [(ngModel)]="form?.confirm.confirm_trained" name="confirm[confirm_trained]" value="no" class="form-control"><label>No</label>
					</div>      
				</div>
			</div>

		  </div>

		  <div class="row form-note">
				Upon completion of Phase I, please return pages 3-7 to Corindus for documentation of proficiency
		  </div>

		  <div class="row form-quote">
			The training described herein is strictly on the use of the CorPath GRX Neurovascular Robotic System and not the procedure being performed. This program 
			does not replace any specific hospital or physician requirements necessary to perform procedures. This document is strictly private, confidential, and personal to its recipients and should not be copied, distributed, or reproduced in whole or in part, nor passed to any third party
		  </div>

		  <div class="heading">
			  HEADQUARTERS APPROVAL: Name, Signature, Date: 
		  </div>

		  <div class="row">
			  <div class="col-md-12">
				  <div>
					  <div>
						  <span>
							  <div class="textarea w-100" [ngClass]="{'is-active': form?.approval.signature}">
								<div class="textarea__container">
									<textarea name="approval[signature]" class="textarea__input" row="2" [(ngModel)]="form?.approval.signature"></textarea>
									<label class="textarea__label" for="approval[signature]"></label>
								</div>
								<small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
							</div>
						  </span>
					  </div>      
				  </div>
			  </div>
		  </div>
	  </div>

	  <div class="row btn-row submit-form">
		  <button class="button button--primary display-block" (click)="saveDraft()">Save draft</button>
	  </div>
  </div>
  <div class="row btn-row complete-form">
	<button class="button button--primary display-block" (click)="complete()">Complete</button>
  </div>
</div>