import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { hcpURL, serverURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HcpService {

  constructor(private http: HttpClient) { }

  getAllHcpsbyDepartmentAndAccount(department, account): Observable<any>{
    return this.http.get<any>(hcpURL+"?account="+account+"&department="+department)
  }

  getAllHcpsbyAccount(account): Observable<any>{
    return this.http.get<any>(hcpURL+"?account="+account)
  }

  createHcp(hcpDetails): Observable<any>{
    return this.http.post<any>(hcpURL, hcpDetails)
  }

  updateHcp(id, hcp): Observable<any> {
    return this.http.patch<any>(hcpURL+id+"/", hcp);
  }

  sendConsentEmail(hcp): Observable<any>{
    return this.http.post<any>(serverURL+"healthcare-api/send-consent-email/", {hcp: hcp});
  }

}
