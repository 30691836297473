import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountChecklistComponent } from './account-checklist/account-checklist.component';
import { AccountDepartmentDetailsComponent } from './account-department-details/account-department-details.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { AccountSiteVisitComponent } from './account-site-visit/account-site-visit.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ConfirmDateComponent } from './confirm-date/confirm-date.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DepartmentsComponent } from './departments/departments.component';
import { HcpRolesComponent } from './hcp-role/hcp-roles.component';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './login/login.component';
import { AddProcedureComponent } from './procedure/add-procedure/add-procedure.component';
import { ProcedureComponent } from './procedure/procedure/procedure.component';
import { ProceduresComponent } from './procedure/procedures/procedures.component';
import { ReportsComponent } from './reports/reports.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CaseTrainingComponent } from './trainings/case-training/case-training.component';
import { HcpTrainingComponent } from './trainings/hcp-training/hcp-training.component';
import { Hcp2TrainingComponent } from './trainings/hcp2-training/hcp2-training.component';
import { NeuroCaseTrainingComponent } from './trainings/neuro-case-training/neuro-case-training.component';
import { NeuroP1TrainingComponent } from './trainings/neuro-p1-training/neuro-p1-training.component';
import { NeuroP2TrainingComponent } from './trainings/neuro-p2-training/neuro-p2-training.component';
import { StaffTrainingComponent } from './trainings/staff-training/staff-training.component';
import { UsersComponent } from './users/users.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard] },
  { path: 'account-details', component: AccountDetailsComponent, canActivate: [AuthGuard] },
  { path: 'account-department-details', component: AccountDepartmentDetailsComponent, canActivate: [AuthGuard] },
  { path: 'account-checklist', component: AccountChecklistComponent, canActivate: [AuthGuard] },
  { path: 'account-site-visit', component: AccountSiteVisitComponent, canActivate: [AuthGuard] },
  { path: 'hcp-training', component: HcpTrainingComponent, canActivate: [AuthGuard] },
  { path: 'hcp2-training', component: Hcp2TrainingComponent, canActivate: [AuthGuard] },
  { path: 'case-training', component: CaseTrainingComponent, canActivate: [AuthGuard] },
  { path: 'neuro-p1-training', component: NeuroP1TrainingComponent, canActivate: [AuthGuard] },
  { path: 'neuro-p2-training', component: NeuroP2TrainingComponent, canActivate: [AuthGuard] },
  { path: 'neuro-case-training', component: NeuroCaseTrainingComponent, canActivate: [AuthGuard] },
  { path: 'staff-training', component: StaffTrainingComponent, canActivate: [AuthGuard] },
  { path: 'departments', component: DepartmentsComponent, canActivate: [AuthGuard] },
  { path: 'hcp-roles', component: HcpRolesComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'confirm-date', component: ConfirmDateComponent, canActivate: [AuthGuard] },

  { path: 'procedures', component: ProceduresComponent, canActivate: [AuthGuard] },
  { path: 'procedure', component: ProcedureComponent, canActivate: [AuthGuard] },
  { path: 'add-procedure', component: AddProcedureComponent, canActivate: [AuthGuard] },
  

  { path: '**', component: LoginComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
