import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { accountsURL, serverURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: HttpClient) { }

  getAccount(id): Observable<any>{
    return this.http.get<any>(accountsURL+id+"/")
  }

  getAllAccounts(): Observable<any>{
    return this.http.get<any>(accountsURL)
  }

  getDashboardData(): Observable<any>{
    return this.http.get<any>(serverURL+"accounts-api/dashboard/")
  }

  installationComplete(accountId): Observable<any>{
    return this.http.post<any>(serverURL+"accounts-api/installation-complete", {account: accountId})
  }

  createAccount(accountDetails): Observable<any>{
    return this.http.post<any>(accountsURL, accountDetails)
  }

  updateAccount(id, account): Observable<any> {
    return this.http.patch<any>(accountsURL+id+"/", account);
  }

  search(type, accounts?, from_date?, to_date?): Observable<any> {
    return this.http.get(serverURL+`accounts-api/search?type=${type}&accounts=${accounts}&from_date=${from_date}&to_date=${to_date}`)
  }

  getEvent(event): Observable<any>{
    return this.http.get<any>(serverURL+"accounts-api/events/"+event+"/")
  }

  confirmEvent(event,data): Observable<any>{
    return this.http.patch<any>(serverURL+"accounts-api/events/"+event+"/", data)
  }


}
