import { Injectable, SecurityContext } from '@angular/core';
import {MatDialog} from '@angular/material/dialog'; 
import { DialogComponent } from '../dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';


@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog, private router: Router, private authService: AuthenticationService, private dom: DomSanitizer) { }

  //messageObj should be an object with 'title' and 'message'
  alertDialog(messageObj){
    messageObj.alertDialog = true;
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: messageObj
    });
  }

  errorDialog(messageObj){
    messageObj.errorDialog = true;
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: messageObj
    });
  }

  errorDialogNew(error){
    let messageObj = {title: "", message: "", errorDialog: true}
    messageObj.title = error.status + " " + error.statusText
    if(error.status == 500){
      messageObj.title = "Internal Server Error."
      messageObj.message = "Check console for more information or contact your administration."
    }
    else if(error.status == 0){
      messageObj.title = "Network Request Failed."
      messageObj.message = "It seems like our server is not responding. Please try again later or if problem persists please inform your adminisration."
    }
    else if(error.status == 401){
      // messageObj.title = "Token Expired."
      // messageObj.message = "Please login again."
      messageObj.title = error.statusText
      messageObj.message = error.error.error
    }
    else
      messageObj.message = JSON.stringify(error.error)

    let dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: messageObj
    });
    if(error.status==401){
      dialogRef.afterClosed().toPromise().then(() => {
        this.authService.logout()
      })
    }
  }

  // selectionDialog(title, message, options){
  //   let messageObj = {title: "", message: "", options: "", selectionDialog: true}
  //   messageObj.title = title
  //   messageObj.message = message
  //   messageObj.options = options
  //   let dialogRef = this.dialog.open(DialogComponent, {
  //     width: '400px',
  //     data: messageObj
  //   });
  //   dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, message)

  //   return dialogRef.afterClosed().toPromise()
  // }

  alertDialogWithReturn(messageObj){
    messageObj.alertDialog = true;
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: messageObj
    });

    return dialogRef.afterClosed().toPromise()
  }

  confirmDialog(messageObj){
    messageObj.confirmDialog = true;
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: messageObj
    });
    return dialogRef.afterClosed().toPromise()
  }

  logoutDialog(messageObj){
    messageObj.logoutDialog = true;
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: messageObj
    });
    return dialogRef.afterClosed().toPromise()
  }
}
