import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  selectedOption;
  // paymentMethod;
  htmlContent: string;
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }
    
  location = {latitude: 0, longitude: 0};
  ngOnInit() {
    if(this.data.mapDialog){
      this.location = this.data.location;
    }
  }

  isObject(something): boolean{
    if((typeof something)=='object') 
      return true
    else
      return false
  }

  okClick(): void {
    this.dialogRef.close();
  }

  cancelClick(): void {
    this.dialogRef.close('cancelled');
  }

  // isFormValid(){
  //   if(this.selectedOption && this.selectedOption.name == 'With Payment')
  //     return !this.selectedOption || !this.paymentMethod
  //   return !this.selectedOption
  // }

  // saveClick(){
  //   if(this.data.closeInvoiceDialog)
  //     if(this.selectedOption.name == 'With Payment')
  //       this.dialogRef.close({status: this.selectedOption, paymentMethod: this.paymentMethod})
  //     else
  //       this.dialogRef.close({status: this.selectedOption, paymentMethod: ""})
  //   else
  //     this.dialogRef.close(this.selectedOption)
  // }

}
