import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountChecklistService } from '../services/account-checklist.service';
import { AccountsService } from '../services/accounts.service';
import { AuthenticationService } from '../services/authentication.service';
import { ChecklistService } from '../services/checklist.service';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';
import { DatePipe } from '@angular/common';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-account-checklist',
  templateUrl: './account-checklist.component.html',
  styleUrls: ['./account-checklist.component.scss']
})
export class AccountChecklistComponent implements OnInit {

  account_checklist: any 
  storedData: any; 
  tempData:any;
  currentUser;
  allUsers;


  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private checklistService: ChecklistService, 
    private accountChecklistService: AccountChecklistService, 
    private accountService: AccountsService,
    private modalService: NgbModal, 
    private router: Router, 
    private dsService: DataSharingService, 
    private dialogService:DialogService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.storedData = this.dsService.currentDataValue
    this.currentUser = this.authenticationService.currentUserValue.user.id[0].id
    this.userService.getAllUsers().subscribe(data=>{
      this.allUsers = data.results
    })

    if(this.storedData.account && this.storedData.account.id){
      this.checklistService.getChecklistbyAccount(this.storedData.account.id).subscribe(data => {
        this.account_checklist = data

        this.tempData = JSON.parse(JSON.stringify(data));

        }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.router.navigate(['/accounts'])
    }
  }

  updateAll(){
    this.checklistService.updateAccountChecklist(this.account_checklist).subscribe(data =>{
      console.log(data)
      this.dialogService.alertDialog({
        title: "Success",
        message: "Saved canges successfully"
      })
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  detectDetailsChange( i ,j, value ) {
    let currentDate = this.datePipe.transform( Date() ,"yyyy-MM-dd")
    let currentUser = this.currentUser
    let oldValue = this.tempData[i].acc_subchecklists[j].details
    let oldUser = this.tempData[i].acc_subchecklists[j].updated_by
    let oldTime = this.tempData[i].acc_subchecklists[j].updated_time
    if( oldValue != '' ) {
      if( oldValue != value ) {
        this.account_checklist[i].acc_subchecklists[j].updated_by = currentUser
        this.account_checklist[i].acc_subchecklists[j].updated_time = currentDate
      } else {
        this.account_checklist[i].acc_subchecklists[j].updated_by = oldUser
        this.account_checklist[i].acc_subchecklists[j].updated_time = oldTime
      }
    } else {
      if( value != '' ) {
        this.account_checklist[i].acc_subchecklists[j].updated_by = currentUser
        this.account_checklist[i].acc_subchecklists[j].updated_time = currentDate
      }
    }
  }

  detectValueChange( i ,j, value ) {
    let currentDate = this.datePipe.transform( Date() ,"yyyy-MM-dd")
    let currentUser = this.currentUser
    let oldValue = this.tempData[i].acc_subchecklists[j].details
    let oldUser = this.tempData[i].acc_subchecklists[j].updated_by
    let oldTime = this.tempData[i].acc_subchecklists[j].updated_time
    if( oldValue != '' ) {
      if( oldValue != value ) {
        this.account_checklist[i].acc_subchecklists[j].updated_by = currentUser
        this.account_checklist[i].acc_subchecklists[j].updated_time = currentDate
      } else {
        this.account_checklist[i].acc_subchecklists[j].updated_by = oldUser
        this.account_checklist[i].acc_subchecklists[j].updated_time = oldTime
      }
    } else {
      if( value != '' ) {
        this.account_checklist[i].acc_subchecklists[j].updated_by = currentUser
        this.account_checklist[i].acc_subchecklists[j].updated_time = currentDate
      }
    }
  }


  installationComplete(){
    this.accountService.installationComplete(this.storedData.account.id).subscribe(data => {
      this.dialogService.alertDialogWithReturn({
        title: "Success",
        message: "Installation marked completed successfully."
      }).then(() => {
        this.router.navigate(['/accounts'])
      })
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }


  // Edit Checklist details Modal

  selectedChecklist: any;
  modalRef: NgbModalRef
  selectedIndex;

  editChecklist(modal, checklist, index){
    console.log("hello")
    this.selectedChecklist = checklist
    this.selectedIndex = index
    this.modalRef = this.modalService.open(modal)  
  }

  saveChecklist(){
    console.log(this.selectedChecklist)
    let updates = {due_date: this.selectedChecklist.due_date, comments: this.selectedChecklist.comments}
    this.accountChecklistService.updateAccountChecklist(this.selectedChecklist.id, updates).subscribe(data => {
      this.account_checklist[this.selectedIndex] = this.selectedChecklist
      this.dialogService.alertDialog({
        title: "Success",
        message: "Updated details of cheklist successfully."
      })
      this.close()
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  close(){
    this.modalRef.close()
  }

  getUserName( id ) {
    let name = '';
    this.allUsers.forEach(user => {
      if( id == user.id ) {
        name = user.name
        // return;
      }
    });

    return name;

  }

}
