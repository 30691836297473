import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DialogService } from 'src/app/services/dialog.service';
import { TrainingsService } from 'src/app/services/trainings.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-hcp2-training',
  templateUrl: './hcp2-training.component.html',
  styleUrls: ['./hcp2-training.component.scss']
})
export class Hcp2TrainingComponent implements OnInit {

  form: any
  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;
  // @Input() form;

  constructor(
    private dsService: DataSharingService,
    private dialogService: DialogService, 
    private router: Router, 
    private trainingsService: TrainingsService
  ) { }

  ngOnInit(): void {
    let storedData = this.dsService.currentDataValue
    if(storedData.account || storedData.hcp){
      this.trainingsService.getHcpTechTrainingbyAccount(storedData.account.id, storedData.hcp.id).subscribe(data => {
       
        if(data.results.length){
          let res = data.results[0];
          this.form = {};
          this.form.id = res.id;
          this.form.overview = res.overview;
          this.form.detail = res.detail;
          this.form.tasks = res.tasks;
          this.form.confirm = res.confirm;
          this.form.approval = res.approval;
          this.signatureImg = this.form.confirm.physician_signature

          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement,{
            onEnd: (event) => {
              console.log('test');
              const base64Data = this.signaturePad.toDataURL();
              this.signatureImg = base64Data;
              this.form.confirm.physician_signature = base64Data;
            }
          });

          if( this.signatureImg !='' && this.signatureImg ) {
            this.signaturePad.fromDataURL( this.signatureImg )
          }
          // this.patchKnownData(storedData)
          // console.log(  )
          // console.log( storedData );
        }
        else {
          this.defaultData()
          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement,{
            onEnd: (event) => {
              const base64Data = this.signaturePad.toDataURL();
              this.signatureImg = base64Data;
              this.form.confirm.physician_signature = base64Data;
            }
          });
          // this.router.navigate(['/account-department-details'])
        }
      }, error =>{
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.defaultData()
      // this.router.navigate(['/account-department-details'])
    }
  }

  patchKnownData(data){
    if(!this.form.tpiq_overview.physician_name)
      this.form.tpiq_overview.physician_name = data.hcp.title + " " + data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.tpiq_overview.hospital)
      this.form.tpiq_overview.hospital = data.account.name
    if(!this.form.tpiq_overview.cellphone)
      this.form.tpiq_overview.cellphone = data.hcp.mobile
    if(!this.form.tpiq_overview.email)
      this.form.tpiq_overview.email = data.hcp.email

    if(!this.form.basic_corpath.physician_name)
      this.form.basic_corpath.physician_name = data.hcp.title + " " + data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.basic_corpath.hospital)
      this.form.basic_corpath.hospital = data.account.name

    if(!this.form.quiz.physician_name)
      this.form.quiz.physician_name = data.hcp.title + " " + data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.quiz.hospital)
      this.form.quiz.hospital = data.account.name

    if(!this.form.post_quiz.physician_name)
      this.form.post_quiz.physician_name = data.hcp.title + " " + data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.post_quiz.hospital)
      this.form.post_quiz.hospital = data.account.name
  }


  defaultData() {
    this.form = {}
    this.form.overview = {};
    this.form.overview.your_name = '';
    this.form.overview.physician_name = '';
    this.form.overview.hospital = '';
    this.form.overview.cellphone = '';
    this.form.overview.office = '';
    this.form.overview.email = '';
    this.form.overview.current_volume = '';
    this.form.overview.program_goals = '';
    this.form.overview.procedure_timeline_1 = '';
    this.form.overview.procedure_timeline_10 = '';
    this.form.overview.procedure_timeline_20 = '';

    this.form.detail = {};
    this.form.detail.hospital_credentialing_requirements = '';
    this.form.detail.days_of_procedures = '';
    this.form.detail.constraints = '';
    this.form.detail.other_notes = '';

    this.form.tasks = {};
    this.form.tasks.system_overview = '';
    this.form.tasks.specific_connections = '';
    this.form.tasks.system_power_up = '';
    this.form.tasks.cockpit_overview = '';
    this.form.tasks.cco = '';
    this.form.tasks.cco_catheter = '';
    this.form.tasks.cco_wire = '';
    this.form.tasks.tiq_movement_overview = '';
    this.form.tasks.cco_techniq_ror = '';
    this.form.tasks.cco_techniq_wiggle = '';
    this.form.tasks.cco_techniq_spin = '';
    this.form.tasks.cco_techniq_dotter = '';
    this.form.tasks.cco_techniq_constant_speed = '';
    this.form.tasks.cco_rx = '';
    this.form.tasks.cco_safety_icons = '';
    this.form.tasks.cco_icons = '';
    this.form.tasks.extended_reach_arm_operation = '';
    this.form.tasks.equipment_needed_for_robotic = '';
    this.form.tasks.prepare_guide = '';
    this.form.tasks.attach_single_use = '';
    this.form.tasks.prepare_single_use = '';
    this.form.tasks.prepare_robotic_drive = '';
    this.form.tasks.position_robotic_drive = '';
    this.form.tasks.load_guide = '';
    this.form.tasks.load_devices = '';
    this.form.tasks.exchange_guide = '';
    this.form.tasks.movement_of_guidewire = '';
    this.form.tasks.movement_of_guide = '';
    this.form.tasks.measurement_feature = '';
    this.form.tasks.system_error_mess = '';
    this.form.tasks.finish_case = '';
    this.form.tasks.troubleshooting = '';
    this.form.tasks.additional_info = '';

    this.form.confirm = {};
    this.form.confirm.physician_name = '';
    this.form.confirm.corindus_trainer = '';
    this.form.confirm.date_of_phase1_completion = '';
    this.form.confirm.confirm_trained = '';
    this.form.confirm.physician_signature = '';

    this.form.approval = {};
    this.form.signature = '';

  }

  saveDraft(){
    if( this.form.id ) {
      this.trainingsService.updateTechHcpTraining(this.form.id, this.form).subscribe(data =>{
        console.log(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "HCP TechIQ Training Form has been saved successfully."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } else {
      let storedData = this.dsService.currentDataValue
      this.form.account = storedData.account.id;
      this.form.hcp = storedData.hcp.id

      this.trainingsService.createTechHcpTraining(this.form).subscribe(data =>{
        this.form.id = data.id;
        this.dialogService.alertDialog({
          title: "Success",
          message: "HCP TechIQ Training Form has been added successfully."
        })
      }, error => {
        console.log( error )
        this.dialogService.errorDialogNew(error)
      })
    }

  }


  complete() {
    this.form.completed = true

    if( this.form.id ) {
      this.trainingsService.updateTechHcpTraining(this.form.id, this.form).subscribe(data =>{
        console.log(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "HCP Non-TechIQ Training Form has been Completed."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } else {
      let storedData = this.dsService.currentDataValue
      this.form.account = storedData.account.id;
      this.form.hcp = storedData.hcp.id

      this.trainingsService.createTechHcpTraining(this.form).subscribe(data =>{
        this.form.id = data.id;
        this.dialogService.alertDialog({
          title: "Success",
          message: "HCP Non-TechIQ Training Form has been Completed"
        })
      }, error => {
        console.log( error )
        this.dialogService.errorDialogNew(error)
      })
    }

  }

  startDrawing(event: Event) {
    console.log(event);
    // works in device not in browser

  }

  moved(event: Event) {
  }

  clearPad() {
    this.signaturePad.clear();
    this.form.confirm.physician_signature = null;
  }

}
