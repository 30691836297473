import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ProcedureService } from 'src/app/services/procedure.service';


@Component({
  selector: 'app-procedures',
  templateUrl: './procedures.component.html',
  styleUrls: ['./procedures.component.scss']
})
export class ProceduresComponent implements OnInit {

  procedures: any
  constructor(private procedureService: ProcedureService, private modalService: NgbModal, private dsService: DataSharingService, private router: Router, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.procedureService.getAllProcedure().subscribe(data => {
      this.procedures = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  getProcedureDetails(procedure){
    let data = this.dsService.currentDataValue
    data.procedure = {id: procedure.id}
    this.dsService.storeData(data)
    this.router.navigate(['/procedure'])
  }





}
