import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Data, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AccountsService } from '../services/accounts.service';
import { DataSharingService } from '../services/data-sharing.service';
import { DepartmentsService } from '../services/departments.service';
import { DialogService } from '../services/dialog.service';
import { HcpService } from '../services/hcp.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  accountId: any;
  account: any;
  hcps;
  storedData: any;
  keyPersons;
  tableType;

  constructor(private hcpService: HcpService, private userService: UserService, private dsService: DataSharingService, private router: Router, private modalService: NgbModal, private departmentService: DepartmentsService, private accountService: AccountsService, private dialogService: DialogService,private fb: FormBuilder) { }

  ngOnInit(): void {
    this.storedData = this.dsService.currentDataValue
    if(this.storedData && this.storedData.account && this.storedData.account.id){
      this.accountId = this.storedData.account.id
      this.accountService.getAccount(this.accountId).subscribe(data => {
        this.account = data
        this.keyPersons = data.key_person
        this.tableType = "edit";
        // console.log(this.account)
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.router.navigate(['/accounts'])
    }
  }

  changeDeptStatus(value, index){
    // console.log(value, index)
    this.account.departments[index].is_active = value
  }

  sendEmail(hcp){
    if(hcp){
      this.hcpService.sendConsentEmail(hcp.id).subscribe(() => {
        this.dialogService.alertDialog({
          title: "Success",
          message: "Email has been sent again successfully."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
  }

  showDepartmentDetails(index){
    this.storedData.department = this.account.departments[index]
    this.dsService.storeData(this.storedData)
    this.router.navigate(['account-department-details'])    
  }

  // Add New Department

  selectedDepartment: any;
  selectedDepartmentsIds: any[] = []
  allDepartments: any[] = []
  modalRef: NgbModalRef

  openDepartmentModal(modal){
    if(!this.allDepartments.length){
      this.departmentService.getAllDepartments().subscribe(data => {
        this.allDepartments = data.results
        this.account.departments.forEach(d => {
          this.selectedDepartmentsIds.push(d.id)
          let index = this.allDepartments.findIndex(alld => alld.id==d.id)
          if(index>-1) this.allDepartments.splice(index, 1)
        })
      })
    }    
    this.modalRef = this.modalService.open(modal)
    this.modalRef.result.then(data => console.log(data), error => console.log(error))
  }

  addDepartment(){
    this.selectedDepartmentsIds.push(this.selectedDepartment.id)
    this.accountService.updateAccount(this.accountId, {departments: this.selectedDepartmentsIds}).subscribe(data => {
      this.modalRef.dismiss()
      this.account.departments.push(this.selectedDepartment)
      this.dialogService.alertDialog({
        title: "Success",
        message: "Successfully added department"
      })
    }, error => {
      this.modalRef.dismiss()
      this.dialogService.errorDialogNew(error)
    })
  }

  closeDepartmentModal(){
    this.selectedDepartment = undefined
    this.modalRef.dismiss()
  }


  // Add New HCP

  modalTitle: string
  action: "view" | "edit" | "add"
  selectedHcp: any
  hcpIndex: any

  openHcpModal(modal){
    this.modalRef = this.modalService.open(modal, {size: "lg"})
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  addHcp(modal){
    this.action = "add";
    this.modalTitle = "Add Hcp"
    this.selectedHcp = undefined;
    this.openHcpModal(modal)
  }

  editHcp(modal, hcp, index){
    this.action = "edit";
    this.modalTitle = "Edit Hcp"
    this.hcpIndex = index;
    this.selectedHcp = hcp;
    this.openHcpModal(modal)
  }

  viewHcp(modal, hcp){
    this.action = "view";
    this.modalTitle = "View Hcp"
    this.selectedHcp = hcp;
    this.openHcpModal(modal)
  }

  saveHcp(hcp){
    console.log(hcp)    
    if(hcp && this.action=="add"){
      this.hcpService.createHcp(hcp).subscribe(data => {
        data.role = hcp.roleObj
        if(data.is_hmt)
          this.account.hcps.push(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Successfully created new HCP"
        })
        this.modalRef.dismiss()
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } 
    if(hcp && this.action=="edit"){
      this.hcpService.updateHcp(this.selectedHcp.id, hcp).subscribe(data => {        
        data.role = hcp.roleObj
        this.hcps[this.hcpIndex] = data
        this.dialogService.alertDialog({
          title: "Success",
          message: "Successfully updated HCP details"
        })
        this.modalRef.dismiss()
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.modalRef.dismiss()
    }
  }


  // Edit Account

  selectedAccount: any

  openAccountModal(modal){
    this.modalRef = this.modalService.open(modal, {size: 'xl'})
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  editAccount(modal){
    this.action = "edit";
    this.modalTitle = "Edit Account"
    this.selectedAccount = this.account;
    this.openAccountModal(modal)
  }

  saveAccount(data){    
    if(data && this.action=="edit"){
      this.accountService.updateAccount(this.selectedAccount.id, data).subscribe(data => {
        this.account = data
        this.dialogService.alertDialog({
          title: "Success",
          message: "Successfully created new Account"
        })
        this.modalRef.dismiss()
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.modalRef.dismiss()
    }
  }

  // Key Person Modal

  users;
  selectedKeyPerson;

  openKeyPersonModal(modal){
    this.getUsers()
    this.modalRef = this.modalService.open(modal)
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      this.updateKeyPersion()
      console.log(cancel);
    });
  }

  getUsers(){
    this.userService.getAllUsers().subscribe(data => {
      this.users = data.results
      this.selectedKeyPerson = this.account.key_person.id
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  closeKeyPersonModal(){
    this.modalRef.dismiss()
  }

  updateKeyPersion() {
    if(this.storedData && this.storedData.account && this.storedData.account.id){
      this.accountId = this.storedData.account.id
      this.accountService.getAccount(this.accountId).subscribe(data => {
        this.keyPersons = data.key_person
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
  }

}
