import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../services/accounts.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  selectedType;
  selectedAccounts;
  fromDate;
  toDate;
  accounts;
  trainingResults;
  angioResults;

  types = [
    {id: "training", name: "Trainings"},
    {id: "angio", name: "Angio Systems"}
  ]

  constructor(private accountService: AccountsService, private titleCase: TitleCasePipe, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.accountService.getAllAccounts().toPromise().then(data => {
      this.accounts = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
    console.log('test auto 322')
  }

  search(){
    if(this.selectedType){
      this.trainingResults = undefined
      this.angioResults = undefined
      
      let selectedAccountsStr = this.selectedAccounts? this.selectedAccounts.toString(): ''
      let fromDateStr = this.fromDate? this.fromDate: ''
      let toDateStr = this.toDate? this.toDate: ''
      
      this.accountService.search(this.selectedType, selectedAccountsStr, fromDateStr, toDateStr).subscribe(data => {

        this._search_render( data );
        if(data){
          if(Array.isArray(data)){
            this.trainingResults = data
          }
          if(this.selectedType == 'angio'){
            let keys = Object.keys(data)
            let myResults = []
            for(let i=0;i<keys.length;i++){
              myResults.push({name: this.titleCase.transform(keys[i].replace('-', '')), value: data[keys[i]], show: false})
            }
            this.angioResults = myResults
          }
        }
        else{
          this.trainingResults = undefined
          this.angioResults = undefined
        }        
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
  }

  _reorder() {
    console.log('test');
  }

  _search_render( data ) {
    console.log( data.sort() );
  }
}
