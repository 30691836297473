<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
		<span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-department-details">Department Details</span>
		  <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					Case Training Form
				</span>
	  </li>
		</ol>   
	</div>
</section>

<div class="form-container hcp-training-form">

  <div>
	<div class="section">
	  <div class="row">
		  <div class="col-md-8">
			  <div class="hero-block__headings">
				  <h1 class="hero-block__title">CORPATH GRX VASCULAR ROBOTIC SYSTEM</h1>
			  </div>
		  </div>
		  <div class="col-md-4 flex-reverse">
			<button class="button display-block btn-sm" routerLink="/account-department-details">Back</button>
		  </div>
	  </div>

	  <div class="heading">
		  <h2>Physician Training Pathway: Phase II</h2>
	  </div>

	  <div class="intro pb-3">
	  	The following document outlines activities required for physicians to complete Phase II training on the CorPath GRX Vascular Robotic System.
	  </div>

	  <div class="row">
			<div class="col-md-6">
				<label>This form will record your name, please fill your name</label>
				<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.your_name}">
					
					<div class="textfield__box">
						<input type="text" class="textfield__input" [(ngModel)]="form?.overview.your_name">
						<label class="textfield__label" for="overview[your_name]">Your Name:</label>
					</div>
					<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
				</div>
			</div>
		</div>

		<div class="subheading">
			<h3>Phase II Training Pathway Overview</h3>
		</div>

		<p>The following document outlines activities required for physicians to complete Phase II training on the CorPath GRX</p>
		<p>Vascular Robotic System.</p>
	  
	  <div class="row form-group">
			  <label class="col-md-2">Physician Name:</label>
			  <div class="col-md-4">
				  <input type="text" name="overview[physician_name]" [(ngModel)]="form?.overview.physician_name" class="form-control">
			  </div>
			  <label class="col-md-2">Hospital:</label>
			  <div class="col-md-4">
				  <input type="text" name="overview[hospital]" [(ngModel)]="form?.overview.hospital" class="form-control">
			  </div>
	  </div>

	  <div class="row form-group">
			  <label class="col-md-2">Cell Phone #:</label>
			  <div class="col-md-4">
				  <input type="text" name="overview[cellphone]" [(ngModel)]="form?.overview.cellphone" class="form-control">
			  </div>
			  <label class="col-md-2">Office #:</label>
			  <div class="col-md-4">
				  <input type="text" name="overview[office]" [(ngModel)]="form?.overview.office" class="form-control">
			  </div>
	  </div>

	  <div class="row form-group">
			  <label class="col-md-2">Email Address:</label>
			  <div class="col-md-10">
				  <input type="text" name="overview[email]" [(ngModel)]="form?.overview.email" class="form-control">
			  </div>
	  </div>
	</div>


	<div class="section">
	  <div class="subheading">
		Physician Training Pathway Summary
	  </div>

	  <div class="entry-content">
		  <p>Corindus will administer a training and education program developed for the CorPath GRX Vascular Robotic System.Prior to using the System, physicians must attend and satisfactorily complete the following <strong>Phase 1 training</strong> elements:</p>
		  <ul>
			  <li><strong>A.</strong> Attend the didactic portion of the training, presented by a member of the Corindus clinical staff.</li>
			  <li><strong>B.</strong> Perform hands-on training on either a demonstration System or a bench-top model, with verbal guidance and evaluation from Corindus clinical staff.</li>
		  </ul>
		  <p>Physicians will be issued a certificate of completion of <strong>Basic CorPath Physician Training</strong> upon successful completion of <strong>Phase I</strong>. Corindus will only support procedural use of the CorPath GRX System after a physician completes the Phase I training program.</p>
		  <p>Additionally, Corindus will observe an initial series of 5 cases with each trained physician, and conduct a comprehensive review, constituting <strong>Phase II</strong> of the program. Physicians will be issued a certificate of completion of <strong>Clinical CorPath Physician Training</strong> upon completion of Phase II. </p>
		  <p>A checklist will be utilized by Corindus clinical staff to ensure proficiency. The competency assessment will be maintained at Corindus. Repetition of the above tasks may be necessary until the physician user shows competence with all requirements. Non-physician staff will be encouraged to participate in the training sessions with physicians.</p>
	  </div>
	</div>

		  
	  <table class="table">
		  <tr class="heading">
			  <td></td>
			  <td>Date</td>
			  <td>Procedure</td>
			  <td>Case Comments</td>
		  </tr>
		  <tr>
			  <td>1st CorPath Procedure </td>
			  <td>
				  <input type="date" name="summary[case_1][date]" [(ngModel)]="form?.summary?.case_1.date" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_1][procedure]" [(ngModel)]="form?.summary?.case_1.procedure" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_1][comment]" [(ngModel)]="form?.summary?.case_1.comments" class="form-control">
			  </td>
		  </tr>
		  <tr>
			  <td>2nd CorPath Procedure </td>
			  <td>
				  <input type="date" name="summary[case_2][date]" [(ngModel)]="form?.summary?.case_2.date" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_2][procedure]" [(ngModel)]="form?.summary?.case_2.procedure" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_2][comment]" [(ngModel)]="form?.summary?.case_2.comments" class="form-control">
			  </td>
		  </tr>
		  <tr>
			  <td>3rd CorPath Procedure </td>
			  <td>
				  <input type="date" name="summary[case_3][date]" [(ngModel)]="form?.summary?.case_3.date" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_3][procedure]" [(ngModel)]="form?.summary?.case_3.procedure" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_3][comment]" [(ngModel)]="form?.summary?.case_3.comments" class="form-control">
			  </td>
		  </tr>
		  <tr>
			  <td>4th CorPath Procedure </td>
			  <td>
				  <input type="date" name="summary[case_4][date]" [(ngModel)]="form?.summary?.case_4.date" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_4][procedure]" [(ngModel)]="form?.summary?.case_4.procedure" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_4][comment]" [(ngModel)]="form?.summary?.case_4.comments" class="form-control">
			  </td>
		  </tr>
		  <tr>
			  <td>5th CorPath Procedure </td>
			  <td>
				  <input type="date" name="summary[case_5][date]" [(ngModel)]="form?.summary?.case_5.date" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_5][procedure]" [(ngModel)]="form?.summary?.case_5.procedure" class="form-control">
			  </td>
			  <td>
				  <input type="text" name="summary[case_5][comment]" [(ngModel)]="form?.summary?.case_5.comments" class="form-control">
			  </td>
		  </tr>
	  </table>

	  <div class="row">
		<div class="col-md-12">
			<div>
				<div>
				  <label>Corindus, A siemens Healthineers Company is proud to certify that ( enter physicians name below) has successfully completed training on CorPath GRX Vascular Robotic system *</label>
				  <span>
					  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.physician_name}">
						  <div class="textfield__box">
							  <input type="text" id="confirm[physician_name]" name="confirm[physician_name]" class="textfield__input" [(ngModel)]="form?.confirm.physician_name">
							  <label class="textfield__label" for="confirm[physician_name]"></label>
						  </div>
						  <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
					  </div>
				  </span>
				</div>      
			</div>
		</div>
		<div class="col-md-12">
			<div>
				<div>
					<span>
						<div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.corindus_trainer}">
							<div class="textfield__box">
								<input type="text" id="confirm[corindus_trainer]" name="confirm[corindus_trainer]" class="textfield__input" [(ngModel)]="form?.confirm.corindus_trainer">
								<label class="textfield__label" for="confirm[corindus_trainer]">Corindus Trainer Name:*</label>
							</div>
							<small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
						</div>
					</span>
				</div>      
			</div>
		</div>
		  <div class="col-md-12">
			  <div>
				  <div>
					  <span>
						  <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.confirm.date_of_phase1_completion}">
							  <div class="textfield__box">
								  <input type="text" id="confirm[date_of_phase1_completion]" name="confirm[date_of_phase1_completion]" class="textfield__input" [(ngModel)]="form?.confirm.date_of_phase1_completion">
								  <label class="textfield__label" for="confirm[date_of_phase1_completion]">Date of Phase II Completion:*</label>
							  </div>
							  <small class="textfield__hint display-flex"><span class="col-10 padding-0">Format: M/d/yyyy</span></small>
						  </div>
					  </span>
				  </div>      
			  </div>
		  </div>

		  <div class="col-md-12">
			<div>
				<div>
				  <label>I hereby confirms that they have been trained on this system, that I am able to access translated CorPath GRX Operator Manuals at<br><a href="https://www.corindus.com/operatorsmanual">https://www.corindus.com/operatorsmanual</a>, and that they are professionally capable of operating the system with the Graphic User Interface( GUI ) touchscreen</label>
				  <input type="radio"  [(ngModel)]="form?.confirm.confirm_trained" name="confirm[confirm_trained]" value="yes" class="form-control"><label>Yes</label>
				  <input type="radio" [(ngModel)]="form?.confirm.confirm_trained" name="confirm[confirm_trained]" value="no" class="form-control"><label>No</label>
				</div>      
			</div>
		</div>

		<div class="col-md-12">
			<div>
				<div>
				  <label>The training described herein is strictly on the use of the CorPath GRX Vascular Robotic System and not the procedure being performed. This program does not replace any specific hospital or physician requirements necessary to perform procedures.This document is strictly private, confidential and personal to its recipients and should not be copied, distributed or reprodiced in the whole or in part, nor passed to any third party*</label>
				</div>      
			</div>
		</div>
		

		<div class="col-md-12">
			<div>
				<div>
					<span>
						<div>
							<label class="" for="confirm[physician_signature]">Physician Signature:</label>
						</div>
						<div class="canvas-holder">
							<canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>

							<button class="sgn-button" (click)="clearPad()">Clear</button>
						</div>
					</span>
				</div> 
			</div>
		</div>
	  </div>

	  <div class="heading">
		HEADQUARTERS APPROVAL: Name, Signature, Date: 
	</div>

	<div class="row">
		<div class="col-md-12">
			<div>
				<div>
					<span>
						<div class="textarea w-100" [ngClass]="{'is-active': form?.approval.signature}">
						  <div class="textarea__container">
							  <textarea name="approval[signature]" class="textarea__input" row="2" [(ngModel)]="form?.approval.signature"></textarea>
							  <label class="textarea__label" for="approval[signature]"></label>
						  </div>
						  <small class="textarea__hint display-flex"><span class="col-10 padding-0"></span></small>
					  </div>
					</span>
				</div>      
			</div>
		</div>
	</div>

	</div>


	<div class="row btn-row submit-form">
		<button class="button button--primary display-block" (click)="saveDraft()">Save draft</button>
	</div>
  </div>
  
  <div class="row btn-row complete-form">
	<button class="button button--primary display-block" (click)="complete()">Complete</button>
  </div>
