<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
          <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					Department Details
				</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row align-center">
	<div class="col-lg-4 col-md-7">
		<div class="hero-block__headings">
			<h1 class="hero-block__title">Department Details</h1>
		</div>
	</div>

  <div class="col-lg-8 col-md-5 flex-reverse">
		<div>
			<button class="button display-block btn-sm" routerLink="/account-details">Back</button>
		</div>
  </div>
</div>  

<div class="row">
  <div class="col-md-12 general-info">
    <div class="table-responsive">
      <table class="table table-features">
        <tbody>
          <tr>
            <th>Account Details</th>
            <td>{{account?.name}}</td>
          </tr>
          <tr>
            <th>Department Name</th>
            <td>{{department?.name}}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td *ngIf="department?.is_active; else inactive">
              <span class="d-flex">Active&nbsp;<i class="pointer material-icons text-success">thumb_up</i></span>
            </td>
            <ng-template #inactive>
              <td>
                <span class="d-flex">InActive&nbsp;<i class="pointer material-icons text-danger">thumb_down</i></span>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="clearfix" style="height:15px;"></div>

<div class="row">
  <div class="col-md-12">
    <div class="hero-block__headings">
      <a (click)="addHcp(hcpModal)" class="hd-icon icon icon--primary pointer" title="Add New User">
        <i class="icon-plus"></i>
      </a>
      <h3 class="aw-h1 hero-block__title">Health Care Professionals for {{department?.name}}</h3>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="table-responsive">
      <table class="table table-features table-long">
        <thead>
          <tr>
            <th>Role </th>
            <th>Name</th>
            <th>E-mail</th>
            <th>Phone</th>
            <th>Consent Status </th>
            <th>Sent Email(s)</th>
            <th>Status</th>
            <!-- <th>Hcp Role</th> -->
            <th colspan="2" style="text-align: center;">Trainings</th>
            <!-- <th>Case Training</th> -->
            <!-- <th>Staff Training</th> -->
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          
          <tr *ngFor="let hcp of hcps; index as index">
            <td>{{ hcp.role?.name}}</td>
            <td>{{ hcp.title }} {{ hcp.first_name }} {{ hcp.last_name }} </td>
            <td>{{ hcp.email }} </td>
            <td>{{ hcp.mobile }} </td>
            <td>
              <p *ngIf="hcp.email_sent && hcp.agreement" class="text-success">Consent Received</p>
              <p *ngIf="hcp.email_sent && !hcp.agreement" ngbTooltip="Send Email Again" class="text-warning d-flex underline pointer" (click)="sendEmail(hcp)">
                Awaiting&nbsp;&nbsp;<i class="material-icons">forward_to_inbox</i>
              </p>
              <p *ngIf="!hcp.email_sent" class="text-danger d-flex underline pointer"  ngbTooltip="Send Email Again" (click)="sendEmail(hcp)">
                Email Not Sent&nbsp;&nbsp;<i class="material-icons">forward_to_inbox</i>
              </p>
            </td>
            <td class="pointer underline current-user" (click)="openEmailLogsModal(hcp, emailLogsModal)"><p ngbTooltip="Click to view email Logs">{{hcp.email_log?.length}}</p></td>
            <td>
              <i *ngIf="hcp.is_active" ngbTooltip="Update Status" (click)="changeStatus(false, index)" class="pointer material-icons text-success">thumb_up</i>
              <i *ngIf="!hcp.is_active" ngbTooltip="Update Status" (click)="changeStatus(true, index)" class="pointer material-icons text-danger">thumb_down</i>
            </td>
            <!-- <td>{{ hcp.role.name }}</td> -->
            
            <td colspan="2" class="text-center">
              <app-training-form-selector [data]="hcp"></app-training-form-selector>
              
            </td>
            <td class="d-flex">
              <i ngbTooltip="Edit" (click)="editHcp(hcpModal, hcp, index)" class="material-icons current-user pointer pr-3">edit</i>
              <i ngbTooltip="View" (click)="viewHcp(hcpModal, hcp)" class="material-icons current-user pointer">preview</i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>	
  </div>
</div>

<ng-template #emailLogsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Emails Log</h4>
		<button type="button" class="close" (click)="closeEmailLogsModal()"><span aria-hidden="true">&times;</span></button>        
	</div>
	<div class="modal-body">
		<div class="form-group row" *ngFor="let log of selectedHcp.email_log; index as index">
			<label for="name" class="col-md-12"><strong>{{index+1}}.</strong> {{log.email_timestamp | date:'medium'}}</label>
		</div>
	</div>
</ng-template>

<ng-template #hcpModal let-modal>
	<app-hcp [modalTitle]="modalTitle" [action]="action" [data]="selectedHcp" (sendHcpForm)="saveHcp($event)"></app-hcp>
</ng-template>