import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {

  @Input() title: string;
  @Input() action: string;
  @Input() data;
  @Output() sendDepartmentForm = new EventEmitter<string>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if(this.data)
      this.departmentForm.patchValue(this.data)
  }  

  departmentForm = this.fb.group({
    name: ['', Validators.required]
  })

  get name() {return this.departmentForm.get('name')}

  submit(){
    this.sendDepartmentForm.emit(this.departmentForm.value);
  }

  close(){
    this.sendDepartmentForm.emit()
    this.departmentForm.reset();
  }

}
