<section class="mt-1 mb-3" data-ste-chapter="breadcrumbs">
	<div class="container">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;"></li>
		</ol>   
	</div>
</section>

<div class="container">
  <div class="row">
      <div class="col-md-8 offset-md-2">
          <div class="panel panel-default">
              <!-- <div class="panel-heading">Reset Password</div> -->

              <div class="panel-body">
                  <form class="form-horizontal" [formGroup]="resetForm">
                      <!-- {{ csrf_field() }} -->

                      <!-- <input type="hidden" name="token" value="{{ $token }}"> -->

                      <!-- <div class="form-group{{ $errors->has('email') ? ' has-error' : '' }}">
                          <label for="email" class="col-md-4 control-label">E-Mail Address</label>

                          <div class="col-md-6">
                              <input id="email" type="email" class="form-control" name="email" value="{{ $email or old('email') }}" required autofocus>

                              @if ($errors->has('email'))
                                  <span class="help-block">
                                      <strong>{{ $errors->first('email') }}</strong>
                                  </span>
                              @endif
                          </div>
                      </div> -->

                      <div class="form-group row">
                        <label for="password" class="col-md-2">Password</label>
                        <div class="col-md-10" [ngClass]="{'was-validated': (password.dirty || password.touched)}">
                          <input class="form-control" type="password" id="password" formControlName="password" minlength="8" required>
                          <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                            <div *ngIf="password.errors.required">
                              <strong>This is required</strong>
                            </div>
                            <div *ngIf="password.errors.minlength">
                              <strong>Min length should be 8 characters.</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label for="password2" class="col-md-2">Confirm Password</label>
                        <div class="col-md-10" [ngClass]="{'was-validated': (password2.dirty || password2.touched)}">
                          <input class="form-control" type="password" id="password2" formControlName="password2" minlength="8" required>
                          <div *ngIf="password2.invalid && (password2.dirty || password2.touched)" class="invalid-feedback">
                            <div *ngIf="password2.errors.required">
                              <strong>This is required</strong>
                            </div>
                            <div *ngIf="password2.errors.minlength">
                              <strong>Min length should be 8 characters.</strong>
                            </div>   
                            <div *ngIf="password2.errors.notMatch">
                              <strong>Passwords do not match.</strong>
                            </div>                         
                          </div>
                        </div>
                      </div>

                      <div class="row form-group my-invalid-feedback offset-4 col-4" *ngIf="(password2.dirty || password2.touched) && password2.errors?.notMatch">
                        <div *ngIf="password2.errors.notMatch">
                          <strong>Passwords do not match.</strong>
                        </div>
                      </div>

                      <!-- <div class="form-group" [ngClass]="{'has-error': (password.dirty || password.touched)}">
                          <label for="password" class="col-md-4 control-label">Password</label>

                          <div class="col-md-6">
                              <input id="password" type="password" class="form-control" name="password" formControlName="password" required minlength="8">

                                  <span class="help-block" *ngIf="password.invalid && (password.dirty || password.touched)">
                                      <strong>Correct Password</strong>
                                  </span>
                          </div>
                      </div> -->

                      <!-- <div class="form-group{{ $errors->has('password_confirmation') ? ' has-error' : '' }}">
                          <label for="password-confirm" class="col-md-4 control-label">Confirm Password</label>
                          <div class="col-md-6">
                              <input id="password-confirm" type="password" class="form-control" name="password_confirmation" required>

                              @if ($errors->has('password_confirmation'))
                                  <span class="help-block">
                                      <strong>{{ $errors->first('password_confirmation') }}</strong>
                                  </span>
                              @endif
                          </div>
                      </div> -->

                      <div class="form-group">
                          <div class="offset-md-4 col-md-4">
                              <button type="submit" class="button button--primary display-block" (click)="resetPassword()" [disabled]="!resetForm.valid || !passwordsMatch" >Reset Password</button>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>