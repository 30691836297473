<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					Account Details
				</span>
      </li>
		</ol>   
	</div>
</section>


<div class="row align-center">
	<div class="col-lg-4 col-md-7">
		<div class="hero-block__headings">
			<h1 class="hero-block__title">{{account?.name}}</h1>
		</div>
	</div>
	<div class="col-lg-8 col-md-5 flex-reverse">
		<div>
			<button class="button display-block btn-sm" routerLink="/accounts">Back</button>
		</div>
		<div>
			<button class="button button--primary display-block btn-sm" (click)="editAccount(accountModal)">Edit</button>
		</div>
		<div>
			<button class="button button--primary display-block btn-sm" routerLink="/account-checklist">Checklist</button>			
		</div>
		<div>
			<button class="button button--primary display-block btn-sm" routerLink="/account-site-visit">Site Visit</button>
		</div>
	</div>
</div>

<div >
	<div class="row">
		<div class="col-md-12 general-info">
			<div class="table-responsive">
				<table class="table table-features">
					<tbody>
						<tr>
							<td style="width:70%;"> Key Person </td>
							<td class="d-flex">  
								<p color="#FF0000">{{account?.key_person? account.key_person.name : 'Not Yet Added'}}</p>
								<button class="button button--primary display-block btn-sm btn-height ml-3" (click)="openKeyPersonModal(keyPersonModal)"> {{account?.key_person? 'Edit' : 'Add'}} Key Person</button>
							</td>
						</tr>
						<tr>
							<td style="width:70%;"> General Contact Detail </td>
							<td>
								Address: {{ account?.address }}
								<br> {{ account?.country?.name }}

								<br><br>Email: {{ account?.email }}
								<br>Telephone: {{ account?.phone }}
								<br>Web: {{ account?.web }}
							</td>
						</tr>
						<tr>
							<td style="width:70%;">Delivery Date</td>
							<td>{{account?.delivery_date}}</td>
						</tr>
						<tr>
							<td style="width:70%;">Installation Date</td>
							<td>{{account?.installation_date}}</td>
						</tr>
						<tr>
							<td style="width:70%;">Inservice Date</td>
							<td>{{account?.inservice_date}}</td>
						</tr>
					</tbody>
					<tbody class="extra-info">
						<tr>
							<td> Region: </td>
							<td>{{account?.region.name}}</td>
						</tr><tr>
							<td> Zone: </td>
							<td>{{account?.zone?.name}}</td>
						</tr>
						<tr>
							<td> Country: </td>
							<td>{{account?.country?.name}}</td>
						</tr>
						<tr>
							<td> Hospital Name </td>
							<td> {{ account?.name }} </td>
						</tr>
						<tr>
							<td> Status </td>
							<td [style.color]="account?.is_active? '#8fdf82': '#FF0000'"><strong>{{account?.is_active}}</strong></td>
						</tr>
						<tr>
							<td> Number of ORs in the Hospital</td>
							<td> {{ account?.num_of_ors }} </td>
						</tr>
						<tr>
							<td> How many of ORs are expected to be einstein compatible <br> (square meters space, maximum height, maximum weight per square meter, etc)</td>
							<td> {{ account?.compatible_ors }} </td>
						</tr>
						<tr>
							<td> Does the hospital currently have a robotic program? if yes, please specify</td>
							<td> {{ account?.robotic_program }} </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="clearfix" style="height:15px;"></div>

	<div class="row">
	<div class="col-md-12">
			<div class="hero-block__headings">
				<a (click)="openDepartmentModal(addDepartmentModal)" class="hd-icon icon icon--primary pointer" title="Add New Department">
					<i class="icon-plus"></i>
				</a>
				<h1 class="aw-h1 hero-block__title">Departments</h1>
			</div>
		</div>
	</div>

	<div class="row">
	<div class="col-md-12">
		<div style="width:100%;" class="table-responsive">
			<table class="table table-features surgery">
				<thead>
					<tr>
						<th>Name</th><th>Status</th><th>Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let d of account?.departments; index as index">						
						<td>{{ d.name }}</td>
						<td>    
							<i *ngIf="d.is_active" ngbTooltip="Update Status" (click)="changeDeptStatus(false, index)" class="pointer material-icons text-success">thumb_up</i>
							<i *ngIf="!d.is_active" ngbTooltip="Update Status" (click)="changeDeptStatus(true, index)" class="pointer material-icons text-danger">thumb_down</i>
						<td>
							<button (click)="showDepartmentDetails(index)" class="button button--primary display-block btn-sm">View</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
</div>

<div>
	<div class="row">
		<div class="col-md-12">
			<div class="hero-block__headings">
				<a (click)="addHcp(hcpModal)" class="hd-icon icon icon--primary pointer" title="Add New User">
					<i class="icon-plus"></i>
				</a>
				<h1 class="aw-h1 hero-block__title">Hospital Management Team</h1>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="table-responsive">
				<table class="table table-features">
					<thead>
						<tr>
							<th>Role</th><th>Team member</th><th>Email</th><th>Consent Status</th><th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let hcp of account?.hcps; index as index">
							<td><i>{{hcp.role.name}}</i></td>
							<td> 
								{{ hcp.title }} {{ hcp.first_name }} {{ hcp.last_name }}
							</td>
							<td>{{ hcp.email }}</td>
							<td class="emmail-consent">
								<p *ngIf="hcp.email_sent && hcp.agreement" class="text-success">Consent Received</p>
								<p *ngIf="hcp.email_sent && !hcp.agreement" ngbTooltip="Send Email Again" class="text-warning d-flex underline pointer" (click)="sendEmail(hcp)">
									Awaiting&nbsp;&nbsp;<i class="material-icons">forward_to_inbox</i>
								</p>
								<p *ngIf="!hcp.email_sent" class="text-danger d-flex underline pointer"  ngbTooltip="Send Email Again" (click)="sendEmail(hcp)">
									Email Not Sent&nbsp;&nbsp;<i class="material-icons">forward_to_inbox</i>
								</p>
								<!-- {{hcp.email_sent? hcp.agreement? "Consent Received": "Email Sent, Waiting for Consent": "Email Not Sent"}} -->
							</td>
							<td class="d-flex">
								<i ngbTooltip="Edit" (click)="editHcp(hcpModal, hcp, index)" class="material-icons current-user pointer pr-3">edit</i>
								<i ngbTooltip="View" (click)="viewHcp(hcpModal, hcp)" class="material-icons current-user pointer">preview</i>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
		</div>
	</div>
</div>


<!-- Modals -->


<ng-template #keyPersonModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Add/Edit Key Person</h4>
		<button type="button" class="close" (click)="closeKeyPersonModal()"><span aria-hidden="true">&times;</span></button>        
	</div>

	<div class="modal-body">
		<app-key-person-table [persons]="keyPersons" [type]="tableType"></app-key-person-table>
	</div>
</ng-template>

<ng-template #addDepartmentModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Add Department</h4>
		<button type="button" class="close" (click)="closeDepartmentModal()"><span aria-hidden="true">&times;</span></button>        
	</div>

	<div class="modal-body">
		<div class="form-group row">
			<label for="name" class="col-md-4">Name</label>
			<div class="col-md-8">
				<select class="form-control" [(ngModel)]="selectedDepartment" required>
					<option value="">Select an option</option>
					<option *ngFor="let department of allDepartments" [ngValue]="department">{{department.name}}</option>
				</select>
			</div>
		</div>

		<div class="form-group">
			<div class="offset-md-4 col-md-4">
				<button type="submit" (click)="addDepartment()" class="button button--primary display-block" [disabled]="!selectedDepartment" >Add & Save</button>
			</div>
			</div>
	</div>
</ng-template>

<ng-template #hcpModal let-modal>
	<app-hcp [modalTitle]="modalTitle" [action]="action" [data]="selectedHcp" (sendHcpForm)="saveHcp($event)"></app-hcp>
</ng-template>

<ng-template #accountModal let-modal>
	<app-account [modalTitle]="modalTitle" [action]="action" [data]="selectedAccount" (sendAccountForm)="saveAccount($event)"></app-account>
</ng-template>