<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
          <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					Site Visit
				</span>
      </li>
		</ol>   
	</div>
</section>

<div class="row align-center">
	<div class="col-lg-4 col-md-7">
		<div class="hero-block__headings">
			<h1 class="hero-block__title">Site Visit</h1>
		</div>
	</div>
  <div class="col-lg-8 col-md-5 flex-reverse">
		<div>
			<button class="button display-block btn-sm" routerLink="/account-details">Back</button>
		</div>
    <div>
			<button class="button button--primary display-block btn-sm" routerLink="/account-checklist">Account Checklist</button>
		</div>
  </div>
</div>  

<div class="inner" *ngIf="siteVisit">
    <div class="infomation-table">
        <div class="table-section">
            <div class="table-title">
                <h3>1.0 Site Information</h3>
            </div>
            <div class="table-container">
                <table class="fifth">
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Date of Site Visit</div>
                        </td>
                        <td><input name="site_information[date]" type="date" [(ngModel)]="siteVisit?.site_information.date"></td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Account Name</div>
                        </td>
                        <td><input name="site_information[name]" type="text" [(ngModel)]="siteVisit?.site_information.name"></td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Site Address</div>
                        </td>
                        <td>
                            <input name="site_information[address]" type="text" [(ngModel)]="siteVisit?.site_information.address">
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Shipping-Receiving Dock hours</div>
                        </td>
                        <td><input name="site_information[dock_hours]" type="text" [(ngModel)]="siteVisit?.site_information.dock_hours"></td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Is a delivery appointment is required?</div>
                            <div class="desc">if Yes, receiving dock contact is required below</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input type="radio" name="site_information[delivery_rq]" [checked]="siteVisit?.site_information?.delivery_rq=='no'" [(ngModel)]="siteVisit?.site_information.delivery_rq" value="no">
                                <label>No</label>
                            </div>
                            <div class="rd-option">
                                <input type="radio" name="site_information[delivery_rq]" [checked]="siteVisit?.site_information?.delivery_rq=='yes'" [(ngModel)]="siteVisit?.site_information.delivery_rq" value="yes">
                                <label>Yes</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Shipping-Receiving Dock Address</div>
                        </td>
                        <td>
                            <div class="tb-option">
                                <input name="site_information[dock_address]" type="checkbox" [(ngModel)]="siteVisit?.site_information.dock_address">
                                <label>Same as site</label>
                            </div>    
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Delivery truck restrictions</div>
                            <div class="desc">Standard truck is 53' with no lift gate</div>
                        </td>
                        <td><input name="site_information[truck]" type="text" [(ngModel)]="siteVisit?.site_information.truck"></td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="table-section">
            <div class="table-title">
                <h3>2.0 Site Contacts</h3>
            </div>
            <div class="table-container">
                <table class="site-contact-table">
                  <tr>
                    <td class="table-heading">
                      <div class="hd">Role</div>
                    </td>
                    <td class="table-heading">
                      <div class="hd">Name</div>
                    </td>
                    <td class="table-heading">
                      <div class="hd">Phone</div>
                    </td>
                    <td class="table-heading">
                      <div class="hd">Email</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr class="site-row" *ngFor="let contact of siteVisit?.site_contacts; index as index">
                    <td class="table-heading"><div class="sub-hd">{{contact?.role?.name}}</div></td>
                    <td>{{contact.title}} {{contact.first_name}} {{contact.last_name}}</td>
                    <td>{{contact.mobile}}</td>
                    <td>{{contact.email}}</td>
                    <td><a class="remove-hcp" (click)="removeHcp(contact,index)"><i class="material-icons">close</i></a></td>
                  </tr>
                </table>
                <div class="contacts-add-row">
                  <select id="all-hcps" class="form-control col-md-6" [(ngModel)]="selectedHcp">
                    <option *ngFor="let hcp of hcps" [ngValue]="hcp">
                      {{hcp.title}} {{hcp.first_name}} {{hcp.last_name}} - {{ hcp.role?.name }}
                    </option>                        
                  </select>
                  <a class="button btn-sm" id="add-site-contact-row" (click)="addHcp()">Add Hcp</a>
                </div>
                <div class="contacts-notes">
                    Notes:<br>
                    <textarea rows=4 name="notes" [(ngModel)]="siteVisit.notes">{{ siteVisit?.notes}}</textarea>
                </div>
                <!-- <div class="contacts-attachment">
                    Attachments:</br>
                    <input type="file" name="attachments" multiple>
                </div> -->
            </div>
        </div>

        <div class="table-section">
            <div class="table-title">
               <h3>3.0 Target Room Information</h3>
            </div>
            <div class="table-container">
                <table class="fifth">
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Targeted Installation Room</div>
                        </td>
                        <td><input name="target_room_info[targeted]" type="text" [(ngModel)]="siteVisit?.target_room_info.targeted"></td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Angio System</div>
                            <div class="desc">List manufacturer, model and configuration (e.g.monoplane or biplane). List Imaging System ID (GE) or Functional Location (Siemens).</div>
                        </td>
                        <td class >
                            <div class="col-md-6 col-sm-12">
                                <div class="cb-option">
                                    <input type="checkbox" value="philips" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('philips')" (click)="onChangeCheckbox('philips', 'target_room_info','fluoroscopy')">
                                    <label>Philips</label>
                                </div>
                                <div class="cb-option">
                                  <input type="checkbox" value="ge" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('ge')" (click)="onChangeCheckbox('ge', 'target_room_info','fluoroscopy')">
                                    <label>GE</label>
                                </div>
                                <div class="cb-option">
                                  <input type="checkbox" value="siemens" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('siemens')" (click)="onChangeCheckbox('siemens', 'target_room_info','fluoroscopy')">
                                    <label>Siemens</label>
                                </div>
                                <div class="cb-option">
                                  <input type="checkbox" value="toshiba" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('toshiba')" (click)="onChangeCheckbox('toshiba', 'target_room_info','fluoroscopy')">
                                    <label>Toshiba</label>
                                </div>
                                <div class="cb-option">
                                  <input type="checkbox" value="other" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('other')" (click)="onChangeCheckbox('other', 'target_room_info','fluoroscopy')">
                                    <label>Other</label>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="cb-option">
                                  <input type="checkbox" value="digital-fluoro" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('digital-fluoro')" (click)="onChangeCheckbox('digital-fluoro', 'target_room_info','fluoroscopy')">
                                    <label>Digital Fluoro</label>
                                </div>
                                <div class="cb-option">
                                  <input type="checkbox" value="analog-fluoro" [checked]="siteVisit?.target_room_info?.fluoroscopy?.includes('analog-fluoro')" (click)="onChangeCheckbox('analog-fluoro', 'target_room_info','fluoroscopy')">
                                    <label>Analog Fluoro</label>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Third party imaging system</div>
                            <div class="desc">e.g. Karl Storz, Perkins</div>
                        </td>
                        <td>
                            <div class="cb-option">
                              <input type="checkbox" value="true" [checked]="siteVisit?.target_room_info?.imaging_system=='true'" [(ngModel)]="siteVisit.target_room_info.imaging_system">
                                <label>N/A</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Footswitch</div>
                            <div class="desc">Model Number, Connector Details</div>
                        </td>
                        <td><input name="target_room_info[footswitch]" type="text" [(ngModel)]="siteVisit?.target_room_info.footswitch"></td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Angio System Video Screen Arrangement</div>
                            <div class="desc">Single screen, six‐panel screen, supplemental pedestal video screen</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="target_room_info[screen]" type="radio" [checked]="siteVisit?.target_room_info?.screen=='single'" [(ngModel)]="siteVisit?.target_room_info.screen" value="single">
                                <label>Single, large monitor</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[screen]" type="radio" [checked]="siteVisit?.target_room_info?.screen=='multi'" [(ngModel)]="siteVisit?.target_room_info.screen" value="multi">
                                <label>Multi‐panel, small monitor</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Hemo System</div>
                            <div class="desc">List manufacturer and model</div>
                        </td>
                        <td><input name="target_room_info[hemo]" type="text" [(ngModel)]="siteVisit?.target_room_info.hemo"></td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Does facility have requirements for conduit to be installed for the routing of plenum signal cables?</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="target_room_info[signal_cable]" type="radio" [checked]="siteVisit?.target_room_info?.signal_cable=='ceiling'" [(ngModel)]="siteVisit?.target_room_info.signal_cable" value="ceiling">
                                <label>Ceiling and wall</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[signal_cable]" type="radio" [checked]="siteVisit?.target_room_info?.signal_cable=='wall'" [(ngModel)]="siteVisit?.target_room_info.signal_cable" value="wall">
                                <label>Wall only</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[signal_cable]" type="radio" [checked]="siteVisit?.target_room_info?.signal_cable=='none'" [(ngModel)]="siteVisit?.target_room_info.signal_cable" value="none">
                                <label>None</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Requirements/options for routing video cables in the cath lab</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="target_room_info[video_cable]" type="radio" [checked]="siteVisit?.target_room_info?.video_cable=='external'" [(ngModel)]="siteVisit?.target_room_info.video_cable"  value="external">
                                <label>External to walls (inside protective covers)</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[video_cable]" type="radio" [checked]="siteVisit?.target_room_info?.video_cable=='internal'" [(ngModel)]="siteVisit?.target_room_info.video_cable" value="internal">
                                <label>Inside walls</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Power for Arm/Robotic Drive</div>
                            <div class="desc">Is there hospital main power available at the bed?</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="target_room_info[drive]" type="radio" [checked]="siteVisit?.target_room_info?.drive=='yes'" [(ngModel)]="siteVisit?.target_room_info.drive" value="yes">
                                <label>Yes</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[drive]" type="radio" [checked]="siteVisit?.target_room_info?.drive=='no'" [(ngModel)]="siteVisit?.target_room_info.drive" value="no">
                                <label>No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Power for Cockpit</div>
                            <div class="desc">Is there hospital main power available within 14 ft the cockpit location?</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="target_room_info[cockpit]" type="radio" [checked]="siteVisit?.target_room_info?.cockpit=='yes'" [(ngModel)]="siteVisit?.target_room_info.cockpit" value="yes">
                                <label>Yes</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[cockpit]" type="radio" [checked]="siteVisit?.target_room_info?.cockpit=='no'" [(ngModel)]="siteVisit?.target_room_info.cockpit" value="no">
                                <label>No</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Power for Video Rack</div>
                            <div class="desc">Is there hospital main power available within 5 ft the video rack location?</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="target_room_info[video_rack]" type="radio" [checked]="siteVisit?.target_room_info?.video_rack=='yes'" [(ngModel)]="siteVisit?.target_room_info.video_rack" value="yes">
                                <label>Yes</label>
                            </div>
                            <div class="rd-option">
                                <input name="target_room_info[video_rack]" type="radio" [checked]="siteVisit?.target_room_info?.video_rack=='no'" [(ngModel)]="siteVisit?.target_room_info.video_rack" value="no">
                                <label>No</label>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="table-section">
            <div class="table-title">
                <h3>4.0 Lab Details</h3>
            </div>
            <div class="table-container">
                <table class="fifth">
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Ceiling Type</div>
                            <div class="desc">For permanent ceilings with access panels take pictures of the ceiling to document access panel locations</div>
                        </td>
                        <td>
                            <div class="clist">
                                <ul class="clist-parent">
                                    <li>
                                        <div class="cb-option">
                                            <input type="checkbox" value="suspended" [checked]="siteVisit?.lab_details?.ceiling_type?.includes('suspended')" (click)="onChangeCheckbox('suspended', 'lab_details','ceiling_type')">
                                            <label>Suspended</label>
                                        </div>
                                        <ul class="clist-child">
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="paper" [checked]="siteVisit?.lab_details?.ceiling_type?.includes('paper')" (click)="onChangeCheckbox('paper', 'lab_details','ceiling_type')">
                                                    <label>Paper tiles</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="metal" [checked]="siteVisit?.lab_details?.ceiling_type?.includes('metal')" (click)="onChangeCheckbox('metal', 'lab_details','ceiling_type')">
                                                    <label>Metal tiles</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="raceways" [checked]="siteVisit?.lab_details?.ceiling_type?.includes('raceways')" (click)="onChangeCheckbox('raceways', 'lab_details','ceiling_type')">
                                                    <label>Wiring raceways present</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="cb-option">
                                          <input type="checkbox" value="permanent" [checked]="siteVisit?.lab_details?.ceiling_type?.includes('permanent')" (click)="onChangeCheckbox('permanent', 'lab_details','ceiling_type')">
                                            <label>Permanent</label>
                                        </div>
                                        <ul class="clist-child">
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="access_panels" [checked]="siteVisit?.lab_details?.ceiling_type?.includes('access_panels')" (click)="onChangeCheckbox('access_panels', 'lab_details','ceiling_type')">
                                                    <label>Access panels</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Other Configuration</div>
                        </td>
                        <td>
                        <textarea style="width:100%;" name="lab_details[other_configuration]" [(ngModel)]="siteVisit.lab_details.other_configuration" rows=3>{{ siteVisit?.lab_details.other_configuration}}</textarea>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Floor Details</div>
                        </td>
                        <td>
                            <div class="cb-option">
                              <input type="checkbox" value="has_channel" [(ngModel)]="siteVisit.lab_details.floor_details.has_channel" [checked]="siteVisit?.lab_details?.floor_details?.has_channel=='true'">
                                <label>Has an existing channel</label>
                            </div>
                            <div class="cb-option">
                              <input type="checkbox" value="need_excavated" [(ngModel)]="siteVisit.lab_details.floor_details.need_excavated" [checked]="siteVisit?.lab_details?.floor_details?.need_excavated=='true'">
                                <label>Need to be excavated</label>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="table-section">
            <div class="table-title">
                <h3>5.0 Control Room Details</h3>
            </div>
            <div class="table-container">
                <table class="fifth">
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Ceiling Type</div>
                            <div class="desc">For permanent ceilings with access panels take pictures of the ceiling to document access panel locations</div>
                        </td>
                        <td>
                            <div class="clist">
                                <ul class="clist-parent">
                                    <li>
                                        <div class="cb-option">
                                            <input type="checkbox" value="suspended" [checked]="siteVisit?.control_room_details?.ceiling_type?.includes('suspended')" (click)="onChangeCheckbox('suspended', 'control_room_details','ceiling_type')">
                                            <label>Suspended</label>
                                        </div>
                                        <ul class="clist-child">
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="paper" [checked]="siteVisit?.control_room_details?.ceiling_type?.includes('paper')" (click)="onChangeCheckbox('paper', 'control_room_details','ceiling_type')">
                                                    <label>Paper tiles</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="metal" [checked]="siteVisit?.control_room_details?.ceiling_type?.includes('metal')" (click)="onChangeCheckbox('metal', 'control_room_details','ceiling_type')">
                                                    <label>Metal tiles</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="raceways" [checked]="siteVisit?.control_room_details?.ceiling_type?.includes('raceways')" (click)="onChangeCheckbox('raceways', 'control_room_details','ceiling_type')">
                                                    <label>Wiring raceways present</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="cb-option">
                                          <input type="checkbox" value="permanent" [checked]="siteVisit?.control_room_details?.ceiling_type?.includes('permanent')" (click)="onChangeCheckbox('permanent', 'control_room_details','ceiling_type')">
                                            <label>Permanent</label>
                                        </div>
                                        <ul class="clist-child">
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="access_panels" [checked]="siteVisit?.control_room_details?.ceiling_type?.includes('access_panels')" (click)="onChangeCheckbox('access_panels', 'control_room_details','ceiling_type')">
                                                    <label>Access panels</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Other Configuration</div>
                        </td>
                        <td>
                        <textarea style="width:100%;" name="control_room_details[other_configuration]" [(ngModel)]="siteVisit.control_room_details.other_configuration" rows=3>{{ siteVisit?.control_room_details?.other_configuration }}</textarea>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="table-section">
            <div class="table-title">
                <h3>6.0 Equipment Room/Closet Information</h3>
            </div>
            <div class="table-container">
                <table class="fifth">
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Equipment Room location</div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input name="equipment_room_info[location]" type="radio" [(ngModel)]="siteVisit.equipment_room_info.location" [checked]="siteVisit?.equipment_room_info?.location=='closet_cath_lab'" value="closet_cath_lab">
                                <label>Closet in cath lab</label>
                            </div>
                            <div class="rd-option">
                                <input name="equipment_room_info[location]" type="radio" [(ngModel)]="siteVisit.equipment_room_info.location" [checked]="siteVisit?.equipment_room_info?.location=='closet_control_room'" value="closet_control_room">
                                <label>Closet in control room</label>
                            </div>
                            <div class="rd-option">
                                <input name="equipment_room_info[location]" type="radio" [(ngModel)]="siteVisit.equipment_room_info.location" [checked]="siteVisit?.equipment_room_info?.location=='separate_room_cath_lab'" value="separate_room_cath_lab">
                                <label>Separate room, access from cath lab</label>
                            </div>
                            <div class="rd-option">
                              <input name="equipment_room_info[location]" type="radio" [(ngModel)]="siteVisit.equipment_room_info.location" [checked]="siteVisit?.equipment_room_info?.location=='separate_room_hallway'" value="separate_room_hallway">
                                <label>Separate room, access from hallway</label>
                            </div>
                            <div class="rd-option">
                              <input name="equipment_room_info[location]" type="radio" [(ngModel)]="siteVisit.equipment_room_info.location" [checked]="siteVisit?.equipment_room_info?.location=='not_an_option'" value="not_an_option">
                                <label>Not an option for Corindus rack location</label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Video Rack Type/location</div>
                            <div class="desc">
                                Floor requires at least 28" width, 36" depth, 30" height with access to front and back of rack for servicing.<br>
                                Wall requires at least 42" height, 30" width, 12" depth to mount. 42" H, 38" W, 36" D to access to front for servicing
                            </div>
                        </td>
                        <td>
                            <div class="rd-option">
                                <input type="checkbox" value="floor_rack" [(ngModel)]="siteVisit.equipment_room_info.floor_rack.available" [checked]="siteVisit?.equipment_room_info?.floor_rack?.available=='true'">
                                <label>Floor rack possible</label>
                                <div class="extra-rd">
                                    Location(s):<input type="text" [(ngModel)]="siteVisit?.equipment_room_info?.floor_rack.location"/>
                                </div>
                            </div>
                            <div class="rd-option">
                                <input type="checkbox" value="wall_rack" [(ngModel)]="siteVisit.equipment_room_info.wall_rack.available" [checked]="siteVisit?.equipment_room_info?.wall_rack?.available=='true'">
                                <label>Wall rack possible</label>
                                <div class="extra-rd">
                                    Location(s):<input type="text" [(ngModel)]="siteVisit?.equipment_room_info?.wall_rack.location">
                                </div>
                            </div>
                            <div class="rd-option">
                                <input type="checkbox" value="corindus_rack" [(ngModel)]="siteVisit.equipment_room_info.corindus_rack" [checked]="siteVisit?.equipment_room_info?.corindus_rack=='true'">
                                <label>No Corindus rack</label>
                                <div class="extra-rd">
                                    Monitor Manager Location:<input name="equipment_room_info[monitor_location]" type="text" [(ngModel)]="siteVisit?.equipment_room_info.monitor_location">                                    
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="table-heading">
                            <div class="hd">Ceiling Type</div>
                            <div class="desc">
                                Leave blank if rack/monitor manager can only reside in the cath lab.<br><br>
                                For permanent ceilings with access panels take pictures of the ceiling to document access panel locations
                            </div>
                        </td>
                        <td>
                            <div class="clist">
                                <ul class="clist-parent">
                                    <li>
                                        <div class="cb-option">
                                            <input type="checkbox" value="suspended" [checked]="siteVisit?.equipment_room_info?.ceiling_type?.includes('suspended')" (click)="onChangeCheckbox('suspended', 'equipment_room_info','ceiling_type')">
                                            <label>Suspended</label>
                                        </div>
                                        <ul class="clist-child">
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="paper" [checked]="siteVisit?.equipment_room_info?.ceiling_type?.includes('paper')" (click)="onChangeCheckbox('paper', 'equipment_room_info','ceiling_type')">
                                                    <label>Paper tiles</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="metal" [checked]="siteVisit?.equipment_room_info?.ceiling_type?.includes('metal')" (click)="onChangeCheckbox('metal', 'equipment_room_info','ceiling_type')">
                                                    <label>Metal tiles</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="raceways" [checked]="siteVisit?.equipment_room_info?.ceiling_type?.includes('raceways')" (click)="onChangeCheckbox('raceways', 'equipment_room_info','ceiling_type')">
                                                    <label>Wiring raceways present</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="cb-option">
                                          <input type="checkbox" value="permanent" [checked]="siteVisit?.equipment_room_info?.ceiling_type?.includes('permanent')" (click)="onChangeCheckbox('permanent', 'equipment_room_info','ceiling_type')">
                                            <label>Permanent</label>
                                        </div>
                                        <ul class="clist-child">
                                            <li>
                                                <div class="cb-option">
                                                  <input type="checkbox" value="access_panels" [checked]="siteVisit?.equipment_room_info?.ceiling_type?.includes('access_panels')" (click)="onChangeCheckbox('access_panels', 'equipment_room_info','ceiling_type')">
                                                    <label>Access panels</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                              </ul>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="installation-table">
        <div class="header">
            <h2>Parts Required for Installation</h2>
        </div>
        <div class="i-single-table">
            <table>
                <tr>
                    <td colspan="2" class="heading green">PVM setup (select only 1)</td>
                </tr>
                <tr class="bold">
                    <td>Part Number</td>
                    <td>Description</td>
                </tr>
                <tr>
                    <td>160‐08615</td>
                    <td>
                        <div class="rd-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['160_08615']" value="true" [checked]="siteVisit?.parts_required['160_08615']=='true'">
                            <label>Cockpit Kit with 40" monitor</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>160‐08616</td>
                    <td>
                        <div class="rd-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['160_08616']" value="true" [checked]="siteVisit?.parts_required['160_08616']=='true'">
                            <label>Control Room kit with 31" monitor</label>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="i-single-table">
            <table>
                <tr>
                    <td colspan="2" class="heading blue">Accessory Kits  (select if camera is required and RGB or splitter)</td>
                </tr>
                <tr class="bold">
                    <td>Part Number</td>
                    <td>Description</td>
                </tr>
                <tr>
                    <td>160‐02430‐02</td>
                    <td>
                        <div class="rd-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['160_02430_02']" value="true" [checked]="siteVisit?.parts_required['160_02430_02']=='true'">  
                            <!-- <input name="parts_required[160_02430_02]" type="radio" @if(isset(site_information?.parts_required['160_02430_02'])) @if(site_information?.parts_required['160_02430_02']=='true') checked @endif @endif value="true"> -->
                            <label>Video Camera System for PVM, HD</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>160‐08617</td>
                    <td>
                        <div class="rd-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['160_08617']" value="true" [checked]="siteVisit?.parts_required['160_08617']=='true'">
                            <!-- <input name="parts_required[160_08617]" type="radio" @if(isset(site_information?.parts_required['160_08617'])) @if(site_information?.parts_required['160_08617']=='true') checked @endif @endif value="true"> -->
                            <label>Monitor Manager Kit</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐08593</td>
                    <td>
                        <div class="rd-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_08593'].available" value="true" [checked]="siteVisit?.parts_required['110_08593'].available=='true'">
                            <label>Dual‐Link splitter/Downscaler</label>
                        </div>
                        <div class="rd-option-extra">
                            <span>Supplied by: </span>
                            <div class="rd-et">
                                <input name="parts_required['110_08593'].supplier" [(ngModel)]="siteVisit?.parts_required['110_08593'].supplier" type="radio" [checked]="siteVisit?.parts_required['110_08593'].supplier=='Fluoro'" value="Fluoro">
                                <label>Fluoro Vendor</label>
                            </div>
                            <div class="rd-et">
                                <input name="parts_required['110_08593'].supplier" [(ngModel)]="siteVisit?.parts_required['110_08593'].supplier" type="radio" [checked]="siteVisit?.parts_required['110_08593'].supplier=='Corindus'" value="Corindus">
                                <label>Corindus</label>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="i-single-table">
            <table>
                <tr>
                    <td colspan="2" class="heading orange">Video Rack  (select 1 IF RGB kit is selected above )</td>
                </tr>
                <tr class="bold">
                    <td>Part Number</td>
                    <td>Description</td>
                </tr>
                <tr>
                    <td>160‐08619</td>
                    <td>
                        <div class="rd-option">
                            <!-- <input name="parts_required[160_08619]" type="radio" @if(isset(site_information?.parts_required['160_08619'])) @if(site_information?.parts_required['160_08619']=='true') checked @endif @endif value="true"> -->
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['160_08619']" value="true" [checked]="siteVisit?.parts_required['160_08619']=='true'">
                            <label>Wall Rack kit</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>160‐08618</td>
                    <td>
                        <div class="rd-option">
                            <!-- <input name="parts_required[160_08618]" type="radio" @if(isset(site_information?.parts_required['160_08618'])) @if(site_information?.parts_required['160_08618']=='true') checked @endif @endif value="true"> -->
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['160_08618']" value="true" [checked]="siteVisit?.parts_required['160_08618']=='true'">
                            <label>Floor Rack kit</label>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="i-single-table">
            <table>
                <tr>
                    <td colspan="3" class="heading red">Video Signal Kits  (select quantities of each required)</td>
                </tr>
                <tr class="bold">
                    <td>Part Number</td>
                    <td>Qty</td>
                    <td>Description</td>
                </tr>
                <tr>
                    <td>160‐08622</td>
                    <td><input type="text" [(ngModel)]="siteVisit?.video_signal_kits[0]['quantity']"></td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.video_signal_kits[0]['value']" value="true" [checked]="siteVisit?.video_signal_kits[0]['value']=='true'">
                            <label>Digital Signal Kit (DVI)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>160‐08620</td>
                    <td><input type="text" [(ngModel)]="siteVisit?.video_signal_kits[1]['quantity']"></td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.video_signal_kits[1]['value']" value="true" [checked]="siteVisit?.video_signal_kits[1]['value']=='true'">
                            <label>Analog Signal Kit (Coax/BNC)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>160‐08621</td>
                    <td><input type="text" [(ngModel)]="siteVisit?.video_signal_kits[2]['quantity']"></td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.video_signal_kits[2]['value']" value="true" [checked]="siteVisit?.video_signal_kits[2]['value']=='true'">
                            <label>Auxiliary Signal Kit (VGA)</label>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="i-single-table">
            <table>
                <tr>
                    <td colspan="2" class="heading yellow">Foot Pedal</td>
                </tr>
                <tr class="bold">
                    <td>Part Number</td>
                    <td>Description</td>
                </tr>
                <tr>
                    <td>N/A</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['N/A']" value="true" [checked]="siteVisit?.parts_required['N/A']=='true'">
                            <label>No Foot pedal required from Corindus</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02005‐01</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02005_01']" value="true" [checked]="siteVisit?.parts_required['110_02005_01']=='true'">
                            <label>Philips Wireless monoplane</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02005‐02</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02005_02']" value="true" [checked]="siteVisit?.parts_required['110_02005_02']=='true'">
                            <label>Philips Wireless biplane</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02342</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02342']" value="true" [checked]="siteVisit?.parts_required['110_02342']=='true'">
                            <label>Philips footswitch Splitter</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Special Order</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['special_order_old']" value="true" [checked]="siteVisit?.parts_required['special_order_old']=='true'">
                            <label>GE Wireless monoplane (Older, dark grey pedal)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Special Order</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['special_order_new']" value="true" [checked]="siteVisit?.parts_required['special_order_new']=='true'">
                            <label>GE Wireless monoplane (newer, light grey pedal)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02011</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02011']" value="true" [checked]="siteVisit?.parts_required['110_02011']=='true'">
                            <label>GE Wired monoplane (Older, dark grey pedal)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02011‐02</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02011_02']" value="true" [checked]="siteVisit?.parts_required['110_02011_02']=='true'">
                            <label>GE Wired monoplane (newer, light grey pedal) </label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐03346</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_03346']" value="true" [checked]="siteVisit?.parts_required['110_03346']=='true'">
                            <label>GE footswitch splitter (D‐Sub Ribbon)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02089‐03</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02089_03']" value="true" [checked]="siteVisit?.parts_required['110_02089_03']=='true'">
                            <label>Siemens wired Monoplane footswitch (w/ 9 Pin SS Round Plug)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02089‐01</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02089_01']" value="true" [checked]="siteVisit?.parts_required['110_02089_01']=='true'">
                            <label>Siemens wired Monoplane footswitch (square plug)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02089‐02</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02089_02']" value="true" [checked]="siteVisit?.parts_required['110_02089_02']=='true'">
                            <label>Siemens wired Biplane (square plug)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02090</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02090']" value="true" [checked]="siteVisit?.parts_required['110_02090']=='true'">
                            <label>Siemens Footswitch Splitter (Square plug)</label>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>110‐02090‐02</td>
                    <td>
                        <div class="cb-option">
                            <input type="checkbox" [(ngModel)]="siteVisit?.parts_required['110_02090_02']" value="true" [checked]="siteVisit?.parts_required['160_08618']=='true'">
                            <label>Siemens Footswitch Splitter (W‐SS Round, Plug)</label>
                        </div>
                    </td>
                </tr>

            </table>
        </div>

        <div class="i-single-table">
            <table>
                <tr>
                    <td style="width:215px;">Injector System</td>
                    <td><input type="text" name="injector_system" [(ngModel)]="siteVisit.injector_system"></td>
                </tr>
            </table>
        </div>
    </div>
    <div class="submit-table">
        <button class="button button--primary" type="button" (click)="saveDraft()">Save Draft</button>
    </div>
    
  </div>
<!-- <div class="complete-table">
    <a href="#"><input class="button button--primary" type="button" value="Completed"></a>
</div> -->