import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsService } from '../services/accounts.service';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';
import { CalendarOptions } from '@fullcalendar/angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  accounts: any
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth'
  };
  constructor(private accountsService: AccountsService, private router: Router, private dsService: DataSharingService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.accountsService.getDashboardData().subscribe(data => {
      this.accounts = data
      console.log(data);
      this.patchDataToCalendar(data)
      // console.log(data)
    }, error => {
      this.dialogService.errorDialogNew(error)
    })    
  }

  patchDataToCalendar(data){
    let events = []
    for(let i=0;i<data.length;i++){
      let account = data[i]
      if(account.installation_date){
        events.push({ title: account.name+"\n - Installation", date: account.installation_date, backgroundColor: "#8fdf82" })
        let date = new Date(account.installation_date)
        for(let j=0;j<1;j++){
          date.setDate(date.getDate()+1)
          events.push({ title: account.name+" - Installation", date: date.toISOString().slice(0,10), backgroundColor: "#e4e4e4" })
        }
      }
      if(account.inservice_date){
        events.push({ title: account.name+"\n - Inservice", date: account.inservice_date, backgroundColor: "#337ab7" })
        let date = new Date(account.inservice_date)
        for(let j=0;j<4;j++){
          date.setDate(date.getDate()+1)
          events.push({ title: account.name+" - Inservice", date: date.toISOString().slice(0,10), backgroundColor: "#e4e4e4" })
        }
      }
    }
    this.calendarOptions.events = events
    this.calendarOptions.weekends = !this.calendarOptions.weekends
  }

  getAccount(account){
    this.dsService.storeData({account: account})
    this.router.navigate(['/account-details'])
  } 

  getAccountChecklist(account){
    this.dsService.storeData({account: account})
    this.router.navigate(['/account-checklist'])
  }

}
