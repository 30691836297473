import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';
import { HcpService } from '../services/hcp.service';
import { SiteVisitService } from '../services/site-visit.service';

@Component({
  selector: 'app-account-site-visit',
  templateUrl: './account-site-visit.component.html',
  styleUrls: ['./account-site-visit.component.scss']
})

// export interface SiteVisit {
//   id: string
//   site_information: {
//     date: string,
//     name: string,
//     address: string,
//     dock_hours: string,
//     delivery_rq: string,
//     dock_address: string,
//     truck: string
//   },
//   site_contacts: Array<string>
// }

export class AccountSiteVisitComponent implements OnInit {

  selectedHcp: any;
  siteVisit: any
  hcps;
  
  constructor(private hcpService: HcpService, private siteVisitService: SiteVisitService, private dsService: DataSharingService, private router: Router, private dialogService: DialogService) { }

  ngOnInit(): void {
    let storedData = this.dsService.currentDataValue
    console.log(storedData)
    if(storedData && storedData.account){
      this.siteVisitService.getSiteVisitbyAccount(storedData.account.id).subscribe(data => {
        console.log(data)
        if(data.results[0]){
          this.siteVisit = data.results[0]
        }
        else
          this.siteVisit = {
            site_information: {},
            site_contacts: [],
            target_room_info: {},
            labe_details: {},
            control_room_details: {},
            equipment_room_info: {
              floor_rack: {location: "", available: ""},
              wall_rack: {location: "", available: ""}
            },
            parts_required: {},
            video_signal_kits: [],
            injector_system: ""
          }
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
      this.hcpService.getAllHcpsbyAccount(storedData.account.id).subscribe(data => {
        this.hcps = data.results
        this.selectedHcp = this.hcps[0]
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.router.navigate(['/accounts'])
    }
  }

  addHcp(){   
    if(this.selectedHcp)
      this.siteVisit.site_contacts.length? this.siteVisit.site_contacts.push(this.selectedHcp) : this.siteVisit.site_contacts = [this.selectedHcp]
  }
  removeHcp(hcp, index){
    this.siteVisit.site_contacts.splice(index,1)
  }

  onChangeCheckbox(item, key1, key2){
    let list = this.siteVisit[key1][key2]
    let index = list.indexOf(item)
    if(index>-1)
      list.splice(index,1)
    else
      list.push(item)
  }

  saveDraft(){
    console.log(this.siteVisit.site_contacts)
    if(this.siteVisit.site_contacts){
      let ids = []
      this.siteVisit.site_contacts.forEach(sc => {
        ids.push(sc.id)
      })
      this.siteVisit.site_contacts = ids
    }
    console.log(this.siteVisit)
    this.siteVisitService.updateSiteVisit(this.siteVisit.id, this.siteVisit).subscribe(data => {
      console.log(data)
      this.dialogService.alertDialogWithReturn({
        title: "Success",
        message: "Site visit data has been saved successfully."
      }).then(() => {
        this.router.navigate(['/account-details'])
      })
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

}
