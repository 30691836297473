import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  private dataSubject: BehaviorSubject<any>;
  public currentData: Observable<any>;

  constructor() {
    this.dataSubject = new BehaviorSubject<any>((JSON.parse(localStorage.getItem('currentData'))));
    this.currentData = this.dataSubject.asObservable();
  }

  public get currentDataValue(): any {
    return this.dataSubject.value;
  }

  updateData(data) {
    localStorage.setItem('currentData', JSON.stringify(data));
    // this.currentDataValue.next(data);
  }

  removeData(){
    this.dataSubject.next(null);
  }

  storeData(data){
    localStorage.setItem('currentData', JSON.stringify(data));
    // this.dataSubject.next(data);
  }
}
