import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() action: string;
  @Input() data;
  @Output() sendAccountForm = new EventEmitter<string>();
  regions: any[] = []
  users: any[] = []
  keyPersons;
  tableType;

  constructor(private fb: FormBuilder, private userService: UserService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.tableType = "add";
    this.keyPersons = []
    if(this.data){
      this.accountForm.patchValue(this.data)
      let obj = {};
      if(this.data.region)
        obj['region'] = this.data.region.id
      if(this.data.zone)
        obj['zone'] = this.data.zone.id
      if(this.data.country)
        obj['country'] = this.data.country.id
      this.accountForm.get('region').setValue(JSON.stringify(obj))
    }
    this.getRegions()
    this.getUsers()
  }  

  getRegions(){
    this.userService.getRegions().subscribe(data => {
      let regions = data;
      for(let i=0;i<regions.length;i++){
        this.regions.push({id: JSON.stringify({"region": regions[i].id}), name: regions[i].name})
        let zones = regions[i].zones
        for(let j=0;j<zones.length;j++){
          this.regions.push({id: JSON.stringify({"region": regions[i].id, "zone": zones[j].id}), name: "--"+zones[j].name})
          let countries = zones[j].countries
          for(let k=0;k<countries.length;k++){
            this.regions.push({id: JSON.stringify({"region": regions[i].id, "zone": zones[j].id, "country": countries[k].id}), name: "----"+countries[k].name})
          }
        }
      }
    })
  }

  getUsers(){
    this.userService.getAllUsers().subscribe(data => {
      this.users = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }


  accountForm = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', Validators.required],
    region: ['', Validators.required],
    web: ['', Validators.required],
    address: ['', Validators.required],
    num_of_ors: [0, [Validators.required, Validators.min(0)]],
    compatible_ors: [0, [Validators.required, Validators.min(0)]],
    progress: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    key_person: ['', Validators.required],
    delivery_date: ['', Validators.required],
    installation_date: ['', Validators.required],
    inservice_date: ['', Validators.required],
    robotic_program: [false, Validators.required],
  })

  get name() {return this.accountForm.get('name')}
  get email() {return this.accountForm.get('email')}
  get phone() {return this.accountForm.get('phone')}
  get region() {return this.accountForm.get('region')}
  get web() {return this.accountForm.get('web')}
  get address() {return this.accountForm.get('address')}
  get num_of_ors() {return this.accountForm.get('num_of_ors')}
  get compatible_ors() {return this.accountForm.get('compatible_ors')}
  get progress() {return this.accountForm.get('progress')}
  get key_person() {return this.accountForm.get('key_person')}
  get delivery_date() {return this.accountForm.get('delivery_date')}
  get installation_date() {return this.accountForm.get('installation_date')}
  get inservice_date() {return this.accountForm.get('inservice_date')}

  submit(){
    let account = this.accountForm.value
    account.region = JSON.parse(account.region)
    account.country = account.region.country
    account.zone = account.region.zone
    account.region = account.region.region
    console.log(account)
    this.sendAccountForm.emit(account);
  }

  close(){
    this.sendAccountForm.emit()
    this.accountForm.reset();
  }

}
