import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { DepartmentsService } from '../services/departments.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  departments;
  modalRef: NgbModalRef
  action: "view" | "edit" | "add"
  title: string
  selectedDepartment: any
  departmentIndex: number;

  constructor(private modalService: NgbModal, private departmentsService: DepartmentsService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.departmentsService.getAllDepartments().subscribe(data => {
      console.log(data)
      this.departments = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

  changeStatus(value, index){
    let dep = this.departments[index]
    this.updateDepartment(dep.id, {is_active: value}, index)
  }

  open(modal) {
    this.modalRef = this.modalService.open(modal)
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  add(modal){
    this.action = "add";
    this.title = "Add Department"
    this.selectedDepartment = undefined;
    this.open(modal)
  }

  edit(modal, department, index){
    this.action = "edit"; 
    this.title = "Edit Department"
    this.departmentIndex = index;
    this.selectedDepartment = department;
    this.open(modal)
  }

  save(department){
    if(department){
      if(this.action=="edit"){
        this.updateDepartment(this.selectedDepartment.id, department, this.departmentIndex)
      }
      else if(this.action=="add"){
        this.departmentsService.createDepartment(department).subscribe(data => {
          this.departments.push(data)
          this.dialogService.alertDialog({title: "Success", message: "Successfully created department."})
        }, error => {
          this.dialogService.errorDialogNew(error)
        })
      }
      this.modalRef.dismiss()
    }
    else{
      this.modalRef.dismiss()
    }
  }

  updateDepartment(id, changes, index){
    this.departmentsService.updateDepartment(id, changes).subscribe(data => {
      this.departments.splice(index, 1, data)
      this.dialogService.alertDialog({title: "Success", message: "Successfully updated department."})
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
  }

}
