<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
</div>

<div class="modal-body">
  <form [formGroup]="userForm" (ngSubmit)="submit()" novalidate>

    <div class="row form-group">
      <label for="title" class="col-md-2">Title</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (title.dirty || title.touched)}">
        <input class="form-control" type="text" id="title" formControlName="title" required>
        <div *ngIf="title.invalid && (title.dirty || title.touched)" class="invalid-feedback">
          <div *ngIf="title.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
      <label for="name" class="col-md-2">Name</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (name.dirty || name.touched)}">
        <input class="form-control" type="text" id="name" formControlName="name" required>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
          <div *ngIf="name.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label for="email" class="col-md-2">Email</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (email.dirty || email.touched)}">
        <input class="form-control" type="text" id="email" formControlName="email" required>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
          <div *ngIf="email.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
      <label for="mobile" class="col-md-2">Mobile</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (mobile.dirty || mobile.touched)}">
        <input class="form-control" type="text" id="mobile" formControlName="mobile" required>
        <div *ngIf="mobile.invalid && (mobile.dirty || mobile.touched)" class="invalid-feedback">
          <div *ngIf="mobile.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <label for="role" class="col-md-2">User Role</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (role.dirty || role.touched)}">
        <select class="form-control" formControlName="role" id="role" required>
          <option value="">Select an option</option>
          <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
        </select>
        <div *ngIf="role.invalid && (role.dirty || role.touched)" class="invalid-feedback">
          <div *ngIf="role.errors.required">
            <strong>Fix this</strong>            
          </div>
        </div>
      </div>
      <label for="is_global" class="col-md-2">Is Global</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (is_global.dirty || is_global.touched)}">
        <input type="checkbox" class="form-control" formControlName="is_global" id="is_global" (change)="regionSelector()" >
      </div>
    </div>

    <div class="row form-group">
      <label for="region" class="col-md-2">Region</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (region.dirty || region.touched)}">
        <select class="form-control" formControlName="region" id="region">
          <option value="">Select an option</option>
          <option *ngFor="let r of regions" [ngValue]="r.id">{{r.name}}</option>
        </select>
      </div>
    </div>

    <input class="form-control" name="status" type="hidden" id="status" value="Active" >

    <div class="form-group">
        <div class="offset-md-4 col-md-4">
            <button type="submit" class="button button--primary display-block" [disabled]="!userForm.valid" >Save</button>
        </div>
    </div>
  </form>
</div>