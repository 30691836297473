import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { encryptPassword } from '../helpers/encrypt.passsword';
import { AuthenticationService } from '../services/authentication.service';
import { DialogService } from '../services/dialog.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private fb: FormBuilder, private usersService: UserService, private modalService: NgbModal, private router: Router, private authenticationService: AuthenticationService, private dialogService: DialogService) { }

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    code: ['']
  })

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); } 
  get code() { return this.loginForm.get('code'); }  

  ngOnInit() {
  }

  login(){
    let user = this.loginForm.value;
    let originalPassword = user.password
    // user.password = encryptPassword(user.password)
    console.log(user.password)

    this.authenticationService.loginUser(user).subscribe(data => {
      console.log(data)
      user.password = originalPassword
      this.router.navigate(['/dashboard'])
    }, error => {
      user.password = originalPassword
      console.log(error)
      this.dialogService.errorDialogNew(error)
      this.router.navigate(['/dashboard'])
    })
  }

  sendAuthCode(){
    console.log("yo")
    if(this.loginForm.get("email").value){
      alert("Code is not set yet. Please login without the code.")
    }
    else{
      alert("Please enter email address first.")
    }
  }

  // Forgot Password Modal

  modalRef: NgbModalRef
  forgotEmail: any;

  openModal(modal){
    this.modalRef = this.modalService.open(modal, {size: "lg"})
    this.modalRef.result.then((result) => {
      console.log(result)
    }, (cancel) => {
      console.log(cancel);
    });
  }

  close(){
    this.modalRef.dismiss()
  }

  sendLink(){
    if(this.forgotEmail){
      this.usersService.forgotPasswordRequest(this.forgotEmail).subscribe(data => {
        this.dialogService.alertDialog({
          title: "Success",
          message: 
            "Please check your email and click on the password reset link. " +
            "Please wait for few minutes before requesting again. " +
            "Also check your Junk/Spam folder also."
        })
        this.modalRef.dismiss()
      }, error =>{
        this.dialogService.errorDialogNew(error)
      })
    }
  }
}
