<div class="modal-header">
  <h4 class="modal-title">{{modalTitle}}</h4>
  <button type="button" class="close" (click)="close()"><span aria-hidden="true">&times;</span></button>        
</div>

<div class="modal-body">
  <form [formGroup]="accountForm" (ngSubmit)="submit()">

    <div class="form-group row">
      <label for="name" class="col-md-2">Name</label>
      <div class="col-md-10" [ngClass]="{'was-validated': (name.dirty || name.touched)}">
        <input class="form-control" type="text" id="name" formControlName="name" required>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="invalid-feedback">
          <div *ngIf="name.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-md-2">Email</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (email.dirty || email.touched)}">
        <input class="form-control" type="email" id="email" formControlName="email" required>
        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
          <div *ngIf="email.errors.required">
            <strong>Fix this</strong>
          </div>
          <div *ngIf="email.errors.email">
            <strong>Email incorrect</strong>
          </div>
        </div>
      </div>
      <label for="phone" class="col-md-2">Mobile</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (phone.dirty || phone.touched)}">
        <input class="form-control" type="text" id="phone" formControlName="phone" required>
        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="invalid-feedback">
          <div *ngIf="phone.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="region" class="col-md-2">Region</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (region.dirty || region.touched)}">
        <select class="form-control" formControlName="region" id="region" required >
          <option value="">Select an option</option>
          <option *ngFor="let r of regions" [ngValue]="r.id">{{r.name}}</option>
        </select>
        <div *ngIf="region.invalid && (region.dirty || region.touched)" class="invalid-feedback">
          <div *ngIf="region.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
      <label for="web" class="col-md-2">Web</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (web.dirty || web.touched)}">
        <input class="form-control" type="text" id="web" formControlName="web" required>
        <div *ngIf="web.invalid && (web.dirty || web.touched)" class="invalid-feedback">
          <div *ngIf="web.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="address" class="col-md-2">Address</label>
      <div class="col-md-10" [ngClass]="{'was-validated': (address.dirty || address.touched)}">
        <input class="form-control" type="text" id="address" formControlName="address" required>
        <div *ngIf="address.invalid && (address.dirty || address.touched)" class="invalid-feedback">
          <div *ngIf="address.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="num_of_ors" class="col-md-2">Number of ORs</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (num_of_ors.dirty || num_of_ors.touched)}">
        <input class="form-control" type="number" min="0" id="num_of_ors" formControlName="num_of_ors" required>
        <small class="form-text text-muted">How many ORs are there in Hospital?</small>
        <div *ngIf="num_of_ors.invalid && (num_of_ors.dirty || num_of_ors.touched)" class="invalid-feedback">
          <div *ngIf="num_of_ors.errors.required">
            <strong>Fix this</strong>
          </div>
          <div *ngIf="num_of_ors.errors.min">
            <strong>Can not be less then 0</strong>
          </div>
        </div>
      </div>
      <label for="compatible_ors" class="col-md-2">Compatible ORs</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (compatible_ors.dirty || compatible_ors.touched)}">
        <input class="form-control" type="number" min="0" id="compatible_ors" formControlName="compatible_ors" required>
        <small class="form-text text-muted">How many of the ORs are expected to be Compatible?</small>
        <div *ngIf="compatible_ors.invalid && (compatible_ors.dirty || compatible_ors.touched)" class="invalid-feedback">
          <div *ngIf="compatible_ors.errors.required">
            <strong>Fix this</strong>
          </div>
          <div *ngIf="compatible_ors.errors.min">
            <strong>Can not be less then 0</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="progress" class="col-md-2">Progress</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (progress.dirty || progress.touched)}">
        <input class="form-control" type="number" min="0" max="100" id="progress" formControlName="progress" required>
        <small class="form-text text-muted">Progress value should be between 0 and 100.</small>
        <div *ngIf="progress.invalid && (progress.dirty || progress.touched)" class="invalid-feedback">
          <div *ngIf="progress.errors.required">
            <strong>Fix this</strong>
          </div>
          <div *ngIf="progress.errors.min || progress.errors.max">
            <strong>Must be between 0 and 100</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="progress" class="col-md-4">Key Persons</label>
      <app-key-person-table [persons]="keyPersons" [type]="tableType"></app-key-person-table>
    </div>



    

    <div class="form-group row">
      <label for="delivery_date" class="col-md-2">Delivery Date</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (delivery_date.dirty || delivery_date.touched)}">
        <input class="form-control" type="date" id="delivery_date" formControlName="delivery_date" required>
        <div *ngIf="delivery_date.invalid && (delivery_date.dirty || delivery_date.touched)" class="invalid-feedback">
          <div *ngIf="delivery_date.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
      <label for="installation_date" class="col-md-2">Installation Date</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (installation_date.dirty || installation_date.touched)}">
        <input class="form-control" type="date" id="installation_date" formControlName="installation_date" required>
        <div *ngIf="installation_date.invalid && (installation_date.dirty || installation_date.touched)" class="invalid-feedback">
          <div *ngIf="installation_date.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="inservice_date" class="col-md-2">Inservice Date</label>
      <div class="col-md-4" [ngClass]="{'was-validated': (inservice_date.dirty || inservice_date.touched)}">
        <input class="form-control" type="date" id="inservice_date" formControlName="inservice_date" required>
        <div *ngIf="inservice_date.invalid && (inservice_date.dirty || inservice_date.touched)" class="invalid-feedback">
          <div *ngIf="inservice_date.errors.required">
            <strong>Fix this</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group no-gutters">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" formControlName="robotic_program" id="robotic_program" checked>
        <label class="form-check-label" for="robotic_program">
          Does the Hospital currently have a robotic program? (Tick the box, if Yes)
        </label>
      </div>
    </div>
    

    <input class="form-control" name="status" type="hidden" id="status" value="Active" >

    <div class="form-group">
        <div class="offset-md-4 col-md-4">
            <button type="submit" class="button button--primary display-block" [disabled]="!accountForm.valid" >Save</button>
        </div>
    </div>
  </form>
</div>