import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from '../services/accounts.service';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-confirm-date',
  templateUrl: './confirm-date.component.html',
  styleUrls: ['./confirm-date.component.scss']
})
export class ConfirmDateComponent implements OnInit {

  storedData:any
  accountId:any
  event:any
  confirm = {
    account: '',
    date_type: '',
    date: '',
    confirmed: '',
    is_active: '',
  }

  constructor(private activatedRoute: ActivatedRoute,private dsService: DataSharingService,private accountService: AccountsService, private dialogService: DialogService) { }

  ngOnInit(): void {
      this.storedData = this.dsService.currentDataValue
      this.activatedRoute.queryParams.subscribe(params => {
        let event = params['event'];

        this.accountService.getEvent(event).subscribe(data => {
          this.confirm.account = data.account;
          this.confirm.date_type = data.date_type;
          this.confirm.date = data.date;
          this.confirm.confirmed = 'true';
          this.confirm.is_active = data.is_active;
          this.accountService.confirmEvent(event,this.confirm ).subscribe(data => {
            console.log(data);
            
          }, error => {
            console.log(error)
          })
          
          
        }, error => {
          this.dialogService.errorDialogNew(error)
        })
      });




  }

}
