import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharingService } from '../services/data-sharing.service';
import { DialogService } from '../services/dialog.service';
import { HcpService } from '../services/hcp.service';
import { TrainingsService } from '../services/trainings.service';

@Component({
	selector: 'app-training-form-selector',
	templateUrl: './training-form-selector.component.html',
	styleUrls: ['./training-form-selector.component.scss']
})
export class TrainingFormSelectorComponent implements OnInit {

	formsSelect:any;
	currentRole;
	path:any
	hcpPath:any

	@Input() data;

	constructor(private hcpService: HcpService, private modalService: NgbModal, private dialogService: DialogService, private router: Router, private dsService: DataSharingService, private trainingsService: TrainingsService) { }

	ngOnInit(): void {
		this.currentRole = this.data.role.type;
		this.path = this.data.path
		this.getPath( this.currentRole, this.path )

		if( this.path != 'none' ) {
			this.setStatus()
		}
		
	}

	getPath( role, path ) {
		switch(path) { 
			case 'none': { 
				if( role == 'doctor' ) {
					this.hcpPath = null
				} else if ( role == 'staff' ) {
					this.hcpPath = [
						{slug : 'staff-training', name : 'Staff Trainning', phase: 1, is_completed: false, status: true },
					]
				}
				break; 
			} 
			case 'withtiq': { 
				if( role == 'doctor' ) {
					this.hcpPath = [
							{slug : 'hcp-training', name : 'Training without technIQ', phase: 1, is_completed: false, status: true },
							{slug : 'case-training', name : 'Case Trainning', phase: 2, is_completed: false, status: false },
						]
				} else if ( role == 'staff' ) {
					this.hcpPath = null
				}
				break; 
			} 
			case 'withoutetiq': { 
				if( role == 'doctor' ) {
					this.hcpPath = [
							{slug : 'hcp2-training', name : 'Training with technIQ',phase: 1, is_completed: false, status: true },
							{slug : 'case-training', name : 'Case Trainning', phase: 2, is_completed: false, status: false }
						]
				} else if ( role == 'staff' ) {
					this.hcpPath = null
				}
			 break; 
		} 
		case 'neuro': { 
			if( role == 'doctor' ) {
				this.hcpPath = [
						{slug : 'neuro-p1-training', name : 'Neurovascular Training Phase I', phase: 1, is_completed: false, status: true },
						{slug : 'neuro-p2-training', name : 'Neurovascular Training Phase II', phase: 2, is_completed: false, status: false },
						{slug : 'neuro-case-training', name : 'Neurovascular Training Phase III', phase: 3, is_completed: false, status: false }
					]
			} else if ( role == 'staff' ) {
				this.hcpPath = null
			}
			break; 
		} 
		case 'staff': { 
			if( role == 'doctor' ) {
				this.hcpPath = null
			} else if ( role == 'staff' ) {
				this.hcpPath = [
					{slug : 'staff-training', name : 'Staff Trainning', phase: 1, is_completed: false, status: true },
				]
			}
			break; 
		} 
			default: { 
				if( role == 'doctor' ) {
					this.hcpPath = [
						{slug : 'hcp-training', name : 'Training without technIQ', phase: 1, is_completed: false, status: true },
						{slug : 'hcp2-training', name : 'Training with technIQ', phase: 1, is_completed: false, status: true },
						{slug : 'neuro-p1-training', name : 'Neurovascular Training Phase I', phase: 1, is_completed: false, status: true },
						]
				} else if ( role == 'staff' ) {
					this.hcpPath = [
						{slug : 'staff-training', name : 'Staff Trainning', phase: 1, is_completed: false, status: true },
					]
				} 
				 break; 
			} 
		}
	}

	setStatus() {
		let account = this.data.account
		let hcp = this.data.id
		if( this.hcpPath.length > 0 ) {
			// get is completed from server
			this.hcpPath.forEach( (e,i) => {
				switch (e.slug) {
					case 'hcp-training':
						this.trainingsService.getHcpNonTechTrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed
							
						}, error =>{
						this.dialogService.errorDialogNew(error)
						})
						break;
					
					case 'hcp2-training':
						this.trainingsService.getHcpTechTrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed
							
						}, error =>{
						this.dialogService.errorDialogNew(error)
						})
						break;
					
					case 'case-training':
						this.trainingsService.getCaseTrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed
							
						}, error =>{
						this.dialogService.errorDialogNew(error)
						})
						break;
					
					case 'neuro-p1-training':
						this.trainingsService.getHcpNeuroP1TrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed

							
						  }, error =>{
							this.dialogService.errorDialogNew(error)
						  })
						break;
					
					case 'neuro-p2-training':
						this.trainingsService.getHcpNeuroP2TrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed

							
						}, error =>{
						this.dialogService.errorDialogNew(error)
						})
						break;
					
					case 'neuro-case-training':
						this.trainingsService.getHcpNeuroCaseTrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed
							
						}, error =>{
						this.dialogService.errorDialogNew(error)
						})
						break;
					
					case 'staff-training':
						this.trainingsService.getStaffTrainingbyAccount(account, hcp).subscribe(data => {
							let training = data.results[0]
							e.is_completed = training.completed
							
						}, error =>{
						this.dialogService.errorDialogNew(error)
						})
						break;
				
					default:
						break;
				}
			});

			// set the status
			this.hcpPath.forEach((e,i) => {
				if( i == 0 ) {
					e.status = true
				}

				setTimeout(() => {
					if( i == 1 ) {
						if( this.hcpPath[0].is_completed ) {
							e.status = true
						} else {
							e.status = false
						}
					}
					setTimeout(() => {
						if( i == 2 ) {
							if( this.hcpPath[1].is_completed ) {
								e.status = true
							} else {
								e.status = false
							}
						}
					}, 200);
				}, 200);
			});
		}
	}

	navigate($event, training) {
		$event.preventDefault();
		let data = this.dsService.currentDataValue
		data.hcp = this.data;
		this.dsService.storeData(data)
		this.router.navigate(['/'+training])
	}

}
