import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/services/accounts.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService } from 'src/app/services/dialog.service';
import { HcpService } from 'src/app/services/hcp.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-procedure',
  templateUrl: './add-procedure.component.html',
  styleUrls: ['./add-procedure.component.scss']
})
export class AddProcedureComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() action: string;
  @Input() data;
  regions: any[] = []
  users: any[] = []
  keyPersons;
  tableType;
  accounts: any
  allHCP: any
  options: any

  constructor(private fb: FormBuilder, private userService: UserService, private dialogService: DialogService, private procedureService: ProcedureService, private accountsService: AccountsService, private hcpService: HcpService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    this.options = this.procedureService.getOptions();

    this.accountsService.getAllAccounts().subscribe(data => {
      this.accounts = data.results
    }, error => {
      this.dialogService.errorDialogNew(error)
    })
    
    this.procedureForm.get('account').valueChanges.subscribe(data => {
      let selectedAccount = data
      if( data ) {
        this.hcpService.getAllHcpsbyAccount( selectedAccount ).subscribe(data => {
            this.allHCP = data.results
            this.allHCP.forEach(element => {
              element.valueName = element.title +"."+ element.first_name +" "+ element.last_name
            });
        }, error => {
          this.dialogService.errorDialogNew(error)
        })
      } else {
        this.allHCP = []
      }
     
    })

    // set owner
    let currentUser = this.authenticationService.currentUserValue.user.id[0].id
    if( currentUser ) {
      this.procedureForm.controls['owner'].setValue( currentUser );
      this.procedureForm.controls['owner'].setErrors(null);
    }
    

  }  

  procedureForm = this.fb.group({
      owner: ['', Validators.required],
      procedure_number: ['', Validators.required],
      procedure_date: ['', Validators.required],
      account: ['', Validators.required],
      corpath_case: ['', Validators.required],
      corpath_case_outcome: [''],
      corpath_system: ['', Validators.required],
      procedure_origin: [''],
      procedure_type: [''],
      number_corpath_case_lost: [''],
      contact_name: [[]],
      scrub1: [[]],
      cinical_presentation: [''],
      access: [''],
      fellow: [[]],
      case_description: [''],
      corpath_room: [''],
      lot_number: [''],
      number_of_cassette_on_shelf: [''],
      number_of_cassette_used: [''],
      independent: [false],
      hybrid: [''],
      mnm: [''],
      corpath_procedure_time: [''],
      xray_time: [''],
      total_gy_at_table: [''],
      total_gy_at_cocpit: [''],
      sheath: [''],
      catheter: [''],
      guide_length: [''],
      guide_wire: [''],
      predil_ballon: [''],
      stent: [''],
      postdil_ballon: [''],
      other_devices: [''],
      other_neuro_devices: [''],
      lesion_location: [''],
      lesion_length:[''],
      plaque_burden:[''],
      techiq_movements:[[]],
      techiq_user_description_and_feedback:[''],
      techiq_type:[''],
      hcp:'0106220d-1d89-410d-9ec1-469c5e5d6aca'
  })

  get owner() {return this.procedureForm.get('owner')}
  get procedure_number() {return this.procedureForm.get('procedure_number')}
  get procedure_date() {return this.procedureForm.get('procedure_date')}
  get account() {return this.procedureForm.get('account')}
  get corpath_case() {return this.procedureForm.get('corpath_case')}
  get corpath_case_outcome() {return this.procedureForm.get('corpath_case_outcome')}
  get corpath_system() {return this.procedureForm.get('corpath_system')}
  get procedure_origin() {return this.procedureForm.get('procedure_origin')}
  get procedure_type() {return this.procedureForm.get('procedure_type')}
  get number_corpath_case_lost() {return this.procedureForm.get('number_corpath_case_lost')}
  get contact_name() {return this.procedureForm.get('contact_name')}
  get scrub1() {return this.procedureForm.get('scrub1')}
  get cinical_presentation() {return this.procedureForm.get('cinical_presentation')}
  get access() {return this.procedureForm.get('access')}
  get fellow() {return this.procedureForm.get('fellow')}
  get case_description() {return this.procedureForm.get('case_description')}
  get corpath_room() {return this.procedureForm.get('corpath_room')}
  get lot_number() {return this.procedureForm.get('lot_number')}
  get number_of_cassette_on_shelf() {return this.procedureForm.get('number_of_cassette_on_shelf')}
  get number_of_cassette_used() {return this.procedureForm.get('number_of_cassette_used')}
  get independent() {return this.procedureForm.get('independent')}
  get hybrid() {return this.procedureForm.get('hybrid')}
  get mnm() {return this.procedureForm.get('mnm')}
  get corpath_procedure_time() {return this.procedureForm.get('corpath_procedure_time')}
  get xray_time() {return this.procedureForm.get('xray_time')}
  get total_gy_at_table() {return this.procedureForm.get('total_gy_at_table')}
  get total_gy_at_cocpit() {return this.procedureForm.get('total_gy_at_cocpit')}
  get sheath() {return this.procedureForm.get('sheath')}
  get catheter() {return this.procedureForm.get('catheter')}
  get guide_length() {return this.procedureForm.get('guide_length')}
  get guide_wire() {return this.procedureForm.get('guide_wire')}
  get predil_ballon() {return this.procedureForm.get('predil_ballon')}
  get stent() {return this.procedureForm.get('stent')}
  get postdil_ballon() {return this.procedureForm.get('postdil_ballon')}
  get other_devices() {return this.procedureForm.get('other_devices')}
  get other_neuro_devices() {return this.procedureForm.get('other_neuro_devices')}
  get lesion_location() {return this.procedureForm.get('lesion_location')}
  get lesion_length() {return this.procedureForm.get('lesion_length')}
  get plaque_burden() {return this.procedureForm.get('plaque_burden')}
  get techiq_movements() {return this.procedureForm.get('techiq_movements')}
  get techiq_user_description_and_feedback() {return this.procedureForm.get('techiq_user_description_and_feedback')}
  get techiq_type() {return this.procedureForm.get('techiq_type')}
  get hcp() {return this.procedureForm.get('hcp')}

  submit(){
    let procedure = this.procedureForm.value

    this.procedureService.createProcedure( procedure ).subscribe(data =>{
      this.dialogService.alertDialog({
        title: "Success",
        message: "Procedure Added"
      })

      this.router.navigate(['/procedures'])

    }, error => {
      this.dialogService.errorDialogNew(error)
    })

    // createProcedure
  }


}
