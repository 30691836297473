import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { departmentsURL, serverURL } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {

  constructor(private http: HttpClient) { }

  getAllDepartments(): Observable<any>{
    return this.http.get<any>(departmentsURL)
  }

  getAssociatedDepartments(account_id): Observable<any>{
    return this.http.get<any>(departmentsURL+"?account="+account_id)
  }

  createDepartment(departmentDetails): Observable<any>{
    return this.http.post<any>(departmentsURL, departmentDetails)
  }

  updateDepartment(id, department): Observable<any> {
    return this.http.patch<any>(departmentsURL+id+"/", department);
  }

}
