<section data-ste-chapter="breadcrumbs">
	<div class="ml-2rem">
		<ol class="bare-list breadcrumbs mb-0" vocab="https://schema.org/" typeof="BreadcrumbList">
			<li class="breadcrumbs__item" property="itemListElement" typeof="ListItem" style="height:38px;">
        <span class="d-flex">
					<span class="pointer" routerLink="/accounts">Accounts List</span>
					<i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-details">Account Details</span>
          <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					<span class="pointer" routerLink="/account-department-details">Department Details</span>
          <i class="material-icons" style="line-height: inherit;">chevron_right</i>
					Staff Training Form
				</span>
      </li>
		</ol>   
	</div>
</section>

<div class="form-container hcp-training-form">

  <div >
    <div class="section">
        <div class="row">
            <div class="col-md-8">
                <div class="hero-block__headings">
                    <h1 class="hero-block__title">CorPath GrX SyStem training</h1>
                </div>
            </div>
            <div class="col-md-4 flex-reverse">
                <button class="button display-block btn-sm" routerLink="/account-department-details">Back</button>
              </div>
        </div>
        <div class="subheading">
            <h3>Staff Acknowledgement</h3>
        </div>

        <div class="intro">
            <p>The following document outlines activities required for physicians to complete Phase I training on the CorPath GRX Vascular Robotic System.</p>
        </div>

        
        <div class="row">
            <div class="col-md-6">
                <div>
                    <div>
                        <span>
                            <!-- <?php $sta_name = isset( form->overview['name'] ) ? form->overview['name'] : null; ?> -->
                            <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.name}">
                                <div class="textfield__box">
                                    <input type="text" id="overview[name]" name="overview[name]" class="textfield__input" [(ngModel)]="form?.overview.name">
                                    <label class="textfield__label" for="overview[name]">Name:</label>
                                </div>
                                <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                            </div>
                        </span>
                    </div>      
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <div>
                        <span>
                            <!-- <?php $sta_hospital = isset( form->overview['hospital'] ) ? form->overview['hospital'] : null; ?> -->
                            <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.hospital}">
                                <div class="textfield__box">
                                    <input type="text" id="overview[hospital]" name="overview[hospital]" class="textfield__input" [(ngModel)]="form?.overview.hospital">
                                    <label class="textfield__label" for="overview[hospital]">Hospital:</label>
                                </div>
                                <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                            </div>
                        </span>
                    </div>      
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div>
                    <div>
                        <span>
                            <!-- <?php $sta_title = isset( form->overview['title'] ) ? form->overview['title'] : null; ?> -->
                            <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.title}">
                                <div class="textfield__box">
                                    <input type="text" id="overview[title]" name="overview[title]" class="textfield__input" [(ngModel)]="form?.overview.title">
                                    <label class="textfield__label" for="overview[title]">Title:</label>
                                </div>
                                <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                            </div>
                        </span>
                    </div>      
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <div>
                        <span>
                            <!-- <?php $tpo_email = isset( form->overview['email'] ) ? form->overview['email'] : null; ?> -->
                            <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.overview.email}">
                                <div class="textfield__box">
                                    <input type="text" id="overview[email]" name="overview[email]" class="textfield__input" [(ngModel)]="form?.overview.email">
                                    <label class="textfield__label" for="overview[email]">Email:</label>
                                </div>
                                <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                            </div>
                        </span>
                    </div>      
                </div>
            </div>
        </div>
    </div>

    <div class="section">
           
        <table class="table">
            <tr class="heading">
                <td>Task</td>
                <td>Activity</td>
                <td>Method</td>
            </tr>
            <tr>
                <td>CorPath GRX System Overview</td>
                <td>Review of system components including Indications for Use, Extended Reach Arm, Robotic Drive, Cockpit, Control Console, Single-use Cassette and icons</td>
                <td>
                    <label>Observe</label>
                    <input type="checkbox" name="tasks[corpath_grx_system_overview]" value="true" [(ngModel)]="form?.tasks.corpath_grx_system_overview" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Site specific connections </td>
                <td>Review power connection, video connection, communication cable connection, "uoro foot peda</td>
                <td>
                    <label>Observe</label>
                    <input type="checkbox" name="tasks[site_specific_connections]" value="true" [(ngModel)]="form?.tasks.site_specific_connections" class="form-control">
                </td>
            </tr>
            <tr>
                <td>System power up</td>
                <td>Power Up button location and use (press and hold for 3 seconds)</td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[system_power_up]" value="true" [(ngModel)]="form?.tasks.system_power_up" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Extended Reach Arm movement</td>
                <td>
                    <p>Position arm on bed-rail for procedure Review</p>
                    <ul>
                        <li>Rail Clamp Release Lever, arm movement capabilities and E-stop Button (location and use)</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[extended_reach_arm_movement]" value="true" [(ngModel)]="form?.tasks.extended_reach_arm_movement" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Equipment needed for robotic intervention</td>
                <td>
                    <p>Locate single-use Robotic Drive Drape, Console Drape, Single-use Cassette, Drive Gear and Y-connector</p>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[equipment_needed]" value="true" [(ngModel)]="form?.tasks.equipment_needed" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Sterile draping for robotic intervention</td>
                <td>
                    <p>Use sterile technique to drape the Arm and Robotic Drive unit</p>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[sterile_draping]" value="true" [(ngModel)]="form?.tasks.sterile_draping" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Prepare guide catheter</td>
                <td>
                    <p>Attach proximal end of Drive Gear to Y-connector, then connect distal end of Drive Gear to guide catheter hub</p>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[prepare_guide_catheter]" value="true" [(ngModel)]="form?.tasks.prepare_guide_catheter" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Attach Single-use Cassette</td>
                <td>
                    <p>Open and attach Cassette to Robotic Drive unit; align Cassette over optical sensor and ensure Cassette is "ush with Robotic Drive surface.</p>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[attach_single_use_cassette]" value="true" [(ngModel)]="form?.tasks.attach_single_use_cassette" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Prepare Single-use Cassette for use</td>
                <td>
                    <p>Follow system prompts shown on bedside touch screen:</p>
                    <ul>
                        <li>Straighten guide catheter track from front of cassette</li>
                        <li>Open rotary drive cover, remove orange key. Align rotary drive with linear drive, close cover; Cassette will perform self-test</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[prepare_single_use_cassette]" value="true" [(ngModel)]="form?.tasks.prepare_single_use_cassette" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Prepare Robotic Drive for use</td>
                <td>
                    <p>Follow system prompt to tilt robotic drive 30o.</p>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[prepare_robotic_drive]" value="true" [(ngModel)]="form?.tasks.prepare_robotic_drive" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Position Extended Reach Arm, Robotic Drive Cassette</td>
                <td>
                    <p>Position Extended Reach Arm via unlock button on front of Robotic Drive handle</p>
                    <ul>
                        <li>Position Robotic Drive/Cassette to permit Y-connector holder to reach Y-connector</li>
                        <li>Use Fine Tune Controls on front of Robotic Drive to optimize location</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[position_extended_reach_arm]" value="true" [(ngModel)]="form?.tasks.position_extended_reach_arm" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Load guide catheter</td>
                <td>
                    <p>Follow system prompts shown on bedside touchscreen</p>
                    <ul>
                        <li>Open lid of Y-connector holder</li>
                        <li>Position Drive Gear and Y-connector into Y-connector holder; close lid</li>
                        <li>Extend guide catheter support track so that it encapsulates guide catheter</li>
                        <li>Attach guide catheter support track to introducer sheath using sheath retainer ring</li>
                        <li>Turn lever on Track Clamp clockwise 180o to lock</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[load_guide_catheter]" value="true" [(ngModel)]="form?.tasks.load_guide_catheter" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Load Devices Exchange Devices</td>
                <td>Select “Load/Exchange Devices” on bedside touchscreen; interact with Cassette on the Robotic Drive using instructions shown on bedside touchscreen</td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[load_exchange_device]" value="true" [(ngModel)]="form?.tasks.load_exchange_device" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Unload guide catheter</td>
                <td>
                    <p>Select “Exchange Guide” at bedside touchscreen</p>
                    <ul>
                        <li>Interact with Cassette on Robotic Drive using instructions shown on bedside touchscreen to exchange guide catheter</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[unload_guide_catheter]" value="true" [(ngModel)]="form?.tasks.unload_guide_catheter" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Movement of guidewire</td>
                <td>
                    <p>Review joystick control and touch screen control movement</p>
                    <ul>
                        <li>Review touch screen values displayed</li>
                        <li>Review RoR automated rotation function</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[movement_of_guidewire]" value="true" [(ngModel)]="form?.tasks.movement_of_guidewire" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Movement of Guidewire and RX catheter</td>
                <td>
                    <p>Use cockpit controls to position wire and RX catheter into desired position Review joystick control and touchscreen control movement, including rotation (wire)</p>
                    <ul>
                        <li>Review touchscreen values displayed</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[movement_of_guidewire_and_RX]" value="true" [(ngModel)]="form?.tasks.movement_of_guidewire_and_RX" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Movement of Guide Catheter</td>
                <td>
                    <p>Review joystick control and touchscreen control movement</p>
                    <ul>
                        <li>Review touchscreen values displayed</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[movement_of_guide]" value="true" [(ngModel)]="form?.tasks.movement_of_guide" class="form-control">
                </td>
            </tr>
            <tr>
                <td>System Error Messages</td>
                <td>
                    <p>Review</p>
                    <ul>
                        <li>Unrecoverable error “Convert to Manual”</li>
                        <li>Communication Error</li>
                    </ul>
                </td>
                <td>
                    <label>Observe</label>
                    <input type="checkbox" name="tasks[system_error_mess]" value="true" [(ngModel)]="form?.tasks.system_error_mess" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Finish case</td>
                <td>
                    <p>Select “End Case” on bedside touch screen Follow instructions shown on bedside touch screen</p>
                    <ul>
                        <li>Turn Track Clamp counterclockwise 180o to unlock</li>
                        <li>Rotate sheath retainer ring to disconnect track from introducer sheath</li>
                        <li>Retract track into Cassette by pulling proximal end of track</li>
                        <li>Open lid on Y-Connector Holder and lift the Y-Connector, guide catheter and wire/RX catheter out of cassette</li>
                        <li>Close Cassette covers and gates</li>
                        <li>Press “Release Cassette” on bedside touchscreen</li>
                        <li>Remove Cassette from Robotic Drive</li>
                        <li>Follow system prompts to level Robotic Drive</li>
                        <li>t Ensure area around Robotic Drive is free from obstruction; select “OK to move” on bedside touchscreen</li>
                        <li>Remove drape, move Extended Reach Arm away from patient and place in Stow position</li>
                    </ul>
                </td>
                <td>
                    <label>Observe & Perform</label>
                    <input type="checkbox" name="tasks[finish_case]" value="true" [(ngModel)]="form?.tasks.finish_case" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Communication error, troubleshooting</td>
                <td>Review power source connections, E-stop buttons and communication cable connections</td>
                <td>
                    <label>Observe</label>
                    <input type="checkbox" name="tasks[troubleshooting]" value="true" [(ngModel)]="form?.tasks.troubleshooting" class="form-control">
                </td>
            </tr>
            <tr>
                <td>Locate source of additional CorPath information</td>
                <td>
                    <p>Location of CorPath GRX System Operator’s Manual</p>
                    <p>Identify location of Single-use Cassette Instructions for Use</p>
                </td>
                <td>
                    <label>Observe</label>
                    <input type="checkbox" name="tasks[additional]" value="true" [(ngModel)]="form?.tasks.additional" class="form-control">
                </td>
            </tr>
        </table>
    </div>

    <div class="row">
        <div class="col-md-9">
            <div>
                <div>
                    <span>
                        <div>
                            <label class="" for="confirm[physician_signature]">Staff Signature:</label>
                        </div>
                        <div class="canvas-holder">
                            <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)"></canvas>

                            <button class="sgn-button" (click)="clearPad()">Clear</button>
                        </div>
                    </span>
                </div> 
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div>
                    <span>
                        <!-- <?php $sta_staff_signature_date = isset( form->signatures['staff_signature_date'] ) ? form->signatures['staff_signature_date'] : null; ?> -->
                        <div class="aw-textfield textfield w-100 textfield-with-prefix is-active">
                            <div class="textfield__box">
                                <input type="date" id="signatures[staff_signature_date]" name="signatures[staff_signature_date]" class="textfield__input" [(ngModel)]="form?.signatures.staff_signature_date">
                                <label class="textfield__label" for="signatures[staff_signature_date]">Date:</label>
                            </div>
                            <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                        </div>
                    </span>
                </div>      
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-9">
            <div>
                <div>
                    <span>
                        <!-- <?php $sta_corindus_trainer = isset( form->signatures['corindus_trainer'] ) ? form->signatures['corindus_trainer'] : null; ?> -->
                        <div class="aw-textfield textfield w-100 textfield-with-prefix" [ngClass]="{'is-active': form?.signatures.corindus_trainer}">
                            <div class="textfield__box">
                                <input type="text" id="signatures[corindus_trainer]" name="signatures[corindus_trainer]" class="textfield__input" [(ngModel)]="form?.signatures.corindus_trainer">
                                <label class="textfield__label" for="signatures[corindus_trainer]">Corindus Trainer:</label>
                            </div>
                            <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                        </div>
                    </span>
                </div>      
            </div>
        </div>
        <div class="col-md-3">
            <div>
                <div>
                    <span>
                        <!-- <?php $sta_corindus_trainer_date = isset( form->signatures['corindus_trainer_date'] ) ? form->signatures['corindus_trainer_date'] : null; ?> -->
                        <div class="aw-textfield textfield w-100 textfield-with-prefix is-active">
                            <div class="textfield__box">
                                <input type="date" id="signatures[corindus_trainer_date]" name="signatures[corindus_trainer_date]" class="textfield__input" [(ngModel)]="form?.signatures.corindus_trainer_date">
                                <label class="textfield__label" for="signatures[corindus_trainer_date]">Date:</label>
                            </div>
                            <small class="textfield__hint display-flex"><span class="col-10 padding-0"></span></small>
                        </div>
                    </span>
                </div>      
            </div>
        </div>
    </div>



    <div class="row btn-row submit-form">
        <!-- <input class="button button--primary display-block" type="submit" value="Submit"> -->
        <button class="button button--primary display-block" (click)="saveDraft()">Submit</button>
    </div>
  </div>

  <div class="row btn-row complete-form">
	<button class="button button--primary display-block" (click)="complete()">Complete</button>
  </div>


</div>