import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { DialogService } from 'src/app/services/dialog.service';
import { TrainingsService } from 'src/app/services/trainings.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-staff-training',
  templateUrl: './staff-training.component.html',
  styleUrls: ['./staff-training.component.scss']
})
export class StaffTrainingComponent implements OnInit {

  form: any

  title = 'signatureJS';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;


  constructor(
    private dsService: DataSharingService,
    private dialogService: DialogService, 
    private router: Router, 
    private trainingsService: TrainingsService
  ) { }

  ngOnInit(): void {
    let storedData = this.dsService.currentDataValue
    // storedData.account = {id: "711910dd-0817-441e-8770-24c59e4eb47e"}
    // storedData.hcp = {id: "6bb0132f-9401-44c8-aabe-53d13dca9f56"}
    // console.log(storedData.account)
    // console.log(storedData.hcp)
    if(storedData.account || storedData.hcp){
      this.trainingsService.getStaffTrainingbyAccount(storedData.account.id, storedData.hcp.id).subscribe(data => {
        console.log(data)
        if(data.results.length){
          let res = data.results[0];
          // this.form = data.results[0]
          this.form = {};
          this.form.id = res.id;
          this.form.overview = res.overview;
          this.form.tasks = res.tasks;
          this.form.signatures = res.signatures;
          this.signatureImg = this.form.signatures.staff_signature

          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement,{
            onEnd: (event) => {
              const base64Data = this.signaturePad.toDataURL();
              this.signatureImg = base64Data;
              this.form.signatures.staff_signature = base64Data;
            }
          });

          if( this.signatureImg !='' && this.signatureImg ) {
            this.signaturePad.fromDataURL( this.signatureImg )
          }
          // this.patchKnownData(storedData)
        } else {
          this.defaultData()
          this.signaturePad = new SignaturePad(this.canvasEl.nativeElement,{
            onEnd: (event) => {
              const base64Data = this.signaturePad.toDataURL();
              this.signatureImg = base64Data;
              this.form.signatures.staff_signature = base64Data;
            }
          });
        }
      }, error =>{
        this.dialogService.errorDialogNew(error)
      })
    }
    else{
      this.router.navigate(['/account-department-details'])
    }
  }

  patchKnownData(data){
    if(!this.form.overview.name)
      this.form.overview.name = data.hcp.first_name + " " + data.hcp.last_name
    if(!this.form.overview.hospital)
      this.form.overview.hospital = data.account.name
    if(!this.form.overview.cellphone)
      this.form.overview.title = data.hcp.title
    if(!this.form.overview.email)
      this.form.overview.email = data.hcp.email
  }

  defaultData() {
    this.form = {}
    this.form.overview = {};
    this.form.overview.your_name = '';
    this.form.overview.hospital = '';
    this.form.overview.title = '';
    this.form.overview.email = '';

    this.form.tasks = {};
    this.form.tasks.corpath_grx_system_overview = '';
    this.form.tasks.site_specific_connections = '';
    this.form.tasks.system_power_up = '';
    this.form.tasks.extended_reach_arm_movement = '';
    this.form.tasks.equipment_needed = '';
    this.form.tasks.sterile_draping = '';
    this.form.tasks.prepare_guide_catheter = '';
    this.form.tasks.attach_single_use_cassette = '';
    this.form.tasks.prepare_single_use_cassette = '';
    this.form.tasks.prepare_robotic_drive = '';
    this.form.tasks.position_extended_reach_arm = '';
    this.form.tasks.load_guide_catheter = '';
    this.form.tasks.load_exchange_device = '';
    this.form.tasks.unload_guide_catheter = '';
    this.form.tasks.movement_of_guidewire = '';
    this.form.tasks.movement_of_guidewire_and_RX = '';
    this.form.tasks.movement_of_guide = '';
    this.form.tasks.system_error_mess = '';
    this.form.tasks.finish_case = '';
    this.form.tasks.troubleshooting = '';
    this.form.tasks.additional = '';

    this.form.signatures = {};
    this.form.signatures.staff_signature = '';
    this.form.signatures.staff_signature_date = '';
    this.form.signatures.corindus_trainer = '';
    this.form.signatures.corindus_trainer_date = '';

  }

  saveDraft(){
    if( this.form.id ) {
      this.trainingsService.updateStaffTraining(this.form.id, this.form).subscribe(data =>{
        console.log(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Staff Training Form has been saved successfully."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } else {
      let storedData = this.dsService.currentDataValue
      this.form.account = storedData.account.id;
      this.form.hcp = storedData.hcp.id

      this.trainingsService.createStaffTraining(this.form).subscribe(data =>{
        this.form.id = data.id;
        this.dialogService.alertDialog({
          title: "Success",
          message: "Staff Training Form has been added successfully."
        })
      }, error => {
        console.log( error )
        this.dialogService.errorDialogNew(error)
      })
    }
  }

  complete() {
    this.form.completed = true

    if( this.form.id ) {
      this.trainingsService.updateStaffTraining(this.form.id, this.form).subscribe(data =>{
        console.log(data)
        this.dialogService.alertDialog({
          title: "Success",
          message: "Staff Training Form has been Completed."
        })
      }, error => {
        this.dialogService.errorDialogNew(error)
      })
    } else {
      let storedData = this.dsService.currentDataValue
      this.form.account = storedData.account.id;
      this.form.hcp = storedData.hcp.id

      this.trainingsService.createStaffTraining(this.form).subscribe(data =>{
        this.form.id = data.id;
        this.dialogService.alertDialog({
          title: "Success",
          message: "Staff Training Form has been Completed"
        })
      }, error => {
        console.log( error )
        this.dialogService.errorDialogNew(error)
      })
    }

  }

  startDrawing(event: Event) {
    console.log(event);
    // works in device not in browser

  }

  moved(event: Event) {
  }

  clearPad() {
    this.signaturePad.clear();
    this.form.signatures.staff_signature = null;
  }

}
